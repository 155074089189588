<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:content>
      <div class="card mb-5 mb-xl-10">
        <div class="fw-bolder d-flex align-items-center ms-5 bg-success py-5 fs-2 text-center justify-content-center"
             v-if="contract && contract.nextContractUuid">
          <router-link class="text-white d-flex align-items-center"
                       :to="{name : 'contract-show', params:{ contractUuid : contract.nextContractUuid}}">
            <i class="fad fa-thumbs-up  text-inherit fs-2"></i>
            <div class="mx-5">
              Ce contract a été renouvellé
            </div>
            <i class="fad fa-caret-right fs-2 text-inherit"></i>
          </router-link>
        </div>
        <div class="card-body pt-9 pb-0">

          <div class="d-flex flex-wrap flex-sm-nowrap mb-3"
               v-if="contract">
            <div class="me-7 mb-4">
              <div
                  class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
              >
                <img
                    :src="uploadRoot + contract.subscriber.photo"
                    alt="image"
                    class="object-cover"
                    v-if="contract.subscriber && contract.subscriber.photo"
                />
                <img
                    :src="uploadRoot + contract.insurer.photo"
                    alt="image"
                    class="object-contain shadow-xs rounded"
                    v-else-if="contract.insurer && contract.insurer.photo"
                />
                <div
                    class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded"
                    v-else
                >
                  <i class="fad fa-user fs-5x"></i>
                  <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                </div>
                <div
                    :class="{
                    'bg-success':
                      contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,
                    'bg-warning':
                      contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,
                    'bg-danger':
                      contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,
                    'bg-primary':
                      contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED,
                  }"
                    class="position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px"
                ></div>
              </div>
            </div>

            <div class="flex-grow-1">
              <div
                  class="d-flex justify-content-between align-items-start flex-wrap mb-2"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <div
                        class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                    >
                      <i
                          :class="contract.branch.icon + ' me-2 fs-2'"
                          :style="{ color: contract.branch.color }"
                      ></i>
                      <img
                          :src="uploadRoot + contract.insurer.photo"
                          :alt="contract.insurer.label"
                          class="object-contain h-40px"
                          v-if="contract.insurer && contract.insurer.photo"
                      />
                      <template v-else>
                        {{ contract.insurer.label }}
                      </template>
                      ▪
                      {{ contract.branch.label }} ▪ {{ contract.type }} N°
                      {{ contract.number }}
                    </div>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    Police : N° {{ contract.police }}
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    <router-link
                        :to="{
                        name: 'customer-show',
                        params: { uuid: contract.subscriber.uuid },
                      }"
                        href=""
                    >
                      <i class="fad fa-user fs-2 me-5"></i>
                      {{ contract.subscriber.identifier }}
                      <span class="mx-2">
                      ❖
                        </span>
                      <span>
                      {{ phoneFormat(contract.subscriber.mobile) }}
                    </span>
                    </router-link>

                  </div>
                  <div class="my-4">
                    <div class="fw-bolder d-flex align-items-center">
                      <div
                          :title="readableDate(contract.startAt, 'dmY')"
                          v-b-tooltip.hover
                          v-if="contract.startAt"
                      >
                        {{ readableDate(contract.startAt, "dmY") }}
                      </div>
                      <div class="mx-1 d-flex align-items-center">↔</div>
                      <div
                          :title="readableDate(contract.expireAt, 'dmY')"
                          v-b-tooltip.hover
                          v-if="contract.expireAt"
                      >
                        {{ readableDate(contract.expireAt, "dmY") }}
                      </div>
                      <div class="ms-2">
                        ( {{ contract.duration }} )
                      </div>
                    </div>
                  </div>

                  <div class="my-4">
                    <div class="fw-bolder d-flex align-items-center">
                      <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover v-if="contract.expireAt">
                        Expire {{ timelapse(contract.expireAt, null, true, "ymd") }}
                      </div>
                      <!--                      <a target="_blank" :href="contract.renewLink" class="ms-5">-->
                      <!--                        {{ contract.renewLink }}-->
                      <!--                      </a>-->
                      <router-link target="_blank"
                                   :to="{name : 'renew-contract', params:{ contractUuid : contract.uuid } }"
                                   class="ms-5 btn-sm btn btn-primary" v-if="empty(contract.nextContractUuid)">
                        <i class="fad fa-sync"></i>
                        Personnaliser le renouvellement
                      </router-link>
                    </div>
                  </div>

                  <!--                                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                  <!--                                            <i class="fad fa-user me-2"></i>-->
                  <!--                                            Developer</a>-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                  <!--                                           <i class="fad fa-map-marker me-2"></i>-->
                  <!--                                            SF, Bay Area-->
                  <!--                                        </a>-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 d-flex align-items-center" href="#">-->
                  <!--                                            <i class="fad fa-at me-2"></i>-->
                  <!--                                            max@kt.com-->
                  <!--                                        </a>-->
                  <!--                                    </div>-->
                </div>
                <div class="d-flex my-4 align-items-center">
                  <div class="mx-5 fw-bolder" v-if="hasRole('ROLE_MANAGER')">
                    Partenaire
                    <div>
                      <b-dropdown class="flex-grow-1 w-100" no-caret no-flip right tag="div"
                                  toggle-class="w-100 p-0 flex-grow-1" variant="link"
                                  @click="(event)=> {event.stopPropagation()}">
                        <template v-slot:button-content>
                          <div
                              class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center w-100">
                            <template v-if="empty(contract.partnerCode) && empty(contract.partner)">
                              Selectionnez un partenaire
                            </template>
                            <template v-else>
                              <div class="w-100 overflow-auto d-flex">

                                <template v-if="!empty(contract.partnerCode)">
                                  <PartnerView :value="contract.partnerCode"/>
                                </template>
                                <template v-else-if="!empty(contract.partner)">
                                  <PartnerView :value="contract.partner.code"/>
                                </template>
                                <template v-else>
                                  {{ contract.partnerCode }}
                                </template>
                                <!--                                <div v-for="code in search.partners"-->
                                <!--                                     class="badge badge-primary mx-1 d-flex align-items-center">-->
                                <!--                                  {{ getPartnerNameByCode(code) }}-->
                                <!--                                  <i class="fad fa-times ps-2 cursor-pointer text-white"-->
                                <!--                                     @click="togglePartner(code)"></i>-->
                                <!--                                </div>-->
                              </div>
                            </template>
                            <i class="fad fa-angle-down ms-5 fs-2"></i>
                          </div>
                        </template>
                        <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                          <template>
                            <div class="overflow-auto border-rounded" style="max-height:250px">
                              <div class="p-1">
                                <input type="text" placeholder="Rechercher" v-model="partnerSearched"
                                       class="form-control">
                              </div>
                              <template v-for="partner of partnerUsers">
                                <template
                                    v-if="empty(partnerSearched) || (!empty(partnerSearched) && (!empty(partner.name) && partner.name.trim().toLowerCase().includes(partnerSearched.toLowerCase())) || (partner.code.trim().toLowerCase().includes(partnerSearched.toLowerCase())))">
                                  <div class="bg-hover-light-primary px-3 py-2 border-top"
                                       @click="switchPartner(partner.code)">
                                    <div class="fw-bolder">
                                      {{ partner.name }}
                                    </div>
                                    <div class="fw-bolder text-muted fs-8">
                                      {{ partner.code }}
                                    </div>
                                  </div>
                                </template>
                              </template>
                            </div>
                          </template>
                        </b-dropdown-text>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="mx-5 fw-bolder">
                    Gestionnaire
                    <br>
                    <div>
                      <VueMultiselect
                          :options="managers"
                          :selectedLabel="'Ok'"
                          :tagPlaceholder="trans('Gestionnaire')"
                          placeholder="Gestionnaire"
                          :close-on-select="true"
                          select-label="+"
                          :value="contract.manager"
                          @input="updateManager"
                          tag-placeholder="+"
                          track-by="uuid"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <user-view
                              v-model="props.option"
                              :oneLine="true"
                          ></user-view>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div>
                            <div class="fs-7">
                              {{ props.option.name }} {{ props.option.prename }}
                            </div>
                            <div class="fs-8 mt-1">
                              {{ props.option.email }}
                            </div>
                          </div>
                        </template>
                      </VueMultiselect>
                      <!--                      <user-view v-model="contract.manager"></user-view>-->
                    </div>
                  </div>
                  <div class="mx-5 fw-bolder">
                    Status
                    <div>
                      <div
                          :class="{
                          'bg-success':
                            contract.status ===
                            CONSTANTS.CONTRACT_STATUS.ONGOING,
                          'bg-warning':
                            contract.status ===
                            CONSTANTS.CONTRACT_STATUS.QUOTATION,
                          'bg-danger':
                            contract.status ===
                            CONSTANTS.CONTRACT_STATUS.TERMINATED,
                          'bg-primary':
                            contract.status ===
                            CONSTANTS.CONTRACT_STATUS.SUSPENDED,
                        }"
                          class="btn btn-sm btn-primary me-3"
                      >
                        {{ contract.status }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <div class="d-flex flex-wrap align-items-center">
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                          <i class="fad fa-coins"></i>
                        </span>
                        <div class="fs-2 fw-bolder counted">
                          {{ currency(contract.primeTTC) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Prime TTC</div>
                    </div>

                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                          <i class="fad fa-coins"></i>
                        </span>
                        <div class="fs-2 fw-bolder counted">
                          {{ currency(contract.subscriber.account.balance) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Solde Client</div>
                    </div>

                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                          <i class="fad fa-coins"></i>
                        </span>
                        <div class="fs-2 fw-bolder counted">
                          {{ currency(contract.subscriber.contractValue) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">
                        Valeur Client
                      </div>
                    </div>
                  </div>


                  <div>

                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>
          <Loading v-else></Loading>
          <b-tabs
              active-nav-item-class=""
              active-tab-class=""
              nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap    flex-grow-1"
              v-model="tabIndex"
          >
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadAvenants">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-layer-group fs-2 me-2"></i>
                  Avenants
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadItems">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-object-group fs-2 me-2"></i>
                  Actifs
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-file-contract fs-2 me-2"></i>
                  Documents
                </div>
              </template>
            </b-tab>
            <b-tab @click="loadPayments"
                   :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'"
            >
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-coins fs-2 me-2"></i>
                  Paiements
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="card" key="avenants">
        <div class="card-body">
          <Loading v-if="isLoading"></Loading>
          <template v-else-if="contract">
            <div v-if="tabIndex === 0">
              <table class="table table-stripped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Assureur</th>
                  <th>Création</th>
                  <th>Emission</th>
                  <th>Période</th>
                  <th>Montant</th>
                  <th>Reste à payer</th>
                  <th>Statut</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="avenant in contract.avenants">
                  <tr :key="'avenant_list_'+avenant.uuid">
                    <td>
                      <div>
                        <span class="text-primary fw-bolder">AV{{ avenant.numero }}</span>
                        <span class="px-1">▪</span>
                        <span class="fw-bolder">{{ avenant.type }}</span>
                      </div>
                      <div v-if="avenant.insurer" class="fw-bolder">
                        {{ avenant.insurer.label }}
                      </div>
                      <div class="text-muted fw-bolder">
                        {{ avenant.police }}
                      </div>
                    </td>
                    <td>
                      <div class="">
                        <UserView v-model="avenant.createBy"/>
                        <div class="text-primary fw-bolder">
                          {{ readableDate(avenant.createdAt, "dmy Hi") }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <UserView v-model="avenant.emitBy"/>
                        <div class="text-primary fw-bolder">
                          {{ readableDate(avenant.dateEmission, "dmy Hi") }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="fw-bolder d-flex align-items-center">
                        <div :title="readableDate(avenant.dateEffet, 'dmY')"
                             v-b-tooltip.hover v-if="avenant.dateEffet">
                          {{ readableDate(avenant.dateEffet, "dmy") }}
                        </div>
                        <div class="mx-1 d-flex align-items-center">↔</div>
                        <div :title="readableDate(avenant.dateEcheance, 'dmY')"
                             v-b-tooltip.hover v-if="avenant.dateEcheance">
                          {{ readableDate(avenant.dateEcheance, "dmy") }}
                        </div>
                      </div>
                      <div class="fw-bolder text-gray-400">
                        {{ avenant.duree }}
                      </div>
                    </td>
                    <td class="fw-bolder">{{ currency(avenant.primeTTC) }}</td>
                    <td class="fw-bolder text-danger">
                      {{ currency(avenant.balance, true) }}
                    </td>
                    <td>
                      <span class="badge" :class="{
                          'badge-warning': avenant.status === CONSTANTS.AVENANT_STATUS.PROJET,
                          'badge-primary': avenant.status === CONSTANTS.AVENANT_STATUS.VALIDE,
                          'badge-success': avenant.status === CONSTANTS.AVENANT_STATUS.EN_COURS}">
                        {{ avenant.status }}
                      </span>
                    </td>
                    <td class="text-end">
                      <!-- <a :href="coreEndpoint+ 'avenant/generate/devis/'+avenant.uuid"
                               class="me-2 btn btn-icon btn-xs btn-warning"
                               target="_blank">
                                <i class="fad fa-file-download"></i>
                            </a> -->
                      <div
                          @click="emitAvenant(avenant)"
                          class="me-2 btn btn-icon btn-xs btn-warning"
                          v-if="
                          avenant.status === CONSTANTS.AVENANT_STATUS.PROJET &&
                          hasRole('ROLE_MANAGER')
                        "
                          target="_blank"
                      >
                        <i class="fad fa-check-double"></i>
                      </div>
                      <div @click="setAvenant(avenant)" class="btn btn-primary me-2 btn-xs btn-icon" v-b-modal="'avenantViewer'">
                        <i class="fad fa-file-signature"></i>
                      </div>

                      <b-dropdown
                          no-caret
                          no-flip
                          right
                          size="sm"
                          tag="div"
                          toggle-class="topbar-item text-decoration-none p-0"
                          v-if="avenant.balance !== 0 && hasRole('ROLE_MANAGER')"
                          variant="link"
                      >
                        <template v-slot:button-content>
                          <div
                              @click="amount = abs(avenant.balance)"
                              class="btn btn-success me-2 btn-xs btn-icon"
                          >
                            <i class="fad fa-money-bill-wave"></i>
                          </div>
                        </template>
                        <b-dropdown-text class="min-w-md-350px p-5" tag="div">
                          <h4>AV{{ avenant.numero }} ▪ {{ avenant.type }}</h4>
                          <div>
                            <label class="fw-bolder mb-2" for="">{{
                                trans("Recharger le compte client et payer")
                              }}</label>
                            <div>
                              <b-input-group>
                                <b-form-input
                                    class="text-end"
                                    type="number"
                                    v-model="amount"
                                ></b-form-input>
                                <b-input-group-append>
                                  <div
                                      @click="payAvenant(avenant)"
                                      class="btn btn-sm border-radius-left-0 d-flex align-items-center btn-primary h-100"
                                  >
                                    <i class="fad fa-money-bill"></i>
                                    Payer
                                  </div>
                                </b-input-group-append>
                              </b-input-group>
                            </div>
                            <div class="mt-5">
                              Si le compte client est suffisant le rechargement
                              ne sera pas effectué
                            </div>

                            <div class="fw-bolder my-2">
                              Envoyez un lien de paiement
                            </div>

                            <div class="">
                              <template v-for="method in paymentMethods">
                                <div
                                    class="m-1 cursor-pointer d-flex align-items-center"
                                    v-if="!method.requireManagerValidation"
                                >
                                  <img
                                      :src="uploadRoot + method.photo"
                                      alt=""
                                      class="w-40px h-40px rounded"
                                  />
                                  <div class="position-relative">
                                    <input
                                        type="text"
                                        class="form-control ms-1 w-150px text-end"
                                        v-model="linkPhoneNumber"
                                    />
                                    <div
                                        class="position-absolute"
                                        style="
                                        left: 10px;
                                        top: 8px;
                                        pointer-events: none;
                                      "
                                    >
                                      <i class="fad fa-phone-alt"></i>
                                    </div>
                                  </div>
                                  <div class="mx-1 position-relative">
                                    <input
                                        type="number"
                                        class="form-control ms-1 w-150px text-end"
                                        v-model="amount"
                                    />
                                    <div
                                        class="position-absolute"
                                        style="
                                        left: 10px;
                                        top: 8px;
                                        pointer-events: none;
                                      "
                                    >
                                      <i class="fad fa-money-bill"></i>
                                    </div>
                                  </div>
                                  <div
                                      class="btn btn-icon btn-primary ms-1 btn-sm px-1"
                                      @click="sendPaymentLink(method, avenant)"
                                  >
                                    <i class="fad fa-paper-plane mx-2"></i>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </b-dropdown-text>
                      </b-dropdown>

                      <div class="btn btn-danger me-2 btn-xs btn-icon" @click="removeAvenantProject(avenant)"
                           v-if="avenant.status === CONSTANTS.AVENANT_STATUS.PROJET || hasRole('ROLE_ADMIN')">
                        <i class="fad fa-ban"></i>
                      </div>

                      <router-link :to="{name: 'avenant-show',params: { uuid: avenant.uuid }}"
                                   class="btn btn-dark btn-xs btn-icon">
                        <i class="fad fa-eye"></i>
                      </router-link>
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
            <div v-if="tabIndex === 1">

              <div class="py-5">
                <div class="d-flex justify-content-end align-items-center">


                  <b-dropdown class="" no-flip right tag="div" variant="primary">
                    <template v-slot:button-content>
                      Sélectionnez une action
                    </template>
                    <b-dropdown-item tag="div" @click="subscribeRenewalCampaign">
                      <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                        <i class="fad fa-sms fs-2 me-2"></i>
                        Lancer la campagne de renouvellement
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item tag="div">
                      <router-link :to="{name: 'contract-renewal',params: { uuid: contract.uuid }}" class="text-dark">
                        <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                          <i class="fad fa-sync fs-2 me-2"></i>
                          Renouveller le contrat
                        </div>
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item tag="div">
                      <router-link :to="{name: 'contract-add-remove',params: { uuid: contract.uuid }}" class="text-dark">
                        <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                          <i class="fad fa-edit fs-2 me-2"></i>
                          Ajouter ou Retirer des objets
                        </div>
                      </router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <Loading v-if="isLoading"></Loading>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Actif</th>
                  <th>Assuré</th>
                  <th>Pack</th>
                  <th>Ajoute le</th>
                  <th>Prime</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="item in contractItems">
                  <tr
                      style="height: 1px"
                      v-if="item.model &&
                        item.model.className !== 'Customer' &&
                        !item.model.isRoot &&
                        (!item.parentItem ||
                          (item.parentItem && item.parentItem.expand))
                      "
                  >
                    <td class="p-0">
                      <label
                          :class="{ 'fw-bolder': itemHasChildren(item) }"
                          class="cursor-pointer w-100 h-100 d-flex align-items-center"
                      >
                        <input
                            @change="toggleItemExpand(item, $event)"
                            class="hidden"
                            type="checkbox"
                            v-model="item.expand"
                        />
                        <template v-for="index in item.level">
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </template>
                        <template v-if="itemHasChildren(item)">
                          <i
                              class="fad fa-angle-right me-2"
                              v-if="!item.expand"
                          ></i>
                          <i class="fad fa-angle-down me-2" v-else></i>
                        </template>
                        {{ item.model.name }}
                        <template v-if="!helper.empty(item.defaultAlias)">
                          {{ item.defaultAlias }}
                        </template>
                      </label>
                    </td>
                    <td>
                        <span class="fw-bolder text-uppercase">
                          <!--                                                <template v-if="item.beneficiary">-->
                          <!--                                                    {{item.beneficiary.properties}}-->
                          <!--                                                </template>-->
                          {{ item.beneficiaryIdentifier }}
                        </span>
                    </td>
                    <td>
                      {{ getItemPack(item) }}
                    </td>
                    <td>
                      {{ readableDate(item.addedAt, "dmY H:i") }}
                    </td>
                    <td class="text-end fw-bolder whitespace-no-wrap">
                      <template v-if="!helper.empty(item.premium)">
                        {{ currency(item.premium) }}
                      </template>
                      <span class="badge badge-secondary" v-else>{{ currency(item.calculatedPremium) }}</span>
                    </td>
                    <td class="text-end">
                      <div class="d-flex justify-content-end">
                        <b-dropdown no-caret no-flip right tag="div"
                                    toggle-class="topbar-item text-decoration-none p-0 me-2"
                                    variant="link" v-if="item.model.printables.length > 0">
                          <template v-slot:button-content>
                            <div class="btn btn-xs btn-light-primary btn-icon rounded">
                              <i class="fad fa-print"></i>
                            </div>
                          </template>
                          <template #default="{ hide }">
                            <template v-for="(printable, index) in item.model.printables">
                              <div :class="{ 'border-top': index > 0 }"
                                   class="d-flex justify-content-between px-3 py-2 align-items-center">
                                <div class="whitespace-no-wrap flex-grow-1">
                                  {{ printable.label }}
                                </div>
                                <router-link
                                    :to="{name: 'printable-view',params: {itemUuid: item.uuid,printableUuid: printable.uuid}}"
                                    class="d-flex justify-content-end ms-5" target="_blank">
                                  <div class="btn btn-xs btn-primary btn-icon mx-1">
                                    <i class="fad fa-eye"></i>
                                  </div>
                                </router-link>
                              </div>
                            </template>
                          </template>
                        </b-dropdown>
                        <router-link :to="{ name: 'item-viewer',params: {uuid: item.uuid,type: item.model.code}}"
                                     class="btn btn-xs btn-icon btn-light-dark">
                          <i class="fad fa-eye"></i>
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div v-if="tabIndex === 2">
              <div class="card mb-5">
                <div
                    class="card-body border border-dashed border-gray-300 rounded min-w-700px"
                >
                  <Loading v-if="isLoading"></Loading>
                  <h3>A télécharger</h3>
                  <div class="d-flex align-items-center p-5">
                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                      <div class="d-flex align-items-center me-2">
                        <i class="fad fa-file-pdf fs-3x text-primary"></i>
                      </div>
                      <div class="ms-1 fw-bold">
                        <a class="fs-6 text-hover-primary fw-bolder" href="#"
                        >Devis</a
                        >
                        <br/>
                        <span class="fs-6x fw-bolder text-gray-400">80 kb</span>
                      </div>
                    </div>
                    <div class="d-flex flex-aligns-center">
                      <div class="d-flex align-items-center me-2">
                        <i class="fad fa-file-pdf fs-3x text-primary"></i>
                      </div>
                      <div class="ms-1 fw-bold">
                        <a class="fs-6 text-hover-primary fw-bolder" href="#"
                        >Contrat</a
                        >
                        <br/>
                        <span class="fs-6x fw-bolder text-gray-400">80 kb</span>
                      </div>
                    </div>
                  </div>

                  <div class="separator my-10"></div>
                  <h3>A Fournir</h3>
                  <div class="d-flex align-items-center p-5">
                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                      <div class="d-flex align-items-center me-2">
                        <i class="fad fa-file-upload fs-3x text-primary"></i>
                      </div>
                      <div class="ms-1 fw-bold">
                        <!--                                                <a class="fs-6 text-hover-primary fw-bolder"-->
                        <!--                                                   href="#">-->
                        <!--                                                    title-->
                        <!--                                                </a>-->
                        <!--                                                <div class="text-gray-400">-->
                        <!--                                                    excerpt-->
                        <!--                                                    <br>-->
                        <!--                                                    <div class="d-flex align-items-center justify-content-between">-->
                        <!--                                                        <div class="d-flex">-->
                        <!--                                        <span class="btn btn-icon btn-sm bg-light-success ms-2">-->
                        <!--                                            <i class="fad fa-check-circle text-success"></i>-->
                        <!--                                        </span>-->
                        <!--                                                            <FileUpload :allowMultiple="false"-->
                        <!--                                                                        :allowedType="'image/jpeg,image/png,image/gif,'+-->
                        <!--                                                                                                        +'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'+-->
                        <!--                                                                                                        +'application/msword,application/pdf'"-->

                        <!--                                                                        :icon="'fad fa-edit'"-->
                        <!--                                                                        :text="''"-->
                        <!--                                                                        :type="'button'"-->
                        <!--                                                            ></FileUpload>-->

                        <!--                                                            <span class="btn btn-icon btn-sm bg-light-danger ms-2">-->
                        <!--                                                            <i class="fad fa-times text-danger"></i>-->
                        <!--                                                            </span>-->
                        <!--                                                        </div>-->
                        <!--                                                    </div>-->
                        <!--                                                </div>-->
                        <div class="text-danger">
                          Ce document est manquant
                          <br/>
                          <FileUpload
                              :allowMultiple="false"
                              :allowRemove="false"
                              :allowedType="
                              'image/jpeg,image/png,image/gif,' +
                              +'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
                              +'application/msword,application/pdf'
                            "
                              :text="'Selectionnez un fichier'"
                          >
                          </FileUpload>
                          <!--                                <div class="btn btn-sm btn-primary">-->
                          <!--                                    <i class="fad fa-cloud-download"></i> Uploader-->
                          <!--                                </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="tabIndex === 3">
              <Loading v-if="isLoading"></Loading>
              <table class="table table-stripped gy-3 gs-3">
                <thead>
                <tr
                    class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200"
                >
                  <th>Libellé</th>
                  <th>Initiateur</th>
                  <th>Montant</th>
                  <th>Reçu</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="op in operations">
                  <td>
                    <div class="d-flex">
                      <!--                                                <span v-if="op.type === 'Débit'">-->
                      <!--                                                    Paiement-->
                      <!--                                                </span>-->
                      <!--                        <span v-if="op.type === 'Crédit'">-->
                      <!--                                                    Retrait-->
                      <!--                                                </span>-->
                      <span class="ms-3 fw-bolder">
                            {{ op.transaction.label }}
                          </span>
                    </div>
                    <div class="fs-6 text-gray-400 fw-bolder">
                      {{ readableDate(op.createdAt) }}
                    </div>
                  </td>
                  <td>
                        <span class="fw-bolder">
                          <user-view v-model="op.transaction.initiator"/>
                        </span>
                  </td>
                  <td>
                        <span class="fw-bolder">
                          {{ currency(op.amount) }}
                        </span>
                  </td>
                  <td>
                    <router-link
                        target="_blank"
                        :to="{
                            name: 'receipt-show',
                            params: { uuid: op.transaction.receipt.uuid },
                          }"
                        class="btn btn-sm btn-icon btn-primary"
                        v-if="op.transaction.receipt"
                    >
                      <i class="fad fa-download"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="tabIndex === 4">4</div>
          </template>
        </div>
      </div>

      <b-modal
          :ref="'avenantViewer'"
          :title="trans('Avenant')"
          centered
          class="modal-fullscreen"
          id="avenantViewer"
          scrollable
          size="xl"
      >
        <div v-if="avenant">
          <div class="d-flex align-items-center">
            <h2 class="flex-grow-1">
              AV{{ avenant.numero }} ▪ {{ avenant.type }}
            </h2>
            <span
                :class="{
                'badge-warning':
                  avenant.status === CONSTANTS.AVENANT_STATUS.PROJET,
                'badge-success':
                  avenant.status === CONSTANTS.AVENANT_STATUS.VALIDE,
              }"
                class="badge fs-2"
            >
              {{ avenant.status }}
            </span>
          </div>
          <div class="mt-5">
            <div class="fw-bolder d-flex align-items-center">
              <div
                  :title="readableDate(avenant.dateEffet, 'dmY')"
                  v-b-tooltip.hover
                  v-if="avenant.dateEffet"
              >
                {{ readableDate(avenant.dateEffet, "dmy") }}
              </div>
              <div class="mx-1 d-flex align-items-center">↔</div>
              <div
                  :title="readableDate(avenant.dateEcheance, 'dmY')"
                  v-b-tooltip.hover
                  v-if="avenant.dateEcheance"
              >
                {{ readableDate(avenant.dateEcheance, "dmy") }}
              </div>
            </div>
          </div>

          <div
              class="d-flex fw-bolder mt-5 align-items-center fs-6 flex-wrap justify-content-start"
          >
            <div class="me-5 mb-5" v-for="pd in avenant.premiumDetails">
              {{ pd.model.label }} :
              <span class="badge badge-primary fs-6">{{
                  currency(pd.premium)
                }}</span>
            </div>
          </div>
          <div class="d-flex fw-bolder align-items-center fs-6 mb-5">
            <div class="">
              Prime TTC :
              <span class="badge badge-secondary fs-6">{{
                  currency(avenant.primeTTC)
                }}</span>
            </div>
            <div class="border-start ms-4 ps-4">
              Reste à Payer :
              <span class="badge badge-danger fs-6">
                {{ currency(avenant.balance, true) }}</span
              >
            </div>
          </div>
          <!--                    <div class="border-top border-bottom pt-5">-->
          <!--                        <h4>Details</h4>-->
          <!--                    </div>-->
          <Loading v-if="isLoadingAvenant"></Loading>
          <table class="table table-striped" v-else>
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
              <th>Actif</th>
              <th>Pack</th>
              <!--                            <th>Garanties</th>-->
              <th>Bénéficiaire</th>
              <th class="text-end">Prime</th>
              <th class="text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="item in itemList">
              <tr
                  style="height: 1px"
                  v-if="
                    item.model.className !== 'Customer' &&
                    !item.model.isRoot &&
                    (!item.parentItem ||
                      (item.parentItem && item.parentItem.expand))
                  "
              >
                <td class="p-0">
                  <label
                      :class="{ 'fw-bolder': itemHasChildren(item) }"
                      class="cursor-pointer w-100 h-100 d-flex align-items-center"
                  >
                    <input
                        @change="toggleItemExpand(item, $event)"
                        class="hidden"
                        type="checkbox"
                        v-model="item.expand"
                    />
                    <template v-for="index in item.level">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </template>
                    <template v-if="itemHasChildren(item)">
                      <i
                          class="fad fa-angle-right me-2"
                          v-if="!item.expand"
                      ></i>
                      <i class="fad fa-angle-down me-2" v-else></i>
                    </template>
                    <template class="" v-if="avenant.ins.includes(item.uuid)">
                      Incorporation
                    </template>
                    <!--                                        <template v-if="item.model.name !== item.name">-->
                    {{ item.model.name }}
                    <!--                                        </template>-->
                    <!--                                        {{item.name}}-->
                    <template v-if="!helper.empty(item.defaultAlias)">
                      {{ item.defaultAlias }}
                    </template>

                    <span
                        class="bg-light-success ms-4"
                        v-if="avenant.ins.includes(item.uuid)"
                    >
                        <i class="fad fa-plus-circle text-success"></i>
                      </span>
                  </label>
                </td>
                <td>
                  {{ getItemPack(item) }}
                </td>
                <!--                                <td>-->
                <!--                                    &lt;!&ndash;                                    <div class="d-flex flex-wrap">&ndash;&gt;-->
                <!--                                    &lt;!&ndash;                                        <div class="btn btn-xs btn-icon m-1 btn-success" v-for="guarantee in item.guarantees" :title="guarantee.label" v-b-tooltip.hover>&ndash;&gt;-->
                <!--                                    &lt;!&ndash;                                            <img :src="uploadRoot+guarantee.icon" alt="" class="mw-15px mh-15px">&ndash;&gt;-->
                <!--                                    &lt;!&ndash;&lt;!&ndash;                                            {{guarantee.label}}&ndash;&gt;&ndash;&gt;-->
                <!--                                    &lt;!&ndash;                                        </div>&ndash;&gt;-->
                <!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->
                <!--                                </td>-->
                <td>
                  &nbsp;
                  <span class="fw-bolder text-uppercase">
                      {{ item.beneficiaryIdentifier }}
                    </span>
                </td>
                <td class="text-end fw-bolder whitespace-no-wrap">
                  <template v-if="!helper.empty(item.premium)">
                    {{ currency(item.premium) }}
                  </template>
                  <span class="badge badge-secondary" v-else>{{
                      currency(item.calculatedPremium)
                    }}</span>
                </td>
                <td class="text-end">
                  <div class="d-flex justify-content-end">
                    <div class="btn btn-xs btn-icon btn-primary me-2">
                      <i class="fad fa-edit"></i>
                    </div>

                    <div class="btn btn-xs btn-icon btn-danger me-2">
                      <i class="fad fa-trash"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

          <div class="d-flex align-items-center p-5">
            <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
              <div class="d-flex align-items-center me-2">
                <i class="fad fa-file-pdf fs-3x text-primary"></i>
              </div>
              <div class="ms-1 fw-bold">
                <a
                    :href="
                    coreEndpoint +
                    'avenant/devis/' +
                    avenant.uuid +
                    '?zone=' +
                    $store.state.currentTenant.code
                  "
                    class="fs-6 text-hover-primary fw-bolder"
                    href="#"
                    target="_blank"
                >Devis</a
                >
                <br/>
                <a
                    :href="
                    coreEndpoint +
                    'avenant/devis/' +
                    avenant.uuid +
                    '?zone=' +
                    $store.state.currentTenant.code
                  "
                    class="btn btn-primary btn-sm"
                    target="_blank"
                >Visualiser</a
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import Contract from "@/entity/Contract";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Avenant from "@/entity/Avenant";
import CONSTANTS from "@/Utils/constant";
import Item from "@/entity/Item";
import UserView from "@/components/UserView.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import Property from "@/entity/Property";
import IncorporationDetail from "@/views/Contract/IncorporationDetail.vue";
import Printable from "@/entity/Printable";
import Swal from "sweetalert2";
import User from "@/entity/User";
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import Popup from "@/entity/Popup";
import {helper} from "@/services/Helper";
import AccountOperation from "@/entity/AccountOperation";
import PartnerView from "@/components/PartnerView.vue";

@Component({
  components: {
    PartnerView,
    IncorporationDetail,
    FileUpload,
    Loading,
    Base,
    UserView,
    VueMultiselect,
  },
})
export default class ContractShow extends Vue {
  contract: Contract | any = null;
  amount = 0;
  coreEndpoint = api.core;
  uploadRoot = api.uploadRoot;
  tabIndex = 0;
  isLoading = false;
  avenant: any = null;
  managers: User[] = [];
  partners: Partner[] = [];
  partnerUsers: Partner[] = [];
  userType: any = "manager";
  paymentMethods: PaymentMethod[] = [];
  paymentMethod = null;
  linkPhoneNumber = "";
  partnerSearched = "";

  getItemPack(item: Item) {
    let insurerLabel = ''
    let packLabel = ''
    if (item.pack && item.pack.insurer) {
      return item.pack.insurer.label + ' ▪ ' + item.pack.label
    }
    for (let property of item.properties) {
      if (property.model.type.code === 'PACK') {
        if (property.value) {
          const value = JSON.parse(property.value)
          if (value.pack && value.pack.label) {
            packLabel = value.pack.label
            if (value.pack.insurer && value.pack.insurer.label) {
              insurerLabel = value.pack.insurer.label
            }
          }
          if (helper.empty(insurerLabel) && value.insurer && value.insurer.label) {
            insurerLabel = value.insurer.label
          }
        }
      }
    }
    return insurerLabel + ' ' + packLabel;
  }

  async removeAvenantProject(avenant: Avenant) {

    const response = await Swal.fire({
      title: 'Supprimer',
      html: "Êtes-vous sûr de vouloir supprimer ce projet  d'avenant ?",
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
    })
    if (response.isConfirmed) {
      const res = await api.get(api.core, 'avenant/remove/project/' + avenant.uuid)
      if (res && res.data) {
        this.loadAvenants()
      }
    }


  }

  async mounted() {
    this.userType = localStorage.getItem("userType");
    // this.loadEngine();
    this.loadPaymentMethods();
    await this.loadContract();
    this.managers = await api.loadManagers();
    if (this.userType === "manager") {
      this.partners = await this.$store.getters.partners();
      this.partnerUsers = await this.$store.getters.partnerUsers();
    }
  }


  async switchPartner(code: string) {
    const response = await Swal.fire({
      title: 'Réattribuer ce contrat',
      html: 'Êtes-vous sûr de vouloir attribuer ce contrat à ce partenaire ? <br/><br/> La commission lié à ce contrat lui sera attribué en votre nom',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'contract/transfer/partner/' + this.contract.uuid + '/' + code)
      this.$store.commit('stopLoading')

    }
  }

  async subscribeRenewalCampaign() {
    if (this.contract) {
      this.$store.commit('loading')
      console.log(api.core + 'contract/subscribe/renewal/campaign/' + this.contract.uuid)
      const res = await api.get(api.core, 'contract/subscribe/renewal/campaign/' + this.contract.uuid)
      this.$store.commit('stopLoading')
    }
  }

  getPartnerNameByCode(code: string) {
    if (code === 'all') {
      return 'Tous les partenaires';
    }
    const partner = this.partners.find((partner: Partner) => {
      return partner.code === code
    })
    if (partner) {
      if (!helper.empty(partner.name)) {
        return partner.name
      }
      return partner.code
    }
    return code;
  }

  getLink(pm: PaymentMethod | null, avenant: Avenant) {
    let route = api.comparator + "avenant/payment/" + avenant.uuid;
    if (pm) {
      route += "/" + pm.slug;
    }
    return route;
  }

  async sendPaymentLink(pm: PaymentMethod | null, avenant: Avenant) {
    if (helper.empty(this.linkPhoneNumber)) {
      new Popup("Erreur", "Le contact est vide");
      return;
    }
    this.$store.commit("loading");
    const link = this.getLink(pm, avenant);
    if (pm) {
      const ref = "pm-button-" + pm.uuid;
      const refs = this.$refs as any;
      if (refs.hasOwnProperty(ref)) {
        refs[ref][0].hide();
      }
    }
    const res = await api.post(api.core, "avenant/send/payment/short", {
      uuid: avenant.uuid,
      link: link,
      contact: this.linkPhoneNumber,
    });
    this.$store.commit("stopLoading");
    if (res && res.data) {
      const pop = new Popup(
          "SMS Envoyé",
          "Un lien de paiement à été envoyé au " + this.linkPhoneNumber,
          "primary",
          "fad fa-sms"
      );
    }
  }

  async loadPaymentMethods() {
    this.isLoading = true
    const popup = new Popup(
        "Chargement ...",
        "",
        "warning",
        "fad fa-cogs",
        true
    );
    const res = await api.get(api.core, "payment/method/list");
    this.isLoading = false
    popup.hide();
    if (res && res.data) {
      this.paymentMethods = [];
      res.data.methods.forEach((method: PaymentMethod) => {
        this.paymentMethods.push(new PaymentMethod(method));
      });
    }
  }

  operations: AccountOperation[] = []

  async loadPayments() {
    this.isLoading = true
    const popup = new Popup(
        "Chargement ...",
        "",
        "warning",
        "fad fa-cogs",
        true
    );
    const res = await api.get(api.core, "contract/payment/list/" + this.$route.params.contractUuid);
    this.isLoading = false
    popup.hide();
    if (res && res.data && res.data.operations) {
      this.operations = [];
      res.data.operations.forEach((op: any) => {
        this.operations.push(new AccountOperation(op));
      });
    }
  }

  get willExpireSoon() {
    if (this.contract instanceof Contract) {
      const now = new Date();
      const diff = this.contract.expireAt.getTime() - now.getTime();
      let dayLeft = Math.ceil(diff / (1000 * 3600 * 24));
      if (dayLeft < 30) {
        return true;
      }
      return false;
    }
    return false;
  }

  async loadReceipt(uuid: string) {
    this.isLoading = true
    const res = await api.get(api.core, "payment/receipt/" + uuid);
    this.isLoading = false
  }

  async emitAvenant(avenant: Avenant) {
    // let timerInterval: any = null;
    const response = await Swal.fire({
      title: "Emission de contrat",
      html: "Êtes-vous sûr de vouloir emettre ce avenant sans encaissement ?",
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: "Emettre l'avenant",
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      },
    });
    if (response.isConfirmed) {
      this.$store.commit("loading");
      const res = await api.get(api.core, "avenant/emit/" + avenant.uuid);
      this.$store.commit("stopLoading");
      this.loadContract();
    }
  }

  customPartnerLabel(option: any) {
    // console.log(option)
    if (typeof option === "string") {
      return option;
    }
    return option.code;
  }

  updatePartner(e: any) {
    console.log(e);
  }

  async updateManager(e: any) {
    if (e && e.uuid) {
      const u = new User(e);
      const response = await Swal.fire({
        title: "Attribuer à " + u.identifier,
        html: "Êtes-vous sûr de vouloir changer le gestionnaire de ce contrat ?",
        timer: 10000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: "Changer",
        didOpen: () => {
        },
        willClose: () => {
        },
      });
      if (response.isConfirmed) {
        this.$store.commit("loading");
        const res = await api.get(
            api.core,
            "contract/update/manager/" + this.contract.uuid + "/" + e.uuid
        );
        this.$store.commit("stopLoading");
        if (res && res.data && res.data.contract) {
          this.contract = new Contract(res.data.contract);
        }
      }
    }
  }

  itemHasChildren(item: Item) {
    // let result = false
    return item.properties.some((p: Property) => p.children.length > 0);
    // item.properties.forEach((p: Property) => {
    //     if (p.children.length > 0) {
    //         result = true
    //     }
    // })
    // return result
  }

  toggleItemExpand(item: Item, evt: Event) {
    // console.log(evt)
    // console.log(evt instanceof Event)
    let value: any = evt;
    if (evt instanceof Event) {
      value = (evt.target as any).checked as any;
    } else {
      item.expand = value;
      // console.log('hide ' + item.name)
    }
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item) => {
        const index = this.itemList.findIndex((i: Item) => {
          return i.uuid === item.uuid;
        });
        if (index !== -1) {
          this.itemList[index].expand = item.expand;
          this.toggleItemExpand(c, value);
        }
      });
    });
  }

  previewPrintable(item: Item, printable: Printable) {
  }

  itemList: Item[] = [];
  isLoadingAvenant = false;

  async getAvenantData(avenant: Avenant) {
    this.isLoadingAvenant = true;
    const res = await api.get(api.core, "avenant/get/items/" + avenant.uuid);
    // console.log(res)
    this.isLoadingAvenant = false;
  }

  get contractItems() {
    if (this.contract && this.contract.root) {
      // console.log(this.getItemByLines(this.contract.root))
      return this.getItemByLines(this.contract.root);
    } else {
      return [];
    }
  }

  getItemByLines(item: Item, result?: Item[]) {
    if (!result) {
      result = [];
    }
    if (!result.includes(item)) {
      result.push(item);
    }
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item) => {
        c.parentItem = item;
        c.level = item.level + 1;
        this.getItemByLines(c, result);
      });
    });
    return result;
  }

  async setAvenant(avenant: Avenant) {

    this.avenant = avenant;
    if (!this.avenant.root) {
      this.isLoadingAvenant = true
      const res = await api.get(api.core, 'avenant/get/root/' + this.avenant.uuid)
      if (res && res.data && res.data.root) {
        this.avenant.root = new Item(res.data.root)
      }
      this.isLoadingAvenant = false
    }
    this.itemList = this.getItemByLines(this.avenant.root);
  }

  async payAvenant(avenant: Avenant) {
    // console.log(this.amount+' '+avenant.balance)
    // return
    if (Math.abs(avenant.balance) < this.amount) {
      const response = await Swal.fire({
        title: "Erreur montant",
        html: "Le montant du paiement est supérieur au montant dû",
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    this.$store.commit("loading");
    const res = await api.get(
        api.core,
        "checkout/pay/avenant/" + avenant.uuid + "/" + this.amount
    );
    if (res && res.data && res.data.status === "success") {
      this.loadContract();
    }
    this.$store.commit("stopLoading");
  }

  async loadAvenants() {
    this.isLoading = true
    const res = await api.get(api.core, 'contract/get/avenants/' + this.$route.params.contractUuid)
    if (res && res.data && res.data.avenants) {
      this.contract.avenants = []
      res.data.avenants.reverse()
      res.data.avenants.forEach((a: any) => {
        const occ = new Avenant(a)
        this.contract.avenants.push(occ)
      })
      res.data.previous.forEach((a: any) => {
        const occ = new Avenant(a)
        this.contract.avenants.push(occ)
      })
    }
    this.isLoading = false
  }

  async loadItems() {
    const res = await api.get(api.core, 'contract/get/root/item/' + this.$route.params.contractUuid)
    if (res && res.data && res.data.root) {
      this.contract.root = new Item(res.data.root)
    }
  }

  async loadContract() {
    this.isLoading = true;
    const res = await api.get(
        api.core,
        "contract/get/data/" + this.$route.params.contractUuid
    );
    if (res && res.data && res.data.contract) {
      // console.log(res.data.contract.avenants[0].root.properties[0].children[0].properties[19].children[0].properties)
      this.contract = new Contract(res.data.contract);
      // this.contract.avenants.reverse();
    }
    if (this.contract.status === CONSTANTS.CONTRACT_STATUS["QUOTATION"]) {
      this.tabIndex = 0;
      await this.loadAvenants()
    }
    if (this.contract.status === CONSTANTS.CONTRACT_STATUS["ONGOING"]) {
      this.tabIndex = 1;
      await this.loadItems()
    }
    this.linkPhoneNumber = this.contract.subscriber.mobile;
    this.isLoading = false;
    // console.log(this.contract)
    // console.log(this.contract.avenants[0].root.properties[0].children[0].properties[1].children[0].properties[6].children[0])
    // console.log(this.contract.avenants[0].root.properties[0].children[0].properties[19].children[0].properties[0].model.link)
  }

  async loadEngine() {
    const module_path = api.core + "engine/Auto/Engine.js";
    // const module_path = api.core + 'engine/EngineModule.js';

    // const Atlantique =

    // const Atlantique = await require(module_path)
    // console.log(Atlantique)

    // const plugin = document.createElement("script");
    // plugin.setAttribute(
    //     "type",
    //     'text/javascript'
    // );
    // plugin.setAttribute(
    //     "src",
    //     module_path
    // );
    // plugin.async = true;
    // document.head.appendChild(plugin);
    // plugin.addEventListener('load', () => {
    //     const r = new Atlantique()
    //     console.log(r)
    //
    // })

    // const module = await import(module_path).then(({default: engine}) => {
    //     console.log(engine)
    // })
    // console.log(module)
    // module.default();
  }
}
</script>
<style scoped>
td {
  vertical-align: middle;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.2s;
}

.slide-left-leave-from {
  transition: all 0.2s;
}

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.modal-xl .modal-content {
  height: 100%;
}
</style>

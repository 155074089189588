<template>
  <div :class="{
        'w-100 float-none m-0': prop.model.type.code.match(/OBJECT/),
        'm-3': !prop.model.type.code.match(/OBJECT/)
    }" class="property-manager flex-grow-1" :key="uKey">
    <!--        {{prop.name}}<template v-if="parent">>>{{parent.name}}</template><template v-else> NO PARENT</template>-->
    <div class="d-flex align-items-center justify-content-start"
         v-if="!prop.model.type.code.match(/OBJECT|OBJECT_GROUP/)">
      <label class="label mb-2 fw-bolder" for="">{{ prop.model.name }}</label>
    </div>

    <!--      *****************************************************************************************************************************  -->
    <div v-if="prop.model.type.code.match(/TEXT|CONTACT|EMAIL|CAR_BRAND|CAR_MODEL/)">
      <input class="form-control" type="text" v-model="prop.value" :key="'property-'+uKey">
    </div>
    <div v-else-if="prop.model.type.code.match(/PHOTO/)">
      <FileUpload :allowMultiple="false" :allowRemove="false"
                  :allowedType="'image/jpeg,image/png,image/gif,image/jfif'"
                  :currentImageLink="!helper.empty(prop.value) ? uploadRoot + prop.value : false" :icon="'fad fa-edit'"
                  :previewClass="'d-flex border-dashed w-125px cursor-pointer h-125px align-items-center justify-content-center m-auto'"
                  :text="'Photo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif" v-model="prop.value"></FileUpload>
    </div>
    <div v-else-if="prop.model.type.code.match(/RADIO/)">
      <select @change="update" class="form-control form-select" v-model.trim="prop.value">
        <b-form-select-option :value="null">{{ trans('Selectionnez une option') }}
        </b-form-select-option>
        <b-form-select-option :key="choice.id" :value="choice.code" v-for="choice in prop.model.choices">
          {{ trans(choice.label) }}
        </b-form-select-option>
      </select>
    </div>
    <div class="d-flex flex-wrap" v-else-if="prop.model.type.code.match(/CHECKBOX/)">
      <label class="form-check form-switch form-check-custom form-check-solid d-flex m-1 flex-grow-1"
             v-for="choice in prop.model.choices">
        <input :value="choice.code" @change="update" class="form-check-input w-30px h-20px" type="checkbox"
               v-model="prop.value"/>
        <span class="form-check-label">{{ choice.label }}</span>
      </label>
    </div>
    <div v-else-if="prop.model.type.code.match(/NUMBER|AMOUNT/)">
      <input @change="update" class="form-control text-end" type="number" v-model="prop.value">
    </div>
    <div v-else-if="prop.model.type.code.match(/DATE|DATETIME/)">
      <input @change="update" class="form-control" type="date" v-model="prop.value">
    </div>
    <div v-else-if="prop.model.type.code.match(/PACK_CATEGORY/)">
      <div class="input-container flex-grow-1 rounded  d-flex">
        <select @change="packCategorySelected" class="form-control form-select" v-model.trim="prop.value">
          <b-form-select-option :value="null">{{ trans('Selectionnez une catégorie') }}
          </b-form-select-option>
          <b-form-select-option :key="category.uuid" :value="category.slug"
                                v-for="category, key in packCategories">
            {{ trans(category.label) }}
          </b-form-select-option>
        </select>
        <span :title="trans('Chargement')" class="input-status" v-b-tooltip.hover
              v-if="isLoadingPackCategories">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
      </div>
    </div>
    <div v-else-if="prop.model.type.code.match(/PACK/)">
      <select @change="packSelected" class="form-control form-select" v-model="prop.value">
        <b-form-select-option :value="null">{{ trans('Selectionnez un pack') }}
        </b-form-select-option>
        <template v-for="pack, key in packs">

          <b-form-select-option :key="pack.id" :value="pack.id" v-if="((insurer && pack.insurer.uuid === insurer.uuid) || !insurer)
                    && (!packCategory || (packCategory && pack.category.uuid === packCategory.uuid))">
            <template v-if="!insurer">
              {{ pack.insurer.label }} ▪
            </template>
            <template v-if="!packCategory">
              {{ pack.category.label }} ▪
            </template>
            {{ trans(pack.label) }}
          </b-form-select-option>
        </template>
      </select>
    </div>

    <div v-else-if="prop.model.type.code.match(/BOOLEAN/)">
      <label class="form-check form-switch form-check-custom form-check-solid d-flex flex-grow-1">
        <span class="btn btn-sm">{{ trans('Non') }}</span>
        <input @change="update" class="form-check-input" type="checkbox" v-model="prop.value" value=""/>
        <span class="form-check-label">{{ trans('Oui') }}</span>
      </label>
    </div>
    <div v-else-if="prop.model.type.code.match(/OBJECT_GROUP|OBJECT/)">
      <ItemManager :lockComparison="lockComparison" :insurer-restriction="insurerRestriction" :alias="prop.name"
                   :show-pack-selector="showChildrenPackSelector"
                   :allowRemove="prop.model.type.code === 'OBJECT_GROUP'" :branch="branch"
                   :fromProp="prop" :insurers="insurers" :item="item" :items="items"
                   :optionalReceived="optionalSelected" :remove-unallowed-pack="true"
                   :packCategories="packCategories" :packFromParent="packFromParent" :packs="packs" :parent="parent"
                   :parentPack="parentPack" :premiumDetailModels="premiumDetailModels" v-for="item in prop.children"
                   :key="'item-manager-'+item.uuid"
                   v-on:appendItem="appendItem" v-on:assetAdded="assetAdded" v-on:update="handleUpdate"></ItemManager>
    </div>
    <div v-else>
            <span class="badge badge-danger">
                {{ prop.model.name }} ▪ {{ prop.model.type.code }} not handle
            </span>
    </div>
    <div class="d-flex align-items-center justify-content-between my-5"
         v-if="prop.model.type.code.match(/OBJECT_GROUP/) && ((prop.model.max && prop.children.length < prop.model.max) || !prop.model.max)">
      <div class="line"></div>
      <div @click="addModel(prop)" class="btn rounded-pill btn-warning mx-2">
        <i class="fad fa-plus"></i>
        Ajouter {{ prop.name }}
        <!--        {{ getItemByUuid(prop.value) ? getItemByUuid(prop.value).name : prop.model.name }}-->
        <!--        <template v-if="parent">-->
        <!--          {{ parent.name }}-->
        <!--        </template>-->
      </div>
      <div class="line"></div>
    </div>
    <!--      *****************************************************************************************************************************  -->
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Property from "@/entity/Property";
import FileUpload from "@/components/FileUpload.vue";
import Item from "@/entity/Item";
import Branch from "@/entity/Branch";
import PackCategory from "@/entity/PackCategory";
import {helper} from "@/services/Helper";
import Pack from "@/entity/Pack";
import PackGuarantee from "@/entity/PackGuarantee";
import PremiumDetailModel from "@/entity/PremiumDetailModel";
import Insurer from '@/entity/Insurer';
import ItemType from "@/entity/ItemType";
import {api} from "@/services/Api";
import CONSTANTS from "@/Utils/constant";
import ItemPropertyExtend from '@/entity/extends/ItemPropertyExtend';
import ItemProperty from '@/entity/ItemProperty';

@Component({
  name: 'PropertyManager',
  components: {FileUpload}
})
export default class PropertyManager extends Vue {
  @Prop() prop!: Property;
  @Prop() items!: Item[];
  @Prop() branch!: Branch;
  @Prop() parent!: Item
  uKey = helper.generateId()
  @Prop() packCategories!: PackCategory[]
  @Prop({default: null}) insurerRestriction: Insurer | undefined
  @Prop({default: true}) showChildrenPackSelector!: boolean
  @Prop({default: true}) lockComparison!: boolean
  @Prop() packFromParent!: boolean
  @Prop() parentPack?: Pack
  isLoadingPackCategories = false;
  @Prop() insurers!: Insurer[]
  @Prop() packs!: Pack[]
  insurer: any = null
  packCategory: any = null
  uploadRoot = api.uploadRoot
  @Prop() optionalSelected!: PackGuarantee[]
  @Prop() premiumDetailModels!: PremiumDetailModel[]


  mounted() {
    // if (this.prop.model.type.code.match(/OBJECT_GROUP/)) {
    //
    //
    //   console.log(this.prop)
    //   const parentType = this.getItemByUuid(this.prop.value!)
    //   // console.log(parentType)
    // }
    // console.log(this.prop)

    // console.log(this.parent.name)

    if (helper.empty(this.prop.value)) {
      if (!helper.empty(this.prop.model.value)) {
        this.prop.value = this.prop.model.value
      } else if (this.prop.model.type.code === 'RADIO' && this.prop.model.choices.length > 0) {
        this.prop.value = this.prop.model.choices[0].code
      }
    }
    if (this.prop.model!.type.code === 'OBJECT') {
      this.addUniqueModel(this.prop)
    }
    if (this.prop.model.type.code.match(/CHECKBOX/)) {
      this.prop.value = [] as any
    }
    if (this.prop.model.type.code === 'PACK') {
      if (this.prop.value && !this.parent.pack) {
        this.$emit('update', {type: 'packSelected', payload: this.prop.value})
      }

    }
    if (this.prop.model.type.code.match(/DATE/)) {
      if (CONSTANTS.dateConstants.hasOwnProperty(this.prop.value as any)) {
        const date = (CONSTANTS.dateConstants as any)[this.prop.value as any]()
        this.prop.value = date.toISOString().substring(0, 10)
      }
    }
    if (this.prop.model.type.code.match(/OBJECT_GROUP/) && this.prop.model.min && this.prop.model.min > 0) {
      const maxAttempt = 100
      let i = 0
      while (this.prop.children.length < this.prop.model.min && i < maxAttempt) {
        this.addModel(this.prop)
      }
      // console.log(this.prop.name)
      // console.log(this.prop)
    }
  }

  packSelected() {
    this.$emit('update', {type: 'packSelected', payload: this.prop.value})
  }

  handleUpdate() {
    this.$emit('update')
  }

  async update() {
    this.$store.state.recalculated = []
    // console.log('update property of ' + this.parent.model.name + ' ' + this.parent.generatedAlias)
    if (this.$store.state.contractUpdateComparison === this.parent.uuid) {
      // console.log('reset comparison uuid')
      this.$store.state.contractUpdateComparison = null
      await this.$store.state.sleep(100)
    }
    // console.log('set comparison uuid')
    this.$store.state.contractUpdateComparison = this.parent.uuid
    // this.$store.commit('updateContractItems', this.parent.uuid)
    // console.log('update received to prop ' + this.prop.name + ' and emitted')
    this.$emit('update')
  }

  packCategorySelected() {
    this.$emit('update', {type: 'packCategorySelected', payload: this.prop.value})
  }

  assetAdded() {
    this.$emit('assetAdded')
  }

  appendItem(occ: Item) {
    this.$emit('appendItem', occ)
  }

  addModel(prop: Property) {
    // return
    // console.log(prop.children.length + '/' + prop.model.max)
    // console.log('search '+prop.model!.value)
    const obj = this.items.find((item: Item) => {
      // console.log(item.model.uuid)
      return item.model!.uuid === prop.model!.value
    });
    if (obj instanceof Item) {
      const occ = new Item();
      occ.name = prop.model.name;
      if (prop.model.max && prop.model.max! > 1) {
        occ.name += ' N°' + (this.prop.children.length + 1)
      }
      // console.log(obj.name)
      occ.model = obj.model;
      occ.parent = this.prop;
      obj.properties.forEach((p: Property) => {
          const np = new Property();
          // np.item = occ;
          np.model = { ...p.model } as any;
          np.name = p.model.name
          np.value = p.value;
          occ.properties.push(np)
      });
      this.prop.children.push(occ)
      if (occ.model.className === 'Asset') {
        this.$emit('assetAdded')
      }
      this.update()
      // console.log(prop.children.length + '/' + prop.model.max)
    } else {
      console.error('OBJET ' + prop.model.name + ' INTROUVABLE')
    }
  }

  getItemByUuid(uuid: string) {
    const obj = this.items.find((item: Item) => {
      return item.model!.uuid === uuid
    });

    return obj
  }

  getItemTypeByUuid(uuid: string) {
    // const obj = this.itemTypes.find((item: Item) => {
    //   return item.model!.uuid === uuid
    // });
    //
    // return obj
  }

  addUniqueModel(prop: Property) {
    if (this.prop.children.length === 0) {
      const obj = this.items.find((item: Item) => {
        return item.model!.uuid === prop.model!.value
      });
      if (obj instanceof Item) {
        const occ = new Item();
        // console.log('unique '+obj.name)
        occ.name = prop.model.name;
        occ.model = obj.model;
        occ.parent = this.prop;
        obj.properties.forEach((p: Property) => {
            const np = new Property();
            // np.item = occ;
            np.name = p.model.name
            np.model = { ...p.model } as any;
            np.value = p.value;
            occ.properties.push(np)
        });
        // console.log(obj,occ)
        this.prop.children.push(occ)
        if (occ.model.className === 'Asset') {
          this.$emit('assetAdded')
        }
      } else {
        console.error('OBJET ' + prop.model.name + ' INTROUVABLE')
      }
    }
  }

  async loadPackCategories() {
    // console.log(this.branch)
    this.isLoadingPackCategories = true;
    this.packCategories = await this.$store.getters.packCategories(this.branch.slug);
    this.isLoadingPackCategories = false
  }
}
</script>
<style scoped>
.line {
  flex-grow: 1;
  height: 1px;
  background: #e8e8e8;
}

.input-container {
  position: relative;
}

.input-status {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>

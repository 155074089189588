<template>
  <div :class="{ 'animating': animating }" class="aside aside-light aside-hoverable" id="kt_aside">
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <router-link :to="{ name: 'home' }">
        <img alt="Logo" class="h-15px logo" src="../assets/logo.png"/>
      </router-link>

      <div @click="toggle()" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
           id="kt_aside_toggle">
        <span class="svg-icon svg-icon-1">
          <i class="fad fa-chevron-double-right" v-if="isMinimized()"></i>
          <i class="fad fa-chevron-double-left" v-else></i>
        </span>
      </div>
    </div>
    <div class="aside-menu">
      <div class="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper">
        <div
            class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            data-kt-menu="true" id="#kt_aside_menu">


          <template v-for="menu in menus()">
            <div v-if="pathIncludes(menu.pathMatch)">
              <div class="menu-item">
                <div class="menu-content pt-8 pb-2">
                  <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{ trans(menu.label) }}</span>
                </div>
              </div>
              <div class="menu-item">
                <div class="menu-content pt-8 pb-2">
                  <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{ trans(menu.label) }}</span>
                </div>
              </div>
              <div class="menu-item menu-title-gray-700" v-for="sub in menu.subMenus">
                <template v-if="sub.route">
                  <router-link :class="{ 'active': isActive(menu,sub) }" :to="sub.route" class="menu-link"
                               v-if="hasRole('ROLE_USER')">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <i :class="sub.icon+' '"></i>
                    </span>
                  </span>
                    <span class="menu-title">
                    {{ trans(sub.label) }}
                  </span>
                  </router-link>
                </template>
                <template v-else-if="sub.href">
                  <a :href="sub.href" target="_blank" :class="{ 'active': isActive(menu,sub) }" class="menu-link"
                     v-if="hasRole('ROLE_USER')">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <i :class="sub.icon+' '"></i>
                    </span>
                  </span>
                    <span class="menu-title">
                    {{ trans(sub.label) }}
                  </span>
                  </a>
                </template>
              </div>
            </div>
          </template>
          <template v-if="pathIncludes('/income')">
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_COTATION') || hasRole('ROLE_PARTNER')">
              <router-link :class="{ 'active': route === 'income-index' }" :to="{ name: 'income-index' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-ballot-check"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Tableau de bord') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <div class="menu-content pt-8 pb-2">
                <span class="menu-section text-muted text-uppercase fs-8 ls-1">
                  {{ trans('Production') }}
                </span>
              </div>
            </div>


            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_COTATION') || hasRole('ROLE_PARTNER')">
              <router-link :class="{ 'active': route === 'cotation-list' }" :to="{ name: 'cotation-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-ballot-check"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Cotations') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_COMPARATOR')">
              <router-link :class="{ 'active': pathIncludes('comparator') }" :to="{ name: 'comparateur-index' }"
                           class="menu-link" target="_blank">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-balance-scale-left"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Comparateur') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_PAYMENT') || hasRole('ROLE_PARTNER')">
              <router-link :to="{ name: 'payment-list' }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-coins"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Paiements') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_INVOICE') ">
              <router-link :to="{ name: 'invoice-list' }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-file-invoice"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Factures') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_DELIVERY')">
              <router-link :to="{ name: 'delivery-list' }" class="menu-link">
                <span class="menu-icon position-relative">
                  <span class="badge badge-danger position-absolute badge-icon" style="transform: scale(.7);right:90%"
                        v-if="$store.state.deliveries.length > 0">
                    {{ $store.state.deliveries.length }}
                  </span>
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-box"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Livraisons') }}</span>

              </router-link>
            </div>

            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_CONTRACT') || hasRole('ROLE_PARTNER')">
              <router-link :class="{ 'active': route === 'contract-list' }" :to="{ name: 'contract-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-file-signature"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Contrats') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_AVENANTS')">
              <router-link :class="{ 'active': route === 'avenant-list' }" :to="{ name: 'avenant-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-list"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Avenants') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_CUSTOMER') || hasRole('ROLE_PARTNER')">
              <router-link :class="{ 'active': route === 'customer-list' }" :to="{ name: 'customer-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-users"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Clients') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_PARTNER')">
              <router-link :to="{ name: 'partner-list' }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-handshake"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Apporteurs') }}</span>
              </router-link>
            </div>

            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_PARTNER')">
              <router-link :to="{ name: 'parrainage-list' }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-ball-pile"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Parrainages') }}</span>
              </router-link>
            </div>


            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_PAYMENT_METHOD')">
              <router-link :class="{ 'active': route.includes('payment/method') }" :to="{ name: 'payment-method-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-money-bill-wave"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Methode de paiements') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('ROLE_PRODUCTION_STATE') || hasRole('ROLE_PARTNER')">
              <router-link :class="{ 'active': route.includes('income/state') }" :to="{ name: 'income-state' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-list"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Etats') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('')">
              <router-link :class="{ 'active': route.includes('income/import') }" :to="{ name: 'income-import' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-file-import"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Importer') }}</span>
              </router-link>
            </div>
            <div class="menu-item" v-if="hasRole('')">
              <router-link :class="{ 'active': route.includes('income/state') }" :to="{ name: 'income-statistic' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-chart-pie"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Statistic') }}</span>
              </router-link>
            </div>
          </template>
          <template v-if="pathIncludes('/sells')">
            <div class="menu-item">
              <a class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-handshake"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Contributors') }}</span>
              </a>
            </div>
            <div class="menu-item">
              <a :class="{ 'active': route.includes('customer') }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-users-crown"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Customers') }}</span>
              </a>
            </div>
            <div class="menu-item">
              <a :class="{ 'active': route.includes('customer') }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-chart-pie"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Statistic') }}</span>
              </a>
            </div>
          </template>
          <template v-if="pathIncludes('/crm')">

            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('crm-dashboard') }" :to="{ name: 'crm-index' }"
                           class="menu-link" v-if="hasRole('ROLE_COMMUNICATION')">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-code-branch"></i>
                  </span>
                </span>
                <span class="menu-title">
                  {{ trans('Dashboard') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <div class="menu-content pt-8 pb-2">
                <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{
                    trans('Prospection')
                  }}</span>
              </div>
            </div>


            <div v-if="hasRole('ROLE_MANAGER')">
              <div class="menu-item">
                <router-link :class="{ 'active': route.includes('handshake-list') }" :to="{ name: 'handshake-list' }"
                             class="menu-link">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <i class="fad fa-file-user"></i>
                    </span>
                  </span>
                  <span class="menu-title">{{ trans('Prise de contact') }}</span>
                </router-link>
              </div>

              <div class="menu-item">
                <router-link :class="{ 'active': route.includes('prospection-clients') }"
                             :to="{ name: 'prospection-clients' }" class="menu-link">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <i class="fad fa-users"></i>
                    </span>
                  </span>
                  <span class="menu-title">{{ trans('Prospects') }}</span>
                </router-link>
              </div>

              <div class="menu-item">
                <router-link :class="{ 'active': route.includes('company') }"
                             :to="{ name: 'company' }"
                             class="menu-link">
                                <span class="menu-icon">
                                    <span class="svg-icon svg-icon-2">
                                        <i class="fad fa-building"></i>
                                    </span>
                                </span>
                  <span class="menu-title">{{ trans('Entreprises') }}</span>
                </router-link>
              </div>


            </div>


            <div class="menu-item">
              <div class="menu-content pt-8 pb-2">
                <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{
                    trans('Communication')
                  }}</span>
              </div>
            </div>

            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('campaign/list') }" :to="{ name: 'campaign-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-chart-network"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Campagne') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('chat-whatsapp') }" :to="{ name: 'chat-whatsapp' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fab fa-whatsapp-square"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Chat Whatsapp') }}</span>
              </router-link>
            </div>


            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('crm/sms') }" :to="{ name: 'sms' }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-sms"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('SMS') }}</span>
              </router-link>
            </div>


            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('email/template') }" :to="{ name: 'email-template-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-inbox"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Modèle Email') }}</span>
              </router-link>
            </div>


            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('template/sms') }" :to="{ name: 'sms-template-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-comment"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Modèle SMS') }}</span>
              </router-link>
            </div>


            <div class="menu-item">
              <div class="menu-content pt-8 pb-2">
                <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{ trans('Autres') }}</span>
              </div>
            </div>


            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('extractor') }" :to="{ name: 'extractor' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-cars"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Extracteur') }}</span>
              </router-link>
            </div>


            <div class="menu-item">
              <router-link :class="{ 'active': route.includes('shortener') }" :to="{ name: 'url-shortener' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-link"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('URL Shrotener') }}</span>
              </router-link>
            </div>


          </template>
          <template v-if="pathIncludes('/admin')">
            <div class="menu-item">
              <router-link :class="{ 'active': pathIncludes('/admin/user') }" :to="{ name: 'admin-index' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-users"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Utilisateurs') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <router-link :class="{ 'active': pathIncludes('/admin/tenant') }" :to="{ name: 'tenant-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-boxes"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Tenants') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <router-link :class="{ 'active': pathIncludes('/admin/role') }" :to="{ name: 'role-list' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-shield-alt"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Roles') }}</span>
              </router-link>
            </div>
          </template>
          <template v-if="pathIncludes('/partner') && hasRole('ROLE_PARTNER')">
            <div class="menu-item">
              <router-link :class="{ 'active': isExactRoute('/partner/hierarchy') }" :to="{ name: 'partner-hierarchy' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-folder-tree"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Hierarchie') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <router-link :class="{ 'active': isExactRoute('/partner/agences') }" :to="{ name: 'partner-agencies' }"
                           class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-building"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Agences') }}</span>
              </router-link>
            </div>

            <div class="menu-item">
              <router-link :class="{ 'active': pathIncludes('/partner-admin/users') }"
                           :to="{ name: 'partner-admin-users' }" class="menu-link">
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <i class="fad fa-users"></i>
                  </span>
                </span>
                <span class="menu-title">{{ trans('Utilisateurs') }}</span>
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="aside-footer flex-column-auto" id="kt_aside_footer"></div>
  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {auth} from "@/services/Auth";
import ComparatorMenu from "@/layouts/Menus/ComparatorMenu.vue";
import menus from "@/router/Menu";
import {helper} from "@/services/Helper";

@Component({
  methods: {
    menus() {
      return menus
    }
  },
  components: {ComparatorMenu}
})
export default class LeftAside extends Vue {
  public route = 'dashboard';
  public selected = '';
  public minimized = 'on';
  public animating = false;

  // public isAdmin = true;

  isAdmin(): boolean {
    return auth.isAdmin();
  }

  toggle(): void {
    const state = document.body.getAttribute('data-kt-aside-minimize');
    if (state === 'on') {
      document.body.setAttribute('data-kt-aside-minimize', 'off');
    } else {
      this.animating = true;
      document.body.setAttribute('data-kt-aside-minimize', 'on');
      const t = setTimeout(() => {
        this.animating = false;
        clearTimeout(t);
      }, 500);
    }
    const newState = document.body.getAttribute('data-kt-aside-minimize');
    if (newState) {
      localStorage.setItem('navState', newState);
    }
    this.$forceUpdate();

  }

  isMinimized(): boolean {
    return this.minimized === 'on';
  }

  isActive(menu: any, sub: any): boolean {
    if (!helper.empty(sub.route)) {
      if (this.$route.name === sub.route.name) {
        return true
      }
      const menuRoute: any = this.$router.getRoutes().find((r: any) => {
        return r.name === sub.route.name
      })
      if (this.$route.fullPath?.startsWith(menuRoute.path)) {
        return true
      }
    }

    if (!helper.empty(sub.pathMatch)) {
      return this.$router.currentRoute.path.includes(sub.pathMatch)
    }

    return false
  }

  isOpen(name: string): boolean {
    return this.selected.trim().length > 0 && this.selected.includes(name);
  }


  isExactRoute(name: string): boolean {
    return name === this.route;
  }

  isRouted(array: Array<any>) {
    return array.includes(this.route)
  }

  select(name: string): void {
    if (this.selected && this.selected.includes(name)) {
      this.selected = '';
    } else {
      this.selected = name;
    }
  }

  pathIncludes(name: string) {
    return this.$router.currentRoute.path.includes(name)
  }

  mounted(): void {

    if (this.$router.currentRoute.name) {
      this.route = this.$router.currentRoute.name;
      this.selected = this.$router.currentRoute.name;
    }
    // console.log(this.route)
    // console.log(this.$router.currentRoute.path)


    if (localStorage.getItem('navState')) {
      const navState = localStorage.getItem('navState');
      if (navState !== null) {
        this.minimized = navState;
        if (this.minimized === 'on') {
          document.body.setAttribute('data-kt-aside-minimize', 'on');
        } else {
          document.body.setAttribute('data-kt-aside-minimize', 'off');
        }
      }
    }
    // this.loadDeliveryQueries()
  }

  beforeUnmount() {

  }


}
</script>
<style scoped>
.open .menu-arrow {
  transform: rotateZ(90deg);
}

.open + .menu-sub {
  display: flex !important;
}

.router-link-exact-active {
  background: var(--bs-light-primary);
}

.router-link-exact-active .menu-title {
  color: var(--bs-primary) !important;
}
</style>

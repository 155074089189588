import {RouteConfig} from "vue-router";
import FormSelector from "@/views/Income/Comparator/FormSelector.vue";
import BranchSelector from "@/views/Income/Comparator/BranchSelector.vue";
import FormProcessor from "@/views/Income/Comparator/FormProcessor.vue";
import Statistic from "@/views/Income/Statistic.vue";
import FormProcessorAdminUi from "@/views/Income/Comparator/FormProcessorAdminUi.vue";
import ShowCotation from "@/views/Income/Comparator/ShowCotation.vue";
import CotationDebugger from "@/views/Income/Comparator/CotationDebugger.vue";
import ContractShow from "@/views/Income/ContractShow.vue";
import ContractList from "@/views/Income/ContractList.vue";
import AffaireNouvelle from "@/views/Income/Avenant/AffaireNouvelle.vue";
import CustomerShow from "@/views/Income/Customer/CustomerShow.vue";
import CustomerList from "@/views/Income/Customer/CustomerList.vue";
import Delivery from "@/views/Income/Delivery.vue";
import IncomeState from "@/views/Income/IncomeState.vue";
import IncomeImport from "@/views/Income/IncomeImport.vue";
import PartnerList from "@/views/Income/Partner/PartnerList.vue";
import PartnerShow from "@/views/Income/Partner/PartnerForm.vue";
import PaymentMethodList from "@/views/Income/PaymentMethodList.vue";
import PaymentList from '@/views/Income/PaymentList.vue';
import CotationList from '@/views/Income/CotationList.vue';
import CotationForm from '@/views/Income/Cotation/CotationForm.vue';
import PrintableView from '@/views/Income/Printable/PrintableView.vue';
import ItemViewer from "@/views/Income/Contract/ItemViewer.vue";
import Receipt from "@/views/Income/Printable/Receipt.vue";
import FullPrintableView from "@/views/Income/Printable/FullPrintableView.vue";
import PartnerForm from "@/views/Income/Partner/PartnerForm.vue";
import PartnerParameters from "@/views/Income/Partner/PartnerParameters.vue";
import Renouvellement from "@/views/Income/Avenant/Renouvellement.vue";
import AvenantShow from "@/views/Income/AvenantShow.vue";
import AvenantList from "@/views/Income/Avenant/AvenantList.vue";
import OrderShow from "@/views/Income/OrderShow.vue";
import ParrainageList from "@/views/Income/Parrainage/ParrainageList.vue";
import PartnerPackRestriction from "@/views/Income/Partner/PartnerPackRestriction.vue";


import Insights from "@/views/Income/Insights.vue";


import IncorporationRetrait from "@/views/Income/Avenant/IncorporationRetrait/IncorporationRetrait.vue";
import {auth} from "@/services/Auth";
import CotationDashboard from "@/views/Income/CotationDashboard.vue";
import PaymentShow from "@/views/Income/Payment/PaymentShow.vue";
import InvoiceList from "@/views/Income/Invoice/InvoiceList.vue";
import CreateInvoice from "@/views/Income/Invoice/CreateInvoice.vue";

const prefix = '/income'
const incomeRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'income-index',
        component: CotationDashboard,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/cotation/list',
        name: 'cotation-list',
        component: CotationList,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/show/cotation/:uuid',
        name: 'show-cotation',
        component: ShowCotation,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/debug/cotation/:uuid',
        name: 'debug-cotation',
        component: CotationDebugger,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/show/order/:uuid',
        name: 'show-order',
        component: OrderShow,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/delivery',
        name: 'delivery-list',
        component: Delivery,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/state',
        name: 'income-state',
        component: IncomeState,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/printable/:itemUuid/:printableUuid',
        name: 'printable-view',
        component: PrintableView,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/full/printable/:itemUuid/:printableUuid',
        name: 'full-printable-view',
        component: FullPrintableView,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/statistic',
        name: 'income-statistic',
        component: Statistic,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/insights',
        name: 'income-insights',
        component: Insights,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/import',
        name: 'income-import',
        component: IncomeImport,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/payments',
        name: 'payment-list',
        component: PaymentList,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/payment/:uuid',
        name: 'payment-show',
        component: PaymentShow,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/invoice',
        name: 'invoice-list',
        component: InvoiceList,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/invoice/form',
        name: 'invoice-form',
        component:CreateInvoice ,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/comparator/form/selector/:slug',
        name: 'comparator-form-selector',
        component: FormSelector,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },


    {
        path: '/comparator/:surveySlug/:uuid',
        name: 'comparator-form-processor',
        component: FormProcessorAdminUi,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },

    {
        path: '/comparator/full/screen/:surveySlug/:uuid',
        name: 'comparator-form-processor-full',
        component: FormProcessor,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },

    {
        path: '/affaire/nouvelle/:branchSlug',
        name: 'contract-creator',
        component: AffaireNouvelle,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/renouvellement/:uuid',
        name: 'contract-renewal',
        component: Renouvellement,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/incorporation/retrait/:uuid',
        name: 'contract-add-remove',
        component: IncorporationRetrait,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/cotation/:branchSlug/:uuid?',
        name: 'cotation-form',
        component: CotationForm,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },

    {
        path: '/contract/list',
        name: 'contract-list',
        component: ContractList,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/avenant/list',
        name: 'avenant-list',
        component: AvenantList,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/contract/show/:contractUuid',
        name: 'contract-show',
        component: ContractShow,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/partner/pack/restriction/:code?',
        name: 'partner-pack-restriction',
        component: PartnerPackRestriction,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/avenant/show/:uuid',
        name: 'avenant-show',
        component: AvenantShow,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/item/viewer/:type/:uuid',
        name: 'item-viewer',
        component: ItemViewer,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/customer/list',
        name: 'customer-list',
        component: CustomerList,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/receipt/:uuid',
        name: 'receipt-show',
        component: Receipt,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },


    {
        path: '/customer/show/:uuid',
        name: 'customer-show',
        component: CustomerShow,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    {
        path: '/partner/list',
        name: 'partner-list',
        component: PartnerList,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },
    {
        path: '/partner/show/:uuid',
        name: 'partner-show',
        component: PartnerShow,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/partner/parameters',
        name: 'partner-parameters',
        component: PartnerParameters,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/partner/form/:code',
        name: 'partner-form',
        component: PartnerForm,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },
    {
        path: '/payment/method/list',
        name: 'payment-method-list',
        component: PaymentMethodList,
        meta: {requiredRoles: ['ROLE_PRODUCTION']},
    },

    {
        path: '/parrainage/list',
        name: 'parrainage-list',
        component: ParrainageList,
        meta: {requiredRoles: ['ROLE_PRODUCTION', 'ROLE_PARTNER']},
    },

    // {
    //     path: '/call/offer',
    //     name: 'call-offer-list',
    //     component: CallOfferList,
    //     meta: {requiredRoles: ['ROLE_PRODUCTION']},
    // },
    // {
    //     path: '/call/offer/:uuid',
    //     name: 'call-offer-show',
    //     component: CallOfferShow,
    //     meta: {requiredRoles: ['ROLE_PRODUCTION']},
    // },


]
incomeRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default incomeRoutes;

<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'prospection-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-user"></i>
                  {{ trans("Details du client") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:content>
    <template v-if="isLoading">
      <Loading />
    </template>

    <template v-else>
      <b-card>
        <div class="row justify-content-start align-items-center mt-3 g-2">
          <div class="col">
            <i class="fad fa-building mb-5" style="font-size: 5rem"></i>
            <h1 class="">{{ company?.name }}</h1>
            <h3 class="uppercase">Client {{ company?.sector }}</h3>
            <!--  <h3 v-if="company?."> {{ company?.company?.name && `/ ${company?.company?.name}` }}</h3> -->
          </div>

          <div class="col">
            <div>
              <div class="d-flex flex-wrap">
                <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">
                  {{ helper.phoneFormat(company?.number) }}
                  <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                    <i class="fad fa-phone"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--
          <div v-if="customer?.manager" class="col">
            <span class="fw-bolder">Gestionnaire:</span>

            <p>{{ customer?.manager }} </p>
          </div>
-->

          <div class="col-3">
            <div class="row g-2">
              <div class="col-12">
                <button type="button" class="btn btn-sm text-white btn-warning w-100">
                  <i class="fad fa-calendar"></i> Programmer un rendez-vous
                </button>
              </div>
              <div class="col-12">
                <router-link :to="{
                  name: 'handshake-form',
                  params: {
                    clientId: 'null',
                    uuid: 'null',
                    entrepriseId: company?.id,
                  },
                }">
                  <button type="button" class="btn btn-sm btn-success w-100">
                    <i class="fad fa-phone"></i> Effectuer une prise de
                    contact
                  </button>
                </router-link>
              </div>

              <div class="col-12">
                <button @click="company && EditCustomer(company)" type="button" class="btn btn-sm btn-secondary w-100">
                  <i class="fad fa-edit"></i> Modifier
                </button>

                <b-modal @ok="saveClient" v-if="company" centered id="editProspect" title="Ajouter un prospect">
                  <h5 class="text-center">Informations supplementaires</h5>

                  <div v-for="(item, index) in company.additionalInfos" :key="index">
                    <select class="form-select form-select-sm" @change="$forceUpdate()" v-model="item.type">
                      <option>Selectionnez le type d'information</option>
                      <option v-for="(option, index) in optionsInfo" :value="option.value" :key="index">
                        {{ option.text }}
                      </option>
                    </select>

                    <b-input-group v-if="item.type" class="my-2">
                      <b-form-input v-model="item.label" placeholder="Libelé"></b-form-input>
                      <b-form-input v-if="item.type != 'FILE'" v-model="item.value"></b-form-input>
                      <div v-else class="mx-3">
                        <FileUpload v-model="item.value" />
                      </div>

                      <b-input-group-append>
                        <div @click="removeInfo(index)" style="
                              border-top-left-radius: 0 !important;
                              border-bottom-left-radius: 0 !important ;
                            " class="btn btn-sm btn-icon btn-danger rounded-end">
                          <i class="fad fa-times"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </div>

                  <div @click="addInfo"
                    class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary">
                    Ajouter une information
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <div class="row mt-5 g-2">
        <div class="col" v-if="company?.uniqProspects.length != 0">
          <div class="card text-start">
            <div class="card-body">
              <h4 class="card-title">
                Employé de l'entreprise ({{ company?.uniqProspects.length }})
              </h4>

              <div>
                <template v-for="(item, index) in company?.uniqProspects">
                  <div :key="index" class="border-bottom py-3">
                    <div :key="index" class="d-flex justify-content-between align-items-center g-2 mt-5 py-5">
                      <div class="d-flex align-items-center">
                        <h1 style="font-size: 4rem">
                          {{
                  item?.status == "HOT"
                    ? "😍"
                    : item?.status == "WARM"
                      ? "😑"
                      : item?.status == "COLD"
                        ? "🥶"
                        : item?.status == "UNVALID"
                          ? "😒"
                          : "🫥"
                }}
                        </h1>

                        <div class="ms-3">
                          <span class="fs-3 fw-bolder">
                            {{ item.name }}
                          </span>

                          <div class="d-flex flex-wrap">
                            <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">
                              {{ helper.phoneFormat(item?.mobile) }}
                              <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                                <i class="fad fa-phone"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex align-items-center">

                        <router-link class="me-2" :to="{
                          name: 'details-clients',
                          params: { id: item.id },
                        }">
                            <button class="btn btn-sm btn-icon btn-primary">
                              <i class="fad fa-eye"></i>
                            </button>
        
                          </router-link>
        
        
        
                          <router-link class="mx-2" :to="{
                          name: 'handshake-form',
                          params: { clientId: item?.id, uuid: 'null' },
                        }">
                            <button class="btn btn-sm btn-icon btn-success">
                              <i class="fad fa-phone"></i>
                            </button>
                          </router-link>


                      </div>
                    

                    </div>

                    <div class="" v-if="item?.handshakes.length > 0">
                      <h2 style="text-transform: uppercase;">Prise de contact effectué:{{item?.handshakes.length}}</h2>
                      <template v-for="(handshake, index) in item?.handshakes">

                        <div :key="index">
                          <h3>
                            {{ handshake.title }}
                          </h3>
                          <h3>
                            {{ handshake.category }}
                          </h3>

                          <span>Effectué par {{ handshake.createBy }} <br />
                            le {{ readable(handshake.createdAt) }}</span>
                        </div>
                      </template>
                    </div>


                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>


        <div v-if="company?.additionalInfos.length != 0" class="col">
          <div class="card text-start">
            <div class="card-body">
              <h4 class="card-title">Informations supplementaires</h4>

              <div>
                <template v-for="(item, index) in company?.additionalInfos">
                  <div :key="index" class="row justify-content-center align-items-end g-2 border-bottom mt-5 py-5">
                    <div>
                      <h4>{{ item.label }} :</h4>

                      <template v-if="item.type == 'FILE'">
                        <div>
                          <iframe :src="item.value" frameborder="0"></iframe>
                        </div>
                      </template>

                      <template v-if="(item.type = 'TEXT')">
                        <h3>{{ item.value }}</h3>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </template>
  </Base>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import Loading from "@/components/Loading.vue";
import FileUpload from "../../components/FileUpload.vue";
import Company from "@/entity/Company";
import Handshake from '../../entity/Handshake';

@Component({
  components: { Base, Loading, FileUpload },
})
export default class DetailsCompanyView extends Vue {
  company: Company | null = null;
  isLoading: boolean = false;

  source = api.uploadRoot;

  async load() {
    this.isLoading = true;
    const res = await api.get(
      api.form,
      "company/info/" + this.$route.params.id
    );
    if (res && res.data) {
      this.company = new Company(res.data.company);
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
    this.$forceUpdate();
  }

  ViewHandShake(id: number) {
    this.$bvModal.show("viewHandShake" + id);
    this.$forceUpdate;
  }

  statusClient = [
    {
      text: "😒",
      value: "UNVALID",
    },
    {
      text: "🥶",
      value: "COLD",
    },

    {
      text: "😑",
      value: "WARM",
    },
    {
      text: "😍",
      value: "HOT",
    },
  ];

  optionsInfo = [
    {
      value: "TEXT",
      text: "Informations",
    },
    {
      value: "FILE",
      text: "Fichiers",
    },
  ];

  // async saveClient() {
  //   const pop = new Popup(
  //     "Chargement",
  //     "Enregistrement du prospect",
  //     "primary",
  //     "fad fa-sync",
  //     true
  //   );

  //   try {
  //     const res = await api.post(api.form, "prospect/edit",
  //       this.company
  //     );

  //     if (res.status == 200) {
  //       pop.hide();
  //       this.load();
  //     }
  //   } catch (error) {
  //     pop.hide();
  //   }
  // }
  // removeInfo(item: number) {
  //   this.company?.additionnalInfo.splice(item, 1);
  //   this.$forceUpdate();
  // }

  // Editcompany(company?: UniqProspect) {
  //   this.$bvModal.show("editProspect");
  //   if (company) {
  //     this.company = company;
  //   } else {
  //     console.log("object");
  //     this.company = new UniqProspect();
  //   }
  // }

  mounted() {
    this.load();
  }
}
</script>

<style scoped></style>

/* eslint-disabled */

export default class InvoiceExtend {


  constructor (object?: any) {
  }

  postConstruct () {
  }
}

<template>
  <div class="d-flex w-100" v-if="$store.state.engineMethod">
    <div class="parameters w-300px h-100">
      <h2 class="p-3 text-center">Paramètres</h2>
      <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="p-5"
              nav-wrapper-class="bg-white rounded" style="position: sticky;top:0">
        <b-tab :title="'Objets'" :title-link-class="''">

          <div v-if="branchItemType && $store.state.engineBranch">
            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">
                <input class="hidden" type="checkbox" v-model="showBranchProperties">
                <i class="fad fa-angle-right" v-if="!showBranchProperties"></i>
                <i class="fad fa-angle-down" v-else></i>
                {{ $store.state.engineBranch.label }}
              </label>
              <div @click="createCustomProperty('Branch', branchItemType.id)"
                   class="btn btn-primary btn-icon btn-xs" v-b-modal="'parameterModal'">
                <i class="fad fa-plus"></i>
              </div>
            </div>

            <div class="d-flex flex-wrap">
              <template v-for="prop in branchItemType.properties" v-if="showBranchProperties">
                <div @dragstart="dragArgument(branchItemType, prop, $event)"
                     v-on:dblclick="editProp(branchItemType, prop)"
                     class="px-2 badge badge-primary m-1 cursor-pointer d-flex" draggable="true">
                  {{ prop.name }}
                </div>
              </template>
            </div>

          </div>

          <div v-if="insurerItemType && $store.state.engineInsurer">

            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">
                <input class="hidden" type="checkbox" v-model="showInsurerProperties">
                <i class="fad fa-angle-right" v-if="!showInsurerProperties"></i>
                <i class="fad fa-angle-down" v-else></i>
                {{ $store.state.engineInsurer.label }}
              </label>
              <div @click="createCustomProperty('Insurer', insurerItemType.id)"
                   class="btn btn-primary btn-icon btn-xs" v-b-modal="'parameterModal'">
                <i class="fad fa-plus"></i>
              </div>
            </div>

            <div class="d-flex flex-wrap">
              <template v-for="prop in insurerItemType.properties" v-if="showInsurerProperties">
                <div @dragstart="dragArgument(insurerItemType, prop, $event)"
                     v-on:dblclick="editProp(insurerItemType, prop)"
                     class="px-5 m-1 badge badge-primary cursor-pointer" draggable="true">
                  {{ prop.name }}
                </div>
              </template>
            </div>
          </div>
          <div v-if="methodItemType && $store.state.engineMethod">
            <div class="d-flex justify-content-between align-items-center">
              <label :class="{ 'text-primary': !showMethodProperties }"
                     class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">
                <input class="hidden" type="checkbox" v-model="showMethodProperties">
                <i class="fad fa-angle-right" v-if="!showMethodProperties"></i>
                <i class="fad fa-angle-down" v-else></i>
                {{ $store.state.engineMethod.label }}
              </label>
              <div @click="createCustomProperty('EngineMethod', methodItemType.id)"
                   class="btn btn-primary btn-icon btn-xs" v-b-modal="'parameterModal'">
                <i class="fad fa-plus"></i>
              </div>
            </div>
            <div class="d-flex flex-wrap" v-if="showMethodProperties">
              <template v-for="prop in methodItemType.properties" v-if="methodItemType.expand">
                <div @dragstart="dragArgument(methodItemType, prop, $event)"
                     v-on:dblclick="editProp(methodItemType, prop)"
                     class="ps-5 m-1 badge badge-primary cursor-pointer d-flex align-items-center"
                     draggable="true">
                  {{ prop.name }}
                  <!-- <div class="ms-2">
                      <i @click="editProp(methodItemType, prop)" class="fad fa-edit text-white"
                          v-b-modal="'parameterModal'"></i>
                  </div> -->
                </div>
              </template>
            </div>
          </div>
          <template v-for="type in itemTypes">
            <div v-if="type.isRoot || type.required">
              <ItemTypeInspector :type="type" @dragArgument="importArgument"></ItemTypeInspector>
              <!--                            <label :class="{ 'text-primary' : !type.expand }" class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">-->
              <!--                                <input class="hidden" type="checkbox" v-model="type.expand">-->
              <!--                                <i class="fad fa-angle-right" v-if="!type.expand"></i>-->
              <!--                                <i class="fad fa-angle-down" v-else></i>-->
              <!--                                {{type.name}}-->
              <!--                            </label>-->
              <!--                            <div class="d-flex flex-wrap">-->
              <!--                                <template v-for="prop in type.properties" v-if="type.expand">-->
              <!--                                    <div @dragstart="dragArgument(type,prop,$event)" class="px-5 m-1 badge badge-primary cursor-pointer"-->
              <!--                                         draggable="true">-->
              <!--                                        {{prop.name}}-->
              <!--                                    </div>-->
              <!--                                </template>-->
              <!--                            </div>-->

            </div>
          </template>
          <template v-for="req in $store.state.engineMethod.requirements">
            <div v-if="req.itemType">
              <ItemTypeInspector :type="req.itemType" @dragArgument="importArgument"></ItemTypeInspector>
              <!--                            <label :class="{ 'text-primary' : !req.itemType.expand }"-->
              <!--                                   class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">-->
              <!--                                <input class="hidden" type="checkbox" v-model="req.itemType.expand">-->
              <!--                                <i class="fad fa-angle-right" v-if="!req.itemType.expand"></i>-->
              <!--                                <i class="fad fa-angle-down" v-else></i>-->
              <!--                                {{req.itemType.name}}-->
              <!--                            </label>-->
              <!--                            <div class="d-flex flex-wrap">-->
              <!--                                <template v-for="prop in req.itemType.properties" v-if="req.itemType.expand">-->
              <!--                                    <div @dragstart="dragArgument(req.itemType,prop,$event)" class="px-5 m-1 badge badge-primary cursor-pointer"-->
              <!--                                         draggable="true">-->
              <!--                                        {{prop.name}}-->
              <!--                                    </div>-->
              <!--                                </template>-->
              <!--                            </div>-->

            </div>
          </template>

          <div v-if="partnerItemType">

            <div class="d-flex justify-content-between align-items-center">
              <label class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">
                <input class="hidden" type="checkbox" v-model="showPartnerProperties">
                <i class="fad fa-angle-right" v-if="!showPartnerProperties"></i>
                <i class="fad fa-angle-down" v-else></i>
                Partenaire
              </label>
            </div>

            <div class="d-flex flex-wrap">
              <template v-for="prop in partnerItemType.properties" v-if="showPartnerProperties">
                <div @dragstart="dragArgument(partnerItemType, prop, $event)"
                     v-on:dblclick="editProp(partnerItemType, prop)"
                     class="px-5 m-1 badge badge-primary cursor-pointer" draggable="true">
                  {{ prop.name }}
                </div>
              </template>
            </div>
          </div>

        </b-tab>
        <b-tab :title="'Calculs'" :title-link-class="''">
          <div>
            <input @input="filterMethod" class="form-control mb-5" placeholder="Rechercher" type="search"
                   v-model="searchedMethod">
          </div>

          <div class="d-flex justify-content-between">
            <h2>
              Globals
            </h2>
            <div>
              <div @click="createGlobalMethod" class="btn btn-xs btn-icon btn-primary"
                   v-b-modal="'methodModal'">
                <i class="fad fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mh-200px overflow-auto">
            <template v-for="m in methods">
              <div
                  :class="{ 'badge-success text-dark': m.insurer, 'badge-warning text-dark': !m.insurer && !m.branch, 'badge-primary': !m.insurer && m.branch }"
                  :title="m.insurer ? m.insurer.label : m.branch ? m.branch.label : 'Global'"
                  @dragstart="dragMethod(m, $event)" class="badge px-5 m-1 cursor-pointer text-start"
                  draggable="true" style="white-space: initial"
                  v-if="m.id !== $store.state.engineMethod.id && m.code !== $store.state.engineMethod.code && !m.insurer && !m.branch">
                {{ m.label }}
              </div>
            </template>
          </div>

          <template v-if="$store.state.engineBranch">
            <div class="d-flex justify-content-between">
              <h2 class="text-uppercase">
                {{ $store.state.engineBranch.label }}
              </h2>
              <div>
                <div @click="createBranchMethod" class="btn btn-xs btn-icon btn-primary"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus"></i>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap mh-200px overflow-auto">
              <template v-for="m in methods">
                <div
                    :class="{ 'badge-success text-dark': m.insurer, 'badge-warning text-dark': !m.insurer && !m.branch, 'badge-primary': !m.insurer && m.branch }"
                    :title="m.insurer ? m.insurer.label : m.branch ? m.branch.label : 'Global'"
                    @dragstart="dragMethod(m, $event)" class="badge px-5 m-1 cursor-pointer text-start"
                    draggable="true" style="white-space: initial"
                    v-if="m.id !== $store.state.engineMethod.id && m.code !== $store.state.engineMethod.code && !m.insurer && m.branch">
                  {{ m.label }}
                </div>
              </template>
            </div>
          </template>

          <template v-if="$store.state.engineInsurer">
            <div class="d-flex justify-content-between">
              <h2 class="text-uppercase">
                {{ $store.state.engineInsurer.label }}
              </h2>
              <div>
                <div @click="createInsurerMethod" class="btn btn-xs btn-icon btn-primary"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus"></i>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap mh-200px overflow-auto">
              <template v-for="m in methods">
                <div
                    :class="{ 'badge-success text-dark': m.insurer, 'badge-warning text-dark': !m.insurer && !m.branch, 'badge-primary': !m.insurer && m.branch }"
                    :title="m.insurer ? m.insurer.label : m.branch ? m.branch.label : 'Global'"
                    @dragstart="dragMethod(m, $event)" class="badge px-5 m-1 cursor-pointer text-start"
                    draggable="true" style="white-space: initial"
                    v-if="m.id !== $store.state.engineMethod.id && m.code !== $store.state.engineMethod.code && m.insurer">
                  {{ m.label }}
                </div>
              </template>
            </div>
          </template>
        </b-tab>
        <b-tab :title="'Resultats'" :title-link-class="''">

        </b-tab>
      </b-tabs>
    </div>
    <div class="data flex-grow-1 border-start border-end flex-column">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <router-link :title="'Retour à la liste des methodes'"
                       :to="{ name: 'engine-method-list', params: { branchSlug: $route.params.branchSlug, engineVersion: $route.params.engineVersion } }"
                       class="cursor-pointer d-flex align-items-center badge badge-light-primary bg-hover-primary text-hover-white rounded-pill me-5"
                       v-b-tooltip.hover>
            <i class="fad fa-arrow-left fs-2 me-2 "></i>
            <img :src="logoUrl" alt="" class="w-30px h-30px object-contain">
          </router-link>
          <div>
            <div>
              <h2 class="p-3 d-flex align-items-center">
                <template v-if="$store.state.engineBranch && !helper.empty($store.state.engineBranch.label)">
                  {{ $store.state.engineBranch.label }} {{ $store.state.engineBranch.category.label }} ▪
                </template>
                <template v-if="$store.state.engineInsurer && !helper.empty($store.state.engineInsurer.label)">
                  {{ $store.state.engineInsurer.label }} ▪
                </template>
                <template>
                  {{ $store.state.engineMethod.label }}
                </template>
              </h2>
            </div>

            <div class="d-flex align-items-center">
              <input type="text" v-model="method.alias" class="form-control" placeholder="Nom Public">
              <div class="btn btn-icon btn-primary ms-2 btn-sm" @click="updateAlias"><i class="fad fa-check"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center fw-bolder  px-5">
          <div class="me-5">
            Show In Details
          </div>
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input :disabled="updatingShowInDetails" @change="toggleShowInDetails" class="form-check-input"
                   type="checkbox" v-model="$store.state.engineMethod.showInDetails" value=""/>
            <!--                <label class="form-check-label" for="flexSwitchDefault">-->
            <!--                </label>-->
          </div>
          <div class="mx-5">
            Show Code
          </div>
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input class="form-check-input" type="checkbox" v-model="$store.state.showCode" value=""/>
            <!--                <label class="form-check-label" for="flexSwitchDefault">-->
            <!--                </label>-->
          </div>
          <div class="mx-5">
            Mode Pro
          </div>
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input @change="generate" class="form-check-input" type="checkbox" v-model="professionalMode"
                   value=""/>
            <!--                <label class="form-check-label" for="flexSwitchDefault">-->
            <!--                </label>-->
          </div>
        </div>


      </div>
      <div class="p-5 m-5 rounded  border-dashed">
        <div class="d-flex justify-content-between">
          <h2>
            Paramètres d'entrée
          </h2>
          <div>
            <div class="btn btn-primary btn-icon btn-xs" v-b-modal="'requirementModal'">
              <i class="fad fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex flex-wrap">
          <template v-for="req in $store.state.engineMethod.requirements">
            <div :key="'req_' + req.id" class="d-flex bg-info text-white rounded m-1">
              <div @dragstart="dragRequirementArgument(req, $event)" class="p-1 px-3" draggable="true">
                <div class="fw-bolder" @dblclick.exact="editRequirementArgument(req)">
                  {{ req.label }}
                </div>
                <!--                                <div class="fs-8">-->
                <!--                                    {{req.type.label}}-->
                <!--                                    <template v-if="req.itemType">-->
                <!--                                        ▪ {{req.itemType.name}}-->
                <!--                                    </template>-->
                <!--                                </div>-->
              </div>
              <div @click="removeRequirement(req)"
                   class="bg-danger text-white py-1 cursor-pointer px-2 rounded-end d-flex align-items-center justify-content-center">
                <i class="fad fa-trash text-white"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
      <template v-if="professionalMode">
        <b-tabs content-class="mt-3">
          <b-tab active>
            <template #title>
              JS
            </template>
            <div style="margin:20px">
              <code-editor :hide_header="true" width="100%" font_size="10px"
                           v-model="$store.state.engineMethod.body"></code-editor>
              <!-- <textarea class="form-control border-dashed min-h-500px" -->
              <!-- v-model="$store.state.engineMethod.body"></textarea> -->
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              PHP
            </template>
            <div>
              <code-editor :hide_header="true" width="100%" font_size="10px"
                           v-model="$store.state.engineMethod.phpBody"></code-editor>
              <!-- <textarea class="form-control border-dashed min-h-500px" -->
              <!-- v-model="$store.state.engineMethod.phpBody"></textarea> -->
            </div>
          </b-tab>
        </b-tabs>
        <div class="p-5">

        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-evenly bg-white py-5" style="position: sticky;top:0;z-index: 2">
          <div @click="addLine({ type: 'text' })" class="btn btn-sm btn-primary">
            Ajouter du texte
          </div>
          <div @click="addLine({ type: 'default' })" class="btn btn-sm btn-primary">
            Ajouter un calcul
          </div>
          <div @click="addLine({ type: 'condition' })" class="btn btn-sm btn-primary">
            Ajouter une condition
          </div>
          <div @click="addLine({ type: 'foreach' })" class="btn btn-sm btn-primary">
            Ajouter une iteration
          </div>
          <div class="btn btn-sm btn-danger" @click="addLine({ type: 'error' })">
            Ajouter un message d'erreur
          </div>
        </div>
        <div class="mt-10 border-top position-relative lines  min-h-500px">
          <div class="d-flex fw-bolder fs-5" v-if="$store.state.showCode">
            <div class="text-danger">
              {{ $store.state.engineMethod.code }}
            </div>
            <div> (</div>
            <template v-for="req in $store.state.engineMethod.requirements">
              <div class="text-primary">
                {{ req.code }}
              </div>
            </template>
            <div>)</div>
            <div>{</div>
          </div>
          <draggable :animation="200" :list="$store.state.engineMethod.methodLines" @end="updateLineNumber"
                     class="" filter=".action-button" ghost-class="bg-light-primary" group="master" handle=".handle"
                     tag="div">
            <template v-for="line, index in $store.state.engineMethod.methodLines">
              <EngineLineEditor :key="'m_line_' + line.uuid" :level="0" :line="line" v-on:addLine="addLine"
                                v-on:addLineBefore="addLineBefore" v-on:removeLine="removeLine"
                                v-on:updateLineNumber="updateLineNumber">
              </EngineLineEditor>
            </template>
          </draggable>
          <div class="fw-bolder" v-if="$store.state.showCode">
            }
          </div>
          <!--                    <div class="btn-primary rounded-pill btn btn-xs btn-icon position-absolute add-line-before d-none"-->
          <!--                         style="bottom:-10px;right:50px"-->
          <!--                         title="Ajouter une ligne ici" v-b-tooltip.hover>-->
          <!--                        <i class="fad fa-plus"></i>-->
          <!--                    </div>-->
        </div>
        <div class="d-flex justify-content-evenly mt-10">
          <div @click="addLine({ type: 'text' })" class="btn btn-sm btn-primary">
            Ajouter du texte
          </div>
          <div @click="addLine({ type: 'default' })" class="btn btn-sm btn-primary">
            Ajouter un calcul
          </div>
          <div @click="addLine({ type: 'condition' })" class="btn btn-sm btn-primary">
            Ajouter une condition
          </div>
          <!--                    <div @click="addLine({type : 'callable'})" class="btn btn-sm btn-primary">-->
          <!--                        Ajouter une fonction-->
          <!--                    </div>-->
          <div @click="addLine({ type: 'foreach' })" class="btn btn-sm btn-primary">
            Ajouter une iteration
          </div>
          <div class="btn btn-sm btn-danger" @click="addLine({ type: 'error' })">
            Ajouter un message d'erreur
          </div>
        </div>
      </template>
      <div class="d-flex align-self-end justify-content-end p-5">
        <div @click="generateJS" class="btn btn-warning me-5">
          <i class="fad fa-cogs"></i>
          Generate JS
        </div>
        <div @click="generatePHP" class="btn btn-warning me-5">
          <i class="fad fa-cogs"></i>
          Generate PHP
        </div>
        <div @click="save" class="btn btn-danger">
          <i class="fad fa-save"></i>
          Enregistrer
        </div>
      </div>
    </div>
    <!--        <div class="test  min-w-300px">-->
    <!--            <h2 class="p-3 text-center">Test</h2>-->
    <!--        </div>-->


    <b-modal :title="trans('Entrée')" centered id="requirementModal" ref="requirementModal">
      <form action="">
        <div class="row">
          <div class="col-auto m-2 flex-grow-1">
            <label class="form-label">{{ trans('Nom') }}</label>
            <div>
              <input class="form-control" type="text" v-model="requirement.label">
            </div>
          </div>
          <div class="col-auto m-2 flex-grow-1">
            <label class="form-label">{{ trans('Type') }}</label>
            <div>
              <b-form-select :state="!helper.empty(requirement.type)"
                             class="form-control form-select-solid form-select" v-model="requirement.type">
                <b-form-select-option :value="null">{{ trans('Select_a_category') }}
                </b-form-select-option>
                <b-form-select-option :key="type.id" :value="type" v-for="type, key in dataTypes">
                  {{ trans(type.label) }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </div>
          <div class="col-auto m-2 flex-grow-1"
               v-if="requirement.type && requirement.type.code.match(/OBJECT|OBJECT_GROUP/)">
            <label class="form-label">{{ trans('Objet') }}</label>
            <div>
              <b-form-select :state="!helper.empty(requirement.itemType)"
                             class="form-control form-select-solid form-select" v-model="requirement.itemType">
                <b-form-select-option :value="null">{{ trans('Select_a_category') }}
                </b-form-select-option>
                <b-form-select-option :key="itemType.uuid" :value="itemType.uuid"
                                      v-for="itemType, key in itemTypes">
                  {{ trans(itemType.name) }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </div>
<!--          <div class="col-auto m-2 flex-grow-1" v-else>-->
<!--            <label class="form-label">{{ trans('Valeur') }}</label>-->
<!--            <div v-if="requirement.type">-->
<!--              <template v-if="requirement.type.format === 'TEXT'">-->
<!--                <input class="form-control" type="text" v-model="requirement.value">-->
<!--              </template>-->
<!--              <template v-if="requirement.type.format === 'NUMBER'">-->
<!--                <input class="form-control" type="number" v-model="requirement.value">-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </form>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="saveRequirement()" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Save') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="trans('Paramètre')" centered id="parameterModal" ref="parameterModal">
      <form action="">
        <div class="row">
          <div class="col-auto m-2 flex-grow-1">
            <label class="form-label">{{ trans('Nom') }}</label>
            <div>
              <input class="form-control" type="text" v-model="itemProperty.name">
            </div>
          </div>
          <div class="col-auto m-2 flex-grow-1">
            <label class="form-label">{{ trans('Type') }}</label>
            <div>
              <b-form-select :state="!helper.empty(itemProperty.type)"
                             class="form-control form-select-solid form-select" v-model="itemProperty.type">
                <b-form-select-option :value="null">{{ trans('Selectionnez un type') }}
                </b-form-select-option>
                <template v-for="type, key in dataTypes">
                  <b-form-select-option :key="type.id" :value="type"
                                        v-if="!type.code.match(/OBJECT|OBJECT_GROUP|ARRAY|CLASS/)">
                    {{ trans(type.label) }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div>
            <div class="col-auto m-2 flex-grow-1" v-if="itemProperty.type">
              <label class="form-label">{{ trans('Valeur') }}</label>
              <div>
                <template v-if="itemProperty.type.format === 'TEXT'">
                  <input class="form-control" type="text" v-model="itemProperty.value">
                </template>
                <template v-if="itemProperty.type.format === 'NUMBER'">
                  <input class="form-control" type="number" v-model="itemProperty.value">
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="deleteCustomProperty()" size="sm" variant="danger">
          <i class="fad fa-times"></i>
          {{ trans('Supprimer') }}
        </b-button>
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Annuler') }}
        </b-button>
        <b-button @click="editCustomProperty()" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Enregistrer') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="trans('Calcul')" centered id="methodModal" ref="methodModal">
      <form>
        <div class="row">
          <div class="col-auto m-2 flex-grow-1">
            <label class="required form-label">{{ trans('Nom') }}</label>
            <div>
              <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text"
                            v-model="method.label">
              </b-form-input>
            </div>
          </div>
          <div class="col-auto m-2 flex-grow-1">
            <label class="form-label">{{ trans('Description') }}</label>
            <div>
              <textarea class="form-control" v-model="method.description"></textarea>
            </div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="editMethod()" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Save') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Branch from "@/entity/Branch";
import Engine from "@/entity/Engine";
import EngineMethod from "@/entity/EngineMethod";
import Insurer from '@/entity/Insurer';
import EngineMethodLine from "@/entity/EngineMethodLine";
import EngineLineEditor from "@/views/Engine/EngineLineEditor.vue";
import EngineArgument from "@/entity/EngineArgument";
import DataType from "@/entity/DataType";
import {helper} from "@/services/Helper";
import EngineMethodRequirement from "@/entity/EngineMethodRequirement";
import ItemType from "@/entity/ItemType";
import Property from "@/entity/Property";
import ItemProperty from "@/entity/ItemProperty";
import Popup from "@/entity/Popup";
import Item from "@/entity/Item";
import CodeEditor from 'simple-code-editor';

@Component({
  components: {EngineLineEditor, Base, CodeEditor}
})
export default class EngineMethodEditor extends Vue {
  // method = new EngineMethod()
  // methods: EngineMethod[] = []
  requirement = new EngineMethodRequirement()
  itemTypes: ItemType[] = []
  // branch = new Branch()
  engine: Engine | null = null
  logoUrl = api.logoUrl
  // insurer: Insurer | null = null
  dataTypes: DataType[] = []
  professionalMode = false
  lineCondition: EngineMethodLine | null = null
  itemProperty = new ItemProperty()
  branchItemType: ItemType | null = null
  partnerItemType: ItemType | null = null
  insurerItemType: ItemType | null = null
  methodItemType: ItemType | null = null
  showCode = true
  showBranchProperties = false
  showInsurerProperties = false
  showMethodProperties = false
  showPartnerProperties = false
  sources: ItemType[] = []
  methods: EngineMethod[] = []
  searchedMethod = ''
  method = new EngineMethod()

  async updateAlias() {
    console.log(this.$store.state.engineMethod.id)
    if (!helper.empty(this.method.alias) && !helper.empty(this.$store.state.engineMethod.id)) {
      this.$store.commit('loading')
      const res = await api.post(api.core, 'engine/method/update/alias/' + this.$store.state.engineMethod.id, {name: this.method.alias})
      this.$store.commit('stopLoading')
      if (res && res.data && res.data.alias) {
        this.method.alias = res.data.alias
      }
    }
  }

  filterMethod() {
    if (!helper.empty(this.searchedMethod)) {
      this.methods = []
      const matchs = this.$store.state.engineMethods.filter((e: EngineMethod) => {
        return helper.removeAccent(e.label.toLowerCase()).includes(helper.removeAccent(this.searchedMethod).toLowerCase())
      })
      let method: any = null
      if (this.$store.state.engineMethod.insurer) {
        method = matchs.find((e: EngineMethod) => {
          return e.insurer && e.insurer.uuid === this.$store.state.engineMethod.insurer.uuid
        })

      }
      if (!method && this.$store.state.engineMethod.engine) {
        method = matchs.find((e: EngineMethod) => {
          return e.engine && e.engine.id === this.$store.state.engineMethod.engine.id
        })
      }
      if (!method) {
        matchs.forEach((e: EngineMethod) => {
          this.methods.push(e)
        })
      }

      if (method) {
        this.methods.push(method)
      }
    } else {
      this.methods = this.$store.state.engineMethods
    }
  }

  insurerHasMethod(method: EngineMethod) {

  }

  createBranchMethod() {

    this.method.engine = this.engine as any
    this.method.insurer = undefined
  }

  createGlobalMethod() {
    this.method.engine = undefined as any
    this.method.insurer = undefined
  }

  createInsurerMethod() {
    this.method.engine = this.engine as any
    this.method.insurer = this.$store.state.engineMethod.insurer
  }

  async editMethod() {
    this.$store.commit('loading')
    const method = {id: this.method.id, label: this.method.label, description: this.method.description}
    let url = 'engine/method/edit';
    if (this.method.engine) {
      url = 'engine/method/edit' + (this.method.engine ? '/' + this.method.engine.id : '') +
          (this.method.engine && this.method.insurer ? '/' + this.method.insurer.slug : '');
    }
    const res = await api.post(api.core, url, {method})
    if (this.$refs.methodModal) {
      (this.$refs.methodModal as any).hide()
    }
    this.$store.commit('stopLoading')
    this.softReload()
    this.method = new EngineMethod()
  }

  async beforeMount() {
    await this.loadMethod()
    this.$store.state.lineCounter = 1
  }

  mounted() {

  }

  updateSources() {
    const merge: ItemType[] = [];

    if (this.branchItemType) {
      merge.push(this.branchItemType)
    }
    if (this.insurerItemType) {
      merge.push(this.insurerItemType)
    }
    if (this.methodItemType) {
      // console.log(this.methodItemType)
      merge.push(this.methodItemType)
    }
    if (this.partnerItemType) {
      merge.push(this.partnerItemType)
    }
    // merge.concat(this.itemTypes)
    // console.log(this.itemTypes)
    this.itemTypes.forEach((i: ItemType) => {
      if (i.isRoot || i.required) {
        merge.push(i)
      }
    })
    this.$store.state.engineMethod.requirements.forEach((r: EngineMethodRequirement) => {
      if (r.itemType instanceof ItemType) {
        merge.push(r.itemType)
      }
    })
    // console.log(merge)
    this.$store.commit('setSources', merge);
    // this.$store.state.sources = merge
    return merge;
  }

  setLineCondition(line: EngineMethodLine) {
    this.lineCondition = line
  }

  ranking = 1
  counter = 1

  dragRequirementArgument(req: EngineMethodRequirement, evt: any) {

    const arg = {
      id: req.uuid,
      uuid: req.uuid,
      itemType: null,
      type: req.type,
      choices: [],
      code: req.code,
      name: req.label,
      value: null,
      alias: null as any,
      symbol: null as any
    }
    // console.log(arg)
    evt.dataTransfer.setData('text/plain', JSON.stringify(arg));
  }

  editRequirementArgument(req: EngineMethodRequirement) {
    this.requirement = req;
    (this.$refs as any).requirementModal.show()
  }

  dragMethod(method: EngineMethod, evt: any) {
    const type = this.dataTypes.find((d: DataType) => {
      return d.code === 'CALLABLE'
    })
    const id = helper.generateId()
    const arg = {
      id: method.id,
      uuid: id,
      itemType: null,
      type: type,
      choices: [],
      code: method.code,
      name: method.label,
      value: method.id,
      alias: method.label,
      symbol: null as any
    }
    // console.log(new EngineArgument(arg))
    evt.dataTransfer.setData('text/plain', JSON.stringify(arg));
  }


  importArgument(payload: any) {
    this.dragArgument(payload.type, payload.prop, payload.evt, payload.source)
  }

  dragArgument(itemType: ItemType, itemProperty: ItemProperty, evt: any, source = null) {

    let type = JSON.parse(JSON.stringify(itemType))
    const arg = {
      id: itemProperty.id,
      uuid: itemProperty.uuid,
      itemType: {
        id: itemType.id,
        uuid: itemType.uuid,
        code: itemType.code,
        entity: itemType.entity,
        className: itemType.className,
        name: itemType.name
      },
      type: itemProperty.type,
      choices: itemProperty.choices,
      code: itemProperty.code,
      name: itemProperty.name,
      value: itemProperty.value,
      source: source,
      alias: null as any,
      symbol: null as any
    }
    // console.log(arg)
    evt.dataTransfer.setData('text/plain', JSON.stringify(arg));
  }

  updateLineNumber() {
    this.counter = 1
    this.ranking = 1
    this.$store.state.engineMethod.methodLines.forEach((l: EngineMethodLine) => {
      l.ranking = this.ranking
      if (l.type !== 'condition') {
        l.number = this.counter
        if (l.returnArgument) {
          if (helper.empty(l.returnArgument.name)) {
            // console.log('rewrite '+l.returnArgument.name)
            l.returnArgument.name = 'Resultat ' + l.number
          }
        }
        this.counter++
      }
      this.ranking++
      this.updateSubLineNumber(l)
    })
  }

  updateSubLineNumber(line: EngineMethodLine) {
    line.subLines.forEach((l: EngineMethodLine) => {
      l.ranking = this.ranking
      if (l.type !== 'condition') {
        l.number = this.counter
        if (l.returnArgument && helper.empty(l.returnArgument.name)) {
          // console.log('setting line return name  from ' + l.returnArgument.name + ' to ' + 'Resultat ' + l.number)
          l.returnArgument.name = 'Resultat ' + l.number
        }
        this.counter++
      }
      this.ranking++;
      this.updateSubLineNumber(l)
    })

  }

  createArgument(payload: any) {

  }

  async loadMethod() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'engine/method/get/data/' + this.$route.params.methodId)
    if (res && res.data) {
      let branch = null
      let insurer = null
      let method: any = null
      let methods: EngineMethod[] = []
      if (res.data.branch) {
        branch = new Branch(res.data.branch)
      }
      if (res.data.insurer) {
        insurer = new Insurer(res.data.insurer)
      }
      this.engine = new Engine(res.data.engine)
      method = new EngineMethod(res.data.method)

      const insurerMethods: EngineMethod[] = []
      res.data.methods.forEach((m: EngineMethod) => {
        // console.log('new method '+m.label)
        const occ = new EngineMethod(m)

        if (method.insurer) {
          if (!m.insurer || m.insurer.uuid === method.insurer.uuid) {
            methods.push(occ)
          }
        } else {
          methods.push(occ)
        }
      })
      res.data.globalMethods.forEach((m: EngineMethod) => {
        const match = methods.find((em: EngineMethod) => {
          return em.code === m.code
        })
        if (!match) {
          const occ = new EngineMethod(m)
          methods.push(occ)
        }
      })

      this.itemTypes = []
      res.data.itemTypes.forEach((m: ItemType) => {
        const occ = new ItemType(m)
        occ.expand = false
        this.itemTypes.push(occ)
      })
      this.dataTypes = []
      res.data.dataTypes.forEach((d: DataType) => {
        const occ = new DataType(d)
        this.dataTypes.push(occ)
      })

      this.branchItemType = res.data.branchItemType ? new ItemType(res.data.branchItemType) : null
      this.partnerItemType = res.data.partnerItemType ? new ItemType(res.data.partnerItemType) : null
      this.methodItemType = new ItemType(res.data.methodItemType)
      this.insurerItemType = res.data.insurerItemType ? new ItemType(res.data.insurerItemType) : null
      this.$store.state.dataTypes = this.dataTypes
      this.$store.state.itemTypes = this.itemTypes
      this.$store.state.engine = this.engine
      this.$store.state.engineMethod = method
      this.$store.state.engineMethods = methods
      this.$store.state.engineBranch = branch
      this.$store.state.engineInsurer = insurer
      this.filterMethod()
      if (method.methodLines.length === 0 && !helper.empty(method.body)) {
        this.professionalMode = true
      }
    }
    this.$store.commit('stopLoading')
    this.updateLineNumber()
    this.updateSources()
  }

  async softReload() {
    const pop = new Popup('Actualisation', '', 'warning', 'fad fa-sync', true)
    const res = await api.get(api.core, 'engine/method/get/data/' + this.$route.params.methodId)
    if (res && res.data) {
      const methods: EngineMethod[] = []
      const insurerMethods: EngineMethod[] = []
      res.data.methods.forEach((m: EngineMethod) => {
        const occ = new EngineMethod(m)

        if (this.$store.state.engineMethod.insurer) {
          if (!m.insurer || m.insurer.uuid === this.$store.state.engineMethod.insurer.uuid) {
            methods.push(occ)
          }
        }
      })
      res.data.globalMethods.forEach((m: EngineMethod) => {
        const match = methods.find((em: EngineMethod) => {
          return em.code === m.code
        })
        if (!match) {
          const occ = new EngineMethod(m)
          methods.push(occ)
        }
      })
      this.itemTypes = []
      res.data.itemTypes.forEach((m: ItemType) => {
        const occ = new ItemType(m)
        occ.expand = false
        this.itemTypes.push(occ)
      })
      this.$store.state.engineMethod.requirements = []
      res.data.method.requirements.forEach((r: EngineMethodRequirement) => {
        const occ = new EngineMethodRequirement(r)
        this.$store.state.engineMethod.requirements.push(occ)
      })
      this.branchItemType = res.data.branchItemType ? new ItemType(res.data.branchItemType) : null
      this.partnerItemType = res.data.partnerItemType ? new ItemType(res.data.partnerItemType) : null
      this.methodItemType = new ItemType(res.data.methodItemType)
      this.insurerItemType = res.data.insurerItemType ? new ItemType(res.data.insurerItemType) : null
      this.$store.state.itemTypes = this.itemTypes
      this.$store.state.engineMethods = methods
      this.filterMethod()
      this.updateSources()

    }
    pop.hide()
  }

  lineTypes = ['default', 'condition', 'callable', 'foreach']

  addLineBefore(payload: any) {
    // console.log(payload)
    this.addLine(payload)
  }


  updatingShowInDetails = false

  async toggleShowInDetails() {
    if (!this.updatingShowInDetails) {
      this.updatingShowInDetails = true
      const pop = new Popup('', 'Actualisation', 'warning', 'fad fa-cogs', true)
      const res = await api.get(api.core, 'engine/method/toggle/show/in/details/' + this.$store.state.engineMethod.id)
      if (res && res.data && res.data.state) {
        this.$store.state.engineMethod.showInDetails = res.data.state
      }
      pop.hide()
      this.updatingShowInDetails = false
    }
  }

  addLine(payload: any) {
    const type = payload.type
    const parent = payload.parent
    const before = payload.before
    let line: any = null
    switch (type) {
      case 'condition': {
        line = this.createConditionLine()
        break
      }
      case 'foreach': {
        line = this.createForeachLine()
        break
      }
      case 'callable': {
        line = this.createCallableLine()
        break
      }
      case 'text': {
        line = this.createTextLine()
        break
      }
      case 'error': {
        line = this.createErrorLine()
        break
      }
      default: {
        line = this.createDefaultLine()
        break
      }
    }
    if (line instanceof EngineMethodLine) {
      if (parent instanceof EngineMethodLine) {
        if (before instanceof EngineMethodLine) {
          const index = parent.subLines.findIndex((s: EngineMethodLine) => {
            return s === before
          })
          if (index !== -1) {
            parent.subLines.splice(index, 0, line)
          }
        } else {
          parent.subLines.push(line)
          line.parent = parent.id
        }
      } else {
        if (before instanceof EngineMethodLine) {
          const index = this.$store.state.engineMethod.methodLines.findIndex((s: EngineMethodLine) => {
            return s === before
          })
          if (index !== -1) {
            this.$store.state.engineMethod.methodLines.splice(index, 0, line)
          }
        } else {
          this.$store.state.engineMethod.methodLines.push(line)
        }
      }
      // console.log(this.$store.state.engineMethod.methodLines[0].subLines)
    }
    this.updateLineNumber()
  }

  async removeLine(payload: any) {
    const line = payload.line
    const parent: EngineMethodLine = payload.parent

    if (line.id) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'engine/method/remove/line/' + line.id)
      if (!(res && res.data)) {
        return
      }
      this.$store.commit('stopLoading')
    }
    if (parent) {
      const index = parent.subLines.findIndex((sb: EngineMethodLine) => {
        return sb === line
      })
      if (index !== -1) {
        parent.subLines.splice(index, 1)
      }
    } else {
      const index = this.$store.state.engineMethod.methodLines.findIndex((sb: EngineMethodLine) => {
        return sb === line
      })
      if (index !== -1) {
        this.$store.state.engineMethod.methodLines.splice(index, 1)
      }
    }
    if (line.returnArgument) {
      this.removeArgument(line.returnArgument)
    }
    this.updateLineNumber()
  }

  removeArgument(argument: EngineArgument) {
    this.$store.state.engineMethod.methodLines.forEach((l: EngineMethodLine) => {
      l.removeArgument(argument)
    })
  }

  createDefaultLine(): EngineMethodLine {
    const line = new EngineMethodLine()
    line.type = 'default'

    const type: DataType = this.dataTypes.find((d: DataType) => {
      return d.code === 'NUMBER'
    }) as any
    // console.log(type)
    if (!(type instanceof DataType)) {
      throw 'Impossible de récupérer le type de donnée correspondant à la ligne de calcul'
    }
    const arg = new EngineArgument()
    arg.type = type
    arg.resultOfLine = line.uuid
    this.$store.state.returnArguments.push(arg)
    line.returnArgument = arg
    return line
  }

  createTextLine(): EngineMethodLine {

    const line = new EngineMethodLine()
    line.type = 'text'

    const type: DataType = this.dataTypes.find((d: DataType) => {
      return d.code === 'TEXT'
    }) as any
    // console.log(type)
    if (!(type instanceof DataType)) {
      throw 'Impossible de récupérer le type de donnée correspondant à la ligne de calcul'
    }
    const arg = new EngineArgument()
    arg.type = type
    arg.resultOfLine = line.uuid
    this.$store.state.returnArguments.push(arg)
    line.returnArgument = arg
    return line
  }


  createErrorLine(): EngineMethodLine {
    const line = new EngineMethodLine()
    line.type = 'error'

    const type: DataType = this.dataTypes.find((d: DataType) => {
      return d.code === 'TEXT'
    }) as any
    // console.log(type)
    if (!(type instanceof DataType)) {
      throw 'Impossible de récupérer le type de donnée correspondant à la ligne de calcul'
    }
    const arg = new EngineArgument()
    arg.type = type
    arg.resultOfLine = line.uuid
    this.$store.state.returnArguments.push(arg)
    line.returnArgument = arg
    return line
  }


  createForeachLine() {
    const line = new EngineMethodLine()
    line.type = 'foreach'
    const type: DataType = this.dataTypes.find((d: DataType) => {
      return d.code === 'NUMBER_ARRAY'
    }) as any
    if (!(type instanceof DataType)) {
      throw 'Impossible de récupérer le type de donnée correspondant à la ligne de calcul'
    }
    const arg = new EngineArgument()
    arg.type = type
    arg.resultOfLine = line.uuid
    this.$store.state.returnArguments.push(arg)
    line.returnArgument = arg
    return line
  }

  createCallableLine() {
    const line = this.createDefaultLine()
    line.type = 'callable'
    return line
  }

  createConditionLine(): EngineMethodLine {
    const line = new EngineMethodLine()
    line.type = 'condition'
    const subLine = this.createDefaultLine()
    line.subLines.push(subLine)
    return line
  }

  async saveRequirement() {
    const requirement: any = this.requirement;
    const res = await api.post(api.core, 'engine/method/requirement/edit/' + this.$store.state.engineMethod.id, {requirement})
    if (res && res.data && res.data.requirements) {
      const reqs: any = []
      res.data.requirements.forEach((r: EngineMethodRequirement) => {
        const occ = new EngineMethodRequirement(r)
        reqs.push(occ)
      })
      this.$store.state.engineMethod.requirements = reqs
      this.$store.state.engineMethod.requirements = reqs
      // console.log(res.data.requirements)
    }
    if (this.$refs.requirementModal) {
      (this.$refs.requirementModal as any).hide()
    }
    this.updateSources()
    // this.loadInParameters()
  }

  async removeRequirement(req: EngineMethodRequirement) {

    // console.log(this.$store.state.engineMethod.requirements)
    // this.$store.state.engineMethod.requirements = this.$store.state.engineMethod.requirements.filter((r: EngineMethodRequirement) => {
    //     return r.id !== req.id
    // })
    // console.log(this.$store.state.engineMethod.requirements)
    const res = await api.get(api.core, 'engine/method/requirement/delete/' + req.id)
    this.updateSources()
    this.softReload()
    this.$forceUpdate()

    // console.log(res)
  }

  async createCustomProperty(name: string, id: number) {
    this.itemProperty = new ItemProperty()
    this.itemProperty.item = {name, id} as any
  }

  editProp(item: ItemType, prop: ItemProperty) {
    console.log('edit')
    this.itemProperty = prop
    this.itemProperty.item = {id: item.id} as any
    (this.$refs.parameterModal as any).show()
  }


  async editCustomProperty() {
    const res = await api.post(api.core, 'item/property/edit', {property: this.itemProperty})
    if (this.$refs.parameterModal) {
      (this.$refs.parameterModal as any).hide()
    }
    this.softReload()
  }

  async deleteCustomProperty() {
    const res = await api.get(api.core, 'item/property/delete/' + this.itemProperty.uuid)
    if (this.$refs.parameterModal) {
      (this.$refs.parameterModal as any).hide()
    }
    this.softReload()
  }

  isArgumentAllowed() {

  }

  invalidLines: EngineMethodLine[] = []

  check() {
    this.invalidLines = []
    this.$store.state.engineMethod.methodLines.forEach((l: EngineMethodLine) => {
      this.checkLine(l)
    })
  }

  checkLine(line: EngineMethodLine) {
    if (!line.isValid) {
      this.invalidLines.push(line)
    }
    line.subLines.forEach((sb: EngineMethodLine) => {
      this.checkLine(sb)
    })
  }

  generate() {
    this.$store.state.engineMethod.generateCode()
    // this.professionalMode = true
  }

  async generateJS() {
    const res = await api.get(api.core, 'engine/generate/js/method/' + this.$store.state.engineMethod.id)
    // this.professionalMode = true
  }

  async generatePHP() {
    const res = await api.get(api.core, 'engine/generate/php/method/' + this.$store.state.engineMethod.id)
    // this.$store.state.engineMethod.generateCode()
    // this.professionalMode = true
  }

  async save() {


    const m: EngineMethod = this.$store.state.engineMethod

    m.generateCode()
    // return
    const method = JSON.parse(JSON.stringify(m))

    // console.log(method)
    this.check()
    if (this.invalidLines.length > 0) {
      const ids = this.invalidLines.map((l: EngineMethodLine) => l.ranking)
      const pop = new Popup('Erreur', 'Les lignes ' + ids.join(',') + ' contienne des erreurs', 'danger', 'fad fa-bug')
    } else {
      this.$store.commit('loading')
      const res = await api.post(api.core, 'engine/method/editor', {method})
      // console.log(res)
      this.$store.commit('stopLoading')
    }
  }
}
</script>
<style scoped>
.lines:hover .add-line-before {
  display: block !important;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .2s;
  /*max-height: 100px;*/
}

.slide-left-leave-from,
.slide-left-leave-from {
  transition: all .2s;
}

.slide-left-enter {
  /*opacity: 0;*/
  /*transform: translateY(50%);*/
  max-height: 0;
  overflow: hidden;
}
</style>

<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div
          class="container-fluid d-flex flex-stack"
          id="kt_toolbar_container"
        >
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link
                    :to="{ name: 'prospection-index' }"
                    class="text-muted text-hover-primary"
                  >
                    <i class="fad fa-headset"></i>
                    {{ trans("Prise de contact") }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

          <div class="d-flex align-items-center py-1">
            <router-link
              :to="{ name: 'handshake-form' }"
              class="btn btn-sm btn-primary"
            >
              <i class="fad fa-headset"></i>
              {{ trans("Effectuer une prise de contact") }}
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <div class="card">

        <div class="w-100">
          <Pagination :count="total" :itemLength="handShakes.length" :matchCount="total" v-model="params.page"
            showEmpty="true" class="p-10" :perPage="params.limit" :key="'pagination_' + params.page" @input="load()">
          </Pagination>
        </div>
        <div class="card-body py-3">
          <div class="table-responsive">
            <table
              class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
            >
              <thead>
                <tr class="fw-bolder text-muted">
                  <th class="w-25px">
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                        class="form-check-input"
                        data-kt-check="true"
                        data-kt-check-target=".widget-9-check"
                        type="checkbox"
                        value="1"
                      />
                    </div>
                  </th>
                  <th class="min-w-150px">{{ trans("Prise de contact") }}</th>
                  <th class="min-w-140px">{{ trans("Clients") }}</th>
                  <!--              <th v-if="!compact">-->
                  <!--                Prochaine activité-->
                  <!--              </th>-->
                  <th class="min-w-120px">{{ trans("Statut") }}</th>
                  <th class="min-w-100px text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-if="rgs.length === 0">
                <td colspan="9">
                  <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                border-bottom-dashed border-gray-300">
                    <i class="fad fa-empty-set fs-4x"></i>
                    <span class="mt-5 fs-2x text-gray-400">
                      {{ trans('Aucun resultat') }}
                    </span>
                  </div>
                </td>
              </tr>

              -->
                <tr
                  v-for="(handshake, index) in handShakes"
                  :key="'cotation_' + handshake.id"
                >
                  <td class="">
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                        class="form-check-input widget-9-check"
                        type="checkbox"
                        value="1"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span>
                        {{
                          handshake.type == "a_distance"
                            ? "A distance"
                            : handshake.type == "rendezvous"
                            ? "Rendez-vous"
                            : null
                        }}
                      </span>
                      <span style="text-transform: uppercase" class="fw-bolder">
                        {{ handshake.title }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span style="text-transform: uppercase" class="fw-bolder">
                        {{ handshake.category }}
                      </span>

                      <span>
                        {{ handshake.uniqProspect.name }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <span class="btn btn-success btn-sm"
                      >Effectué par {{ handshake.createBy }} <br />
                      le {{ helper.readable(handshake.createdAt) }}</span
                    >
                  </td>

                  <!--              <td v-if="!compact">-->
                  <!--              </td>-->
                  <td class="text-gray-400 fw-bolder text-end">
                    <div>
                      <button
                        @click="ViewHandShake(handshake.id)"
                        class="btn btn-icon btn-primary btn-sm"
                      >
                        <i class="fad fa-eye"></i>
                      </button>

                      <b-modal :id="'viewHandShake' + handshake.id" title="Details de la prospection" size="xl">
                        <h2 style="text-transform: uppercase">
                          {{ handshake.title }}
                        </h2>

                        <div class="row g-2">
                          <div class="col">
                            <div
                              class="row justify-content-center align-items-center g-2 mt-5"
                            >
                              <div class="col">
                                <p class="fw-bolder">
                                  Propection
                                  {{
                                    handshake.type == "a_distance"
                                      ? "à distance"
                                      : handshake.type == "rendezvous"
                                      ? "par Rendez-vous"
                                      : null
                                  }}
                                </p>

                                <div>
                                  {{ handshake.createBy }} <br />
                                  le {{ helper.readable(handshake.createdAt) }}
                                </div>
                              </div>
                              <div class="col">
                                <p>Type de client</p>
                                <h5>
                                  {{ handshake.category }}
                                </h5>
                              </div>
                            </div>

                            <div class="mt-5">
                              <h3>Notes</h3>

                              <div
                                v-for="(item, index) in handshake.notes"
                                class="border-bottom border-top py-5"
                                :key="index"
                              >
                                <p>
                                  {{ item.createBy }} <br />
                                  le {{ helper.readable(item.createdAt) }}
                                </p>
                                <iframe
                                  :srcdoc="item.comment"
                                  width="100%"
                                  height="100%"
                                ></iframe>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-4 d-flex flex-column align-items-center"
                          >
                            <h1 style="font-size: 6rem">
                             

                              {{
                                handshake.uniqProspect?.status == "HOT"
                                  ? "😍"
                                  : handshake.uniqProspect?.status == "WARM"
                                    ? "😑"
                                    : handshake.uniqProspect?.status == "COLD"
                                      ? "🥶"
                                      : handshake.uniqProspect?.status == "UNVALID"
                                        ? "😒"
                                        : "🫥"
                              }}
                            </h1>
                            <h1 class="text-center">
                              {{ handshake.uniqProspect.name }}
                            </h1>

                            <div>
                            

                              <div class="d-flex flex-wrap">
                                <div
                                  class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                                >
                                  {{
                                    helper.phoneFormat(handshake.uniqProspect?.mobile)
                                  }}
                                  <div
                                    class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0"
                                  >
                                    <i class="fad fa-phone"></i>
                                  </div>


                                
                                </div>
                              </div>

                              <router-link class="me-2" :to="{
                                name: 'details-clients',
                                params: { id: handshake.uniqProspect.id },
                              }">
                                  <button class="btn  btn-primary mt-5">
                                   Voir plus 
                                  </button>
              
                                </router-link>
                            </div>
                          </div>
                        </div>
                      </b-modal>

                      <router-link
                        class="mx-2"
                        :to="{
                          name: 'handshake-form',
                          params: { uuid: handshake.id, idClient: 'null' },
                        }"
                      >
                        <button class="btn btn-icon btn-secondary btn-sm mx-2">
                          <i class="fad fa-edit"></i>
                        </button>
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import Base from "@/layouts/Base.vue";
import SellHandShake from "@/entity/SellHandShake";
import { api } from "@/services/Api";
import Popup from "@/entity/Popup";
import { helper } from "@/services/Helper";
import Handshake from '../../entity/Handshake';
import Pagination from "@/components/Pagination.vue";
@Component({
  components: { Base , Pagination },
})
export default class HandShakeList extends Vue {
  handShakes: Handshake[] = [];
  params: any = {
    limit: 20,
    page: 1,
  };
  total: number = 0;
  async load() {
    const pop = new Popup(
      "Chargement",
      "Liste des prise de contact",
      "primary",
      "fad fa-sync",
      true
    );


    

    const res = await api.get(api.form, `handshake/list?limit=${this.params.limit}&page=${this.params.page}`);

    if (res && res.data) {
      this.handShakes = [];
      this.total = res.data.total;
      res.data.handshakes.forEach((h: any) => {
        const occ = new Handshake(h);

        this.handShakes.push(occ);
      });

      pop.hide();
    }
  }

  ViewHandShake(id: number) {
    this.$bvModal.show("viewHandShake" + id);

    this.$forceUpdate;
  }
  mounted() {
    this.load();
  }
}
</script>
<style lang=""></style>

<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                  <i class="fad fa-home"></i>
                  {{ trans("Dashboard") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>

        <div class="d-flex align-items-center py-1">
          <router-link :to="{ name: 'avenant-type-form' }" class="btn btn-sm btn-primary">
            <i class="fad fa-plus"></i>
            {{ trans("Creer un type d'avenant") }}
          </router-link>
        </div>
      </div>
    </div>
  </template>
  <template v-slot:content>
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">Type d'avenant</h1>

        <table class="table table-striped gy-7 gs-7">
          <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans("Type") }}</th>
              <th>{{ trans("Code") }}</th>
              <th>{{ trans("Descripition") }}</th>
              <!-- <th>{{ trans("Fonctionallites") }}</th> -->
              <th>{{ trans("Actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(type, index) in typesAvenant" :key="index">
              <td>{{ type.label }} </td>
              <td>{{ type.code }}</td>

              <td>{{ type.description }}</td>

              <td>
                <div class="d-flex">
                  <button class="btn-icon btn-primary btn btn-sm" v-b-modal="'detailsType' + type.id">
                    <i class="fad fa-eye"></i>
                  </button>

                  <b-modal :id="'detailsType' + type.id" title="detail du type d'avenant">
                    <h1>
                      {{ type.label }}
                    </h1>

                    <p>{{ type.description ?? "Aucune description" }}</p>

                    <div class="row">
                      <div v-if="type.allRisks" class="col">
                        Impact tous les risques
                      </div>
                      <div class="col" v-if="type.primeImpact">
                        Impact la prime
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        Fonction de l'ancien Avenant : <br> <span class="fw-bolder">{{ type.lastFunction }}</span>
                      </div>
                      <div class="col">
                        Fonction de calcul de la prime: <br>
                        <span class="fw-bolder">{{ type.calculPrimeFunction }}</span>
                      </div>
                    </div>

                    <h3 class="mt-5">Fonctionnalites</h3>

                    <div class="my-5">
                      <span v-for="(feat, index) in type.feature" class="badge badge-primary me-3" :key="index">
                        {{ feat.label }}
                      </span>
                    </div>
                  </b-modal>

                  <router-link :to="{
                  name: 'avenant-type-form',
                  params: { id: type.id },
                }">
                    <button class="btn-icon btn-secondary btn btn-sm mx-3">
                      <i class="fad fa-edit"></i>
                    </button>
                  </router-link>
                  <button @click="deleteTypeAvenant(type.id)" class="btn-icon btn-danger btn btn-sm">
                    <i class="fad fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div></div>
      </div>
    </div>
  </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import Popup from "@/entity/Popup";
import { api } from "@/services/Api";
import TypeAvenant from "@/entity/TypeAvenant";
import Feature from "@/entity/Feature";
import { helper } from "@/services/Helper";
import router from "@/router";

@Component({
  components: { Base },
})
export default class AvenantTypeManager extends Vue {
  typesAvenant: TypeAvenant[] = [];

  helper = helper;
  featureSearch = null;

  async loadTypesAvenant() {
    const pop = new Popup(
      "Recuperation des types d'avenant",
      "CHARGEMENT",
      "primary",
      "fad fa-sync",
      true
    );
    const res = await api.get(api.core, "type/avenant/list");
    if (res && res.data && res.data.typesAvenant) {
      // (this.$refs.dataTypeModal as any).hide()
      this.typesAvenant = [];
      res.data.typesAvenant.forEach((type: TypeAvenant) => {
        this.typesAvenant.push(new TypeAvenant(type));
      });
    }
    pop.hide();
  }

  async deleteTypeAvenant(id: number) {
    const pop = new Popup(
      "Recuperation des types d'avenant",
      "CHARGEMENT",
      "warning",
      "fad fa-trash",
      true
    );
    const res = await api.delete(api.core, "type/avenant/delete/" + id);

    if (res.status == 200) {
      this.loadTypesAvenant();
    }
    pop.hide();
  }

  mounted() {
    this.loadTypesAvenant();
  }
}
</script>
<style scoped></style>

<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <div class="d-flex flex-column flex-lg-row-fluid py-10 flex-2">
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <div style="height:250px;width:100%;">
            <Logo :zoom="-.5"></Logo>
          </div>
          <div class="w-lg-500px p-10 p-lg-15 mx-auto">
            <form v-if="page=='reset'" class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                  novalidate="novalidate">
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">{{ trans('forgot_password_?') }}</h1>
              </div>
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <label class="form-label fs-6 fw-bolder text-dark">Email</label>
                <input autocomplete="off" class="form-control form-control-lg form-control-solid"
                       name="email" type="email" v-model="username"/>
                <div class="fv-plugins-message-container"></div>
              </div>
              <div class="text-center">
                <button @click.prevent="setPage('login')" class="btn btn-lg btn-secondary fw-bolder me-3 my-2"
                        type="submit">
                  <span class="indicator-label">Annuler</span>
                </button>
                <button :data-kt-indicator="isLoading ? 'on' : 'off'" @click.prevent="confirmReset()"
                        class="btn btn-lg btn-primary fw-bolder me-3 my-2" type="submit">
                  <span class="indicator-label">Reinitialiser</span>
                  <span class="indicator-progress">En cours...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
                <!--                                <a class="btn btn-light-primary btn-lg fw-bolder my-2" href="#">-->
                <!--                                    <img alt="Logo" class="h-20px me-3" src="assets/media/svg/social-icons/google.svg">Sign-->
                <!--                                    in with Google</a>-->
              </div>
              <div></div>
            </form>
            <form v-else-if="page=='login'" action="#" class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                  id="kt_sign_in_form">
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">{{ trans('Portail Partenaire') }}</h1>
                <div class="text-gray-400 fw-bold fs-4">
                  Vous souhaitez devenir partenaire ?
                  <a class="link-primary fw-bolder" href="">
                    Créer un compte
                  </a></div>
              </div>
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <label class="form-label fs-6 fw-bolder text-dark">Email</label>
                <input autocomplete="off" class="form-control form-control-lg form-control-solid"
                       name="email" type="text" v-model="username"/>
                <div class="fv-plugins-message-container"></div>
              </div>
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0">
                    {{ trans('Password') }}
                  </label>
                  <span @click="setPage('reset')" class="link-primary fs-6 fw-bolder cursor-pointer">
                                        {{ trans('Forgotten_?') }}
                                    </span>
                </div>
                <input autocomplete="off" class="form-control form-control-lg form-control-solid"
                       name="password" type="password" v-model="password"/>
                <div class="fv-plugins-message-container"></div>
              </div>
              <!--              <div id="recaptcha" class="g-recaptcha mb-10 d-flex justify-content-center"-->
              <!--                   :data-sitekey="recaptchaKey"></div>-->
              <div class="text-center">
                <button :data-kt-indicator="isLoading ? 'on' : 'off'" @click.prevent="signin()"
                        class="btn btn-lg btn-primary fw-bolder me-3 my-2" id="kt_sign_in_submit" type="submit">
                  <span class="indicator-label">Connexion</span>
                  <span class="indicator-progress">En cours...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
              <div></div>
            </form>
            <form v-else-if="page == 'verification'" class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                  novalidate="novalidate">
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3 bg-light-primary px-5 py-3 rounded text-uppercase">{{ username }}</h1>
              </div>
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <label class="form-label fs-6 fw-bolder text-dark">Entrez le code de verification (SMS ou Email)</label>
                <input autocomplete="off" class="form-control form-control-lg form-control-solid"
                       name="email" type="email" v-model="code"/>
                <div class="fv-plugins-message-container"></div>
              </div>
              <div class="text-center">
                <button @click.prevent="setPage('login')" class="btn btn-lg btn-secondary fw-bolder me-3 my-2"
                        type="submit">
                  <span class="indicator-label">Annuler</span>
                </button>
                <button :data-kt-indicator="isLoading ? 'on' : 'off'" @click.prevent="verify()"
                        class="btn btn-lg btn-primary fw-bolder me-3 my-2" type="submit">
                  <span class="indicator-label">Connexion</span>
                  <span class="indicator-progress">
                    En cours...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
              <div></div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import router from "@/router";
import {auth} from "@/services/Auth";
import Logo from "@/components/Logo.vue";
import User from '@/entity/User';
import Swal from 'sweetalert2';
import Popup from "@/entity/Popup";
import {helper} from "@/services/Helper";
// import {api} from "@/services/Api";

@Component({
  components: {Logo}
})
export default class PartnerLogin extends Vue {
  private username = '';
  private password = '';
  public isLoading = false;
  page = 'login'
  code = ''
  private error = '';
  public recaptchaKey = api.recaptchaKey
  token = ''

  setPage(value: string) {
    this.code = ''
    this.page = value
  }

  async verify() {
    if (!helper.empty(this.code) && !helper.empty(this.token)) {
      this.$store.commit('loading')
      const res = await api.get(api.auth, 'auth/partner/confirm/' + this.code, {
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      });
      this.$store.commit('stopLoading')
      if (res && res.data && res.data.token && res.data.refreshToken) {
        auth.loginData(res.data)
      }
    }
  }


  public async signin(): Promise<void> {
    if (this.isLoading) {
      return
    }
    this.isLoading = true;
    this.error = ''
    if (!this.username.length) {
      this.error = 'Entrez votre nom d\'utilisateur';
      this.isLoading = false;
    }
    if (!this.password.length) {
      this.error = 'Entrez votre mot de passe';
      this.isLoading = false;
    }

    // const w = window as any
    // if (!w.grecaptcha) {
    //   this.error = 'Veuillez patienter un instant';
    //   new Popup('Chargement en cours', 'Veuillez patienter un instant', 'info', 'fad fa-exclamation-triangle')
    //   this.isLoading = false
    //   return
    // }
    let captchaResponse = ''
    // try {
    //   captchaResponse = w.grecaptcha.getResponse()
    // } catch (e) {
    //   this.isLoading = false;
    //   new Popup('Erreur', 'Une erreur a été rencontrée lors du chargement de la page. Veuillez actualiser la page', 'info', 'fad fa-exclamation-triangle')
    //   this.loadCaptcha()
    //   return
    // }
    // if (helper.empty(captchaResponse)) {
    //   this.error = 'Veuillez cochez la case "Je ne suis pas un robot"';
    //   new Popup('Info', 'Veuillez cochez la case "Je ne suis pas un robot"', 'info', 'fad fa-question-circle')
    //   this.isLoading = false
    //   return
    // }
    if (helper.empty(this.error)) {
      try {
        const token = await auth.partnerLogin(this.username, this.password);
        if (token) {
          this.token = token
          this.setPage('verification')
        }
      } catch (e) {
        this.isLoading = false;
      }
      this.isLoading = false;
    }
    this.isLoading = false;
    this.$forceUpdate();
  }

  async resetPass(): Promise<void> {
    this.isLoading = true;
    this.$store.commit('load');
    const res = await api.post(api.auth, 'shared/reset/password', {email: this.username});
    this.$store.commit('stopLoad');
    this.isLoading = false;
    this.$forceUpdate();
  }

  async confirmReset() {
    const reset = await Swal.fire({
      title: 'Réinitialisation',
      icon: 'question',
      html: 'Êtes-vous spur de vouloir entamer cette procédure ?',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
          'Réinitialiser',
      confirmButtonAriaLabel: 'Réinitialiser',
      cancelButtonText:
          'Annuler',
      cancelButtonAriaLabel: 'Annuler'
    })
    if (reset.isConfirmed) {
      const res = await api.get(api.auth, 'api/reset/password/' + this.username)
    }
  }

  logout() {
    auth.logout();
  }

  get isConnected(): boolean {
    return api.isConnected();
  }

  mounted() {
    console.log('mount')
    localStorage.removeItem('user');
    localStorage.removeItem('userRefreshToken');
    localStorage.removeItem('token');
    localStorage.setItem('userType', 'partner')
    // this.loadCaptcha()
    // localStorage.clear();
    // auth.logout();
  }

  loadCaptcha() {
    this.$store.state.sleep(500)
    console.log('load captcha')
    const w = window as any
    if (w.grecaptcha) {
      w.grecaptcha.ready(() => {
        const container = document.querySelector("#recaptcha")!
        if (helper.empty(container.innerHTML)) {
          w.grecaptcha.render('recaptcha', {
            'sitekey': api.recaptchaKey,
            'callback': (response: any) => {
            }
          });
        }
      })
    } else {
      this.$store.state.sleep(500)
      this.loadCaptcha()
    }
  }
}
</script>
<style scoped></style>

<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'prospection-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-user"></i>
                  {{ trans("Details du client") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:content>
    <template v-if="isLoading">
      <Loading />
    </template>

    <template v-else>
      <b-card>
        <div class="row justify-content-start align-items-center mt-3 g-2">
          <div class="col">
            <h1 style="font-size: 6rem; position: absolute; top: -35px">
              {{
                  customer?.status == "HOT"
                    ? "😍"
                    : customer?.status == "WARM"
                      ? "😑"
                      : customer?.status == "COLD"
                        ? "🥶"
                        : customer?.status == "UNVALID"
                          ? "😒"
                          : "🫥"
                }}
            </h1>
            <h1 class="mt-5">{{ customer?.name }}</h1>
            <h3>Client {{ customer?.type }}</h3>
            <!--  <h3 v-if="customer?."> {{ customer?.company?.name && `/ ${customer?.company?.name}` }}</h3> -->
          </div>

          <div class="col">
            <div>
              <div class="d-flex flex-wrap">
                <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">
                  {{ helper.phoneFormat(customer?.mobile) }}
                  <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                    <i class="fad fa-phone"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--
          <div v-if="customer?.manager" class="col">
            <span class="fw-bolder">Gestionnaire:</span>

            <p>{{ customer?.manager }} </p>
          </div>
-->

          <div class="col-3">
            <div class="row g-2">
              <div class="col-12">
                <button type="button" class="btn btn-sm text-white btn-warning w-100">
                  <i class="fad fa-calendar"></i> Programmer un rendez-vous
                </button>
              </div>
              <div class="col-12">
                <router-link :to="{
                  name: 'handshake-form',
                  params: { clientId: customer?.id, uuid: 'null' },
                }">
                  <button type="button" class="btn btn-sm btn-success w-100">
                    <i class="fad fa-phone"></i> Effectuer une prise de
                    contact
                  </button>
                </router-link>
              </div>

              <div class="col-12">
                <button @click="customer && EditCustomer(customer)" type="button"
                  class="btn btn-sm btn-secondary w-100">
                  <i class="fad fa-edit"></i> Modifier
                </button>

                <b-modal @ok="saveClient" v-if="customer" centered id="editProspect" title="Ajouter un prospect">
                  <div class="mb-3">
                    <label for="name" class="form-label">Nom complet</label>
                    <input type="text" name="name" v-model="customer.name" class="form-control" id="name" />
                  </div>

                  <div class="row justify-content-center align-items-center g-2 mb-3">
                    <div class="col">
                      <label for="name" class="form-label">Numero de telephone</label>
                      <input type="tel" name="name" v-model="customer.mobile" class="form-control" id="name" />
                    </div>
                  </div>

                  <div class="my-3 w-100">
                    <div class="fw-bolder my-3">Type de client</div>
                    <div class="d-flex container-check w-100" label="">
                      <div v-for="(item, index) in statusClient" :key="index" class="choiceContent">
                        <input :value="item.value" class="hidden" type="radio" v-model="customer.status"
                          name="statusClient" :id="'statusClient' + index" />
                        <label style="font-size: 2.5rem" :class="index == statusClient.length - 1
                    ? ''
                    : 'border-end-0 '
                  "
                          class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                          :for="'statusClient' + index">
                          {{ item.text }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="my-3 w-100">
            
            

            <div>
           
                      <AutoComplete v-model="customer.manager" :options="usermaped" label="Assigner le prospsect" />
                    </div>

                    -->
                  <!--
                <div class="col">
                  <label for="name" class="form-label">Localisation</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                  />
                </div>
              </div>

              <div class="mb-3">
                <label for="dateBirthday" class="form-label"
                  >Date de naissance</label
                >
                <input
                  type="date"
                  name="dateBirthday"
                  class="form-control"
                  id="dateBirthday"
                />
              </div>
            <div class="border my-5" />

 -->

                  <h5 class="text-center">Informations supplementaires</h5>

                  <div v-for="(item, index) in customer.additionnalInfo" :key="index">
                    <select class="form-select form-select-sm" @change="$forceUpdate()"  v-model="item.type">
                      <option>
                        Selectionnez le type d'information 
                      </option>
                      <option v-for="(option, index) in optionsInfo" :value="option.value" :key="index">
                        {{ option.text }} 
                      </option>
                    </select>


                    <b-input-group v-if="item.type" class="my-2">
                      <b-form-input v-model="item.label" placeholder="Libelé"></b-form-input>
                      <b-form-input v-if="item.type != 'FILE'" v-model="item.value"></b-form-input>
                      <div v-else class="mx-3">
                        <FileUpload v-model="item.value" />
                      </div>

                      <b-input-group-append>
                        <div @click="removeInfo(index)" style="
                              border-top-left-radius: 0 !important;
                              border-bottom-left-radius: 0 !important ;
                            " class="btn btn-sm btn-icon btn-danger rounded-end">
                          <i class="fad fa-times"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>


                  </div>

                  <div @click="addInfo"
                    class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary">
                    Ajouter une information
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <div class="row mt-5 g-2">
        <div class="col" v-if="customer?.handshakes.length != 0">
          <div class="card text-start">
            <div class="card-body">
              <h4 class="card-title">
                Prises de contact effectués ({{
                  customer?.handshakes.length
                }})
              </h4>

              <div>
                <template v-for="(item, index) in customer?.handshakes">
                  <div :key="index" class="row justify-content-center align-items-end g-2 border-bottom mt-5 py-5">
                    <div class="col">

                      <span class="fw-bold">
                        {{ 
                          item.title
                         }}
                      </span>
                      <span class="text-muted fw-bold text-muted d-block fs-7">
                        Effectué le {{ helper.readable(item.createdAt) }} par
                        {{ item.createBy }}



                        {{
                          item.type == "a_distance"
                            ? "A distance"
                            : item.type == "rendezvous"
                            ? "En presentiel "
                            : null
                        }}
                      </span>

                  
                    </div>

                    <div class="col-2">
                      <button   @click="ViewHandShake(item.id)" type="button" class="btn btn-primary btn-sm">
                        <i class="fad fa-eye"></i> Voir plus
                      </button>



                      <b-modal :id="'viewHandShake' + item.id" title="Details de la prospection" size="xl">
                        <h2 style="text-transform: uppercase">
                          {{ item.title }}
                        </h2>

                        <div class="row g-2">
                          <div class="col">
                            <div
                              class="row justify-content-center align-items-center g-2 mt-5"
                            >
                              <div class="col">
                                <p class="fw-bolder">
                                  Propection
                                  {{
                                    item.type == "a_distance"
                                      ? "à distance"
                                      : item.type == "rendezvous"
                                      ? "par Rendez-vous"
                                      : null
                                  }}
                                </p>

                                <div>
                                  {{ item.createBy }} <br />
                                  le {{ helper.readable(item.createdAt) }}
                                </div>
                              </div>
                              <div class="col">
                                <p>Type de prospection</p>
                                <h5>
                                  {{ item.category }}
                                </h5>
                              </div>
                            </div>

                            <div class="mt-5">
                              <h3>Notes</h3>

                              <div
                                v-for="(subItem, index) in item.notes"
                                class="border-bottom border-top py-5"
                                :key="index"
                              >
                                <p>
                                  {{ item.createBy }} <br />
                                  le {{ helper.readable(subItem.createdAt) }}
                                </p>
                                <iframe
                                  :srcdoc="subItem.comment"
                                  width="100%"
                                  height="100%"
                                ></iframe>
                              </div>
                            </div>
                          </div>

                         
                        </div>
                      </b-modal>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
<!--
        <div class="col">
          <div class="card text-start">
            <div class="card-body">
              <h4 class="card-title">
                Rendez-vous pris ({{ customer?.handshakes.length }})
              </h4>

              <div>
                <template v-for="(item, index) in customer?.handshakes">
                  <div :key="index" class="row justify-content-center align-items-end g-2 border-bottom mt-5 py-5">
                    <div class="col">
                      <span class="text-muted fw-bold text-muted d-block fs-7">
                        Effectué le {{ helper.readable(item.createdAt) }} par
                        {{ item.createBy }}
                      </span>

                      <span class="fw-bold">
                        Cotations effectués : null
                      </span>
                    </div>

                    <div class="col-2">
                      <button type="button" class="btn btn-primary btn-sm">
                        <i class="fad fa-eye"></i> Voir plus
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        -->
        <div class="col" v-if="customer?.leads.length != 0">
          <div class="card text-start">
            <div class="card-body">
              <h4 class="card-title">Informations en tant que lead</h4>

              <div>
                <template v-for="(item, index) in customer?.leads">
                  <div :key="index" class="align-items-end g-2 border-bottom mt-5 py-5">
                    <div class="py-3">
                      <h5>Nom: {{ item.name }}</h5>
                      <h5>Prenoms: {{ item.prename }}</h5>
                      <h5>Email: {{ item.email }}</h5>
                    </div>

                    <div class="mt-3">
                      <h3 class="text-center my-3">
                        Cotations Effectuées ({{
                  item?.responseGroups.length
                }})
                      </h3>
                      <div class="mt-3" v-for="(subItem, index) in item?.responseGroups" :key="index">
                        <div>
                          <router-link :to="{
                  name: 'response-group-manager',
                  params: {
                    surveySlug: subItem.survey.slug,
                    rgUuid: subItem.uuid,
                  },
                }" class="text-dark text-uppercase fw-bolder text-hover-primary fs-6" target="_blank">
                            <div class="d-flex justify-content-between align-items-center">
                              <div>
                                <span>{{ subItem.alias }}</span>

                                <span class="fw-bolder d-block fs-7">
                                  {{ subItem.survey.label }} ▪️
                                  {{ subItem.source }}
                                </span>
                              </div>

                              <div>
                                <span class="text-muted me-2 fs-7 fw-bold">{{ subItem.state }} %</span>
                                <span v-if="subItem.status &&
                  CONSTANTS.responseGroupStatus
                  " :class="{
                  'badge-secondary':
                    subItem.status === 'INCOMPLETE',
                  'badge-dark':
                    subItem.status.match(/REACHABLE/),
                  'badge-light-success':
                    subItem.status === 'QUALIFIED',
                  'badge-light-danger':
                    subItem.status.match(/DISQUALIFIED/),
                  'badge-danger':
                    subItem.status.match(/COMPARED/),
                  'badge-info':
                    subItem.status.match(/OFFER_SELECTED/),
                  'badge-success':
                    subItem.status === 'VALIDATED',
                }" class="badge text-uppercase mt-3 badge-secondary">
                                  {{ subItem.status }}
                                </span>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="customer?.additionnalInfo.length != 0" class="col">
          <div class="card text-start">
            <div class="card-body">
              <h4 class="card-title">Informations supplementaires</h4>

              <div>
                <template v-for="(item, index) in customer?.additionnalInfo">
                  <div :key="index" class="row justify-content-center align-items-end g-2 border-bottom mt-5 py-5">
                    <div>
                      <h4>{{ item.label }} :</h4>

                      <template v-if="item.type == 'FILE'">
                        <div>
                          <iframe :src="item.value" frameborder="0"></iframe>
                        </div>
                      </template>

                      <template v-if="(item.type = 'TEXT')">
                        <h3>{{ item.value }}</h3>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </template>
  </Base>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import Loading from "@/components/Loading.vue";
import UniqProspect from "../../entity/UniqProspect";
import CONSTANTS from "@/Utils/constant";
import Popup from "@/entity/Popup";
import AdditionalInfo from '../../entity/AdditionalInfo';
import FileUpload from "../../components/FileUpload.vue";

@Component({
  components: { Base, Loading, FileUpload },
})
export default class DetailsClientView extends Vue {
  customer: UniqProspect | null = null;
  isLoading: boolean = false;

  source = api.uploadRoot;

  async load() {
    this.isLoading = true;

    const res = await api.get(api.form, "prospect/info/" + this.$route.params.id);

    if (res && res.data) {
      this.customer = new UniqProspect(res.data.prospect);
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }

    this.$forceUpdate();
  }

  ViewHandShake(id: number) {
    this.$bvModal.show("viewHandShake" + id);

    this.$forceUpdate;
  }
  statusClient = [
    {
      text: "😒",
      value: "UNVALID",
    },
    {
      text: "🥶",
      value: "COLD",
    },

    {
      text: "😑",
      value: "WARM",
    },
    {
      text: "😍",
      value: "HOT",
    },
  ];

  optionsInfo = [
    {
      value: "TEXT",
      text: "Informations",
    },
    {
      value: "FILE",
      text: "Fichiers",
    },
  ];
  addInfo() {
    const additionnaInfo = new AdditionalInfo();

    this.customer?.additionnalInfo.push(additionnaInfo);


    // this.$forceUpdate();
  }

  async saveClient() {
    const pop = new Popup(
      "Chargement",
      "Enregistrement du prospect",
      "primary",
      "fad fa-sync",
      true
    );


    try {
      const res = await api.post(api.form, "prospect/edit", 
        this.customer
      );

      if (res.status == 200) {
        pop.hide();
        this.load();
      }
    } catch (error) {
      pop.hide();
    }
  }
  removeInfo(item: number) {
    this.customer?.additionnalInfo.splice(item, 1);
    this.$forceUpdate();
  }

  EditCustomer(customer?: UniqProspect) {
    this.$bvModal.show("editProspect");
    if (customer) {
      this.customer = customer;
    } else {
      console.log("object");
      this.customer = new UniqProspect();
    }
  }

  mounted() {
    this.load();
  }
}
</script>

<style scoped></style>

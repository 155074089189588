<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div
          class="container-fluid d-flex flex-stack"
          id="kt_toolbar_container"
        >
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link
                    :to="{ name: 'dashboard' }"
                    class="text-muted text-hover-primary"
                  >
                    <i class="fad fa-home"></i>
                    {{ trans("Creation d'une facture") }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

          <div class="btn btn-sm btn-primary" @click="preview">Apercu</div>
          <b-modal size="xl" id="my-modal">
            <iframe width="100%" height="600px" :src="previewData" frameborder="0"> </iframe>
          </b-modal>
        </div>
      </div>
    </template>

    <template #content>
      <div class="card">
        <div class="card-body">
          <h1>Creation de la facture</h1>

          <div class="my-5">
            <label class="my-2" for="">{{ trans("Description") }}</label>
            <div>
              <input
                type="text"
                class="form-control"
                v-model="invoice.description"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card my-5">
        <div class="card-header d-flex align-items-center">
          <h2>{{ trans("Informations de la facture") }}</h2>
        </div>
        <div class="card-body">
          <div>
            <div class="row g-5">
              <div
                v-for="(header, index) in invoice.header"
                :key="index"
                class="col-6"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <p class="fs-3">Item {{ index + 1 }}</p>

                  <div class="btn btn-sm btn-icon btn-danger rounded-end">
                    <i class="fad fa-times"></i>
                  </div>
                </div>

                <div class="mb-5">
                  <label class="my-2" for="">{{ trans("Titre") }}</label>
                  <b-form-input
                    v-model="header.label"
                    label="Titre"
                    placeholder="Titre"
                  ></b-form-input>
                </div>

                <div>
                  <label class="my-2" for="">{{ trans("Valeurs") }}</label>

                  <template v-for="(value, subIndex) in header.values">
                    <b-input-group class="my-2" :key="subIndex">
                      <b-form-input
                        v-model="header.values[subIndex]"
                        placeholder="Libelé"
                      ></b-form-input>

                      <b-input-group-append>
                        <div
                          @click="removeInfo(subIndex)"
                          style="
                            border-top-left-radius: 0 !important;
                            border-bottom-left-radius: 0 !important ;
                          "
                          class="btn btn-sm btn-icon btn-danger rounded-end"
                        >
                          <i class="fad fa-times"></i>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <button
                    @click="addInfoIndex(header.label)"
                    class="btn btn-sm btn-primary w-100"
                  >
                    Ajouter un information
                  </button>
                </div>
              </div>
              <div
                @click="createHeaderIndex"
                class="col-6 border-3 rounded border-dashed border-primary text-primary d-flex justify-content-center flex-column align-items-center fs-1"
              >
                <i style="font-size: 3rem" class="fad fa-plus text-primary"></i>

                <span> Ajouter d'un item </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h2>{{ trans("Articles") }}</h2>
        </div>
        <div class="card-body">
          <div class=" ">
            <div
              class="my-3"
              v-for="(article, index) in invoice.article"
              :key="index"
            >
              <p>Article {{ index + 1 }}</p>

              <b-input-group class="my-2" :key="index">
                <b-form-input
                  v-model="article.label"
                  placeholder="Article"
                ></b-form-input>

                <b-form-input
                  v-model="article.price"
                  type="number"
                  placeholder="Prix"
                ></b-form-input>

                <b-input-group-append>
                  <div
                    style="
                      border-top-left-radius: 0 !important;
                      border-bottom-left-radius: 0 !important ;
                    "
                    class="btn btn-sm btn-icon btn-danger rounded-end"
                  >
                    <i class="fad fa-times"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

            <button @click="createArticle" class="btn btn-sm btn-primary w-100">
              Ajouter un Article
            </button>

            <div class="d-flex justify-content-between align-items-center my-5">
              <h1>Prix total</h1>

              <h1>{{ sumArticle(invoice.article) }} FRANCS CFA</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label class="form-label">{{ trans("Remarques") }}</label>
          <b-form-textarea v-model="invoice.remarque"> </b-form-textarea>

          <div class="d-flex justify-content-end">

            <button class="btn btn-primary mt-5" @click="save">
              Enregistrer la facture 
            </button>
          </div>
         
        </div>

      
      </div>


      
    </template>
  </Base>
</template>

<script lang="ts">
import Invoice from "@/entity/Invoice";
import Popup from "@/entity/Popup";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: { Base },
})
export default class CreateInvoice extends Vue {
  invoices = [];
  invoice: Invoice = new Invoice();

  headers: any = [];
  articles: any = [];

  previewData: any = null;

  sumArticle(articles: any) {
    let totalPrice = 0;

    for (const article of articles) {
      totalPrice += parseInt(article.price, 10);
    }

    return totalPrice;
  }

  mounted() {
    // this.load()

    this.createArticle();
    this.createHeaderIndex();
  }

  createArticle() {
    this.invoice.article.push({
      label: "",
      price: 0,
    });
  }

  createHeaderIndex() {
    const headerOption = {
      label: "",
      values: [""],
    };

    this.invoice.header.push(headerOption);
  }

  addInfoIndex(label: string) {
    const header = this.invoice.header.find((a) => a.label === label);
    if (header) {
      this.invoice.header.forEach((headerItem) => {
        headerItem.values.push("");
      });
    }
  }

  async preview() {
    const response = await api.post(api.core, "invoice/preview", {
      invoice: this.invoice,
    }, {
      responseType: 'blob'
    });

    if (response.status == 200) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const urlBlob = URL.createObjectURL(blob); 
      window.open(urlBlob)
    }
  }


  async save() {

    const pop = new Popup(
      "CHARGEMENT",
      "Creation d'une facture",
      "primary",
      "fad fa-sync",
      true
    );

    const response = await api.post(api.core, "invoice/create", {
      invoice: this.invoice,
    },);
    

    pop.hide()
    if (response.status == 200) {
     
      this.$router.back()
    }
  }


  // async load(){
  //   const response = await api.get(api.core, "invoice/load", {

  //   })
  // }
}
</script>

<style scoped></style>

<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

          <div class="d-flex align-items-center py-1">
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="card">
        <div class="card-body">

          <div class="d-flex">
            <div>
              <div>
                Id
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Date
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Initiateur
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Source
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Methode de paiement
              </div>
              <div>

              </div>
            </div>


            <div>
              <div>
                Label
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Client
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Montant
              </div>
              <div>

              </div>
            </div>

            <div>
              <div>
                Contrat
              </div>
              <div>

              </div>
            </div>
          </div>


        </div>
      </div>
      <div>

      </div>
    </template>
  </Base>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";

@Component({
  components: {Base}
})
export default class PaymentShow extends Vue {

}
</script>


<style scoped>

</style>

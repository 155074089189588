const menus = [
    {
        label: 'Configuration',
        icon: 'fad fa-cogs',
        roles: [['ROLE_CONFIGURATION']],
        route: {name: 'configuration-index'},
        pathMatch: '/configuration',
        subMenus: [
            {
                label: 'Branches',
                icon: 'fad fa-folder-tree',
                route: {name: 'configuration-index'},
                roles: [['ROLE_CONFIGURATION_BRANCHES']],
                pathMatch: 'configuration/branch'
            },
            {
                label: 'Type de données',
                icon: 'fad fa-sort-numeric-up',
                route: {name: 'data-type-manager'},
                roles: [['ROLE_CONFIGURATION_DATA_TYPES']],
                pathMatch: 'configuration/data'
            },
            {
                label: "Editeur d'avenant",
                icon: 'fad fa-file-signature',
                route: {name: 'avenant-type-manager'},
                roles: [['ROLE_CONFIGURATION_AVENANT_TYPES']],
            },
            {
                label: "Tags",
                icon: 'fad fa-tag',
                route: {name: 'tag-manager'},
                roles: [['ROLE_CONFIGURATION_TAGS']],
            },
            {
                label: "Motifs",
                icon: 'fad fa-question',
                route: {name: 'reason-manager'},
                roles: [['ROLE_CONFIGURATION_REASONS']],
            },
            {
                label: "Durées",
                icon: 'fad fa-watch',
                route: {name: 'duration-manager'},
                roles: [['ROLE_CONFIGURATION_DURATIONS']],
            },
            {
                label: "Plateformes",
                icon: 'fad fa-desktop-alt',
                route: {name: 'platform-list'},
                roles: [['ROLE_CONFIGURATION_PLATFORMS']],
            },
            {
                label: "Formulaires",
                icon: 'fad fa-ballot-check',
                route: {name: 'orphan-form-list',params: { slug: 'orphan' }},
                roles: ['ROLE_CONFIGURATION_FORMS'],
            },
            {
                label: "Assureurs",
                icon: 'fad fa-building',
                route: {name: 'insurer-list'},
                roles: ['ROLE_CONFIGURATION_INSURERS'],
            },
            {
                label: "Catégories d'offre",
                icon: 'fad fa-boxes',
                route: {name: 'pack-category-list'},
                roles: ['ROLE_CONFIGURATION_PACK_CATEGORIES'],
            },
            {
                label: "Langues",
                icon: 'fad fa-language',
                route: {name: 'language-list'},
                roles: ['ROLE_CONFIGURATION_LANGUAGES'],
            },
            {
                label: "Paramètres",
                icon: 'fad fa-cogs',
                route: {name: 'parameters'},
                roles: ['ROLE_CONFIGURATION_PARAMETERS'],
            },
            {
                label: "Documentation",
                icon: 'fad fa-info-circle',
                href: '/doc.pdf',
                route: null,
                roles: ['ROLE_MANAGER'],
            },
        ]
    },
]

export default menus;

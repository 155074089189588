/* eslint-disabled */
import InvoiceExtend from "@/entity/extends/InvoiceExtend";

export default class Invoice extends InvoiceExtend {

public number = '';
public header= [] as any[];
public description= "";
public remarque? = '';
public article= [] as any[];
public orderOf? = '';
public uuid? = '';
public createdAt? = '';
public updatedAt? = '';
public createBy? = '';
public updateBy? = '';
public removeBy? = '';
public createdFromIp? = '';
public updatedFromIp? = '';

  constructor (object?: any) {
      super(object)
      if(object){
       this.number= object.number;
       this.header= object.header;
       this.description= object.description;
       this.remarque= object.remarque;
       this.article= object.article;
       this.orderOf= object.orderOf;
       this.uuid= object.uuid;
       this.createdAt= object.createdAt;
       this.updatedAt= object.updatedAt;
       this.createBy= object.createBy;
       this.updateBy= object.updateBy;
       this.removeBy= object.removeBy;
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
      }
      this.postConstruct()
  }

}

import { render, staticRenderFns } from "./CreateInvoice.vue?vue&type=template&id=5b222ffd&scoped=true"
import script from "./CreateInvoice.vue?vue&type=script&lang=ts"
export * from "./CreateInvoice.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b222ffd",
  null
  
)

export default component.exports
/* eslint-disabled */

export default class PremiumDetailExtend {

    edit = false
    updated = false

    constructor(object?: any) {
    }

    postConstruct() {
    }
}

<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:content>

      <div class="card my-5" key="avenants">
        <div class="card-body">
          Configurer les options de connexions selon les type d'utilisateur
          <br>
          2FA => Activé ? Sms ? Email ?
        </div>
      </div>

      <div class="card my-5" key="avenants">
        <div class="card-body">
          Configurer les methodes de paiements
          <br>
          Clé Api, Désactivé ?
        </div>
      </div>


      <div class="card my-5" key="avenants">
        <div class="card-body">
          Configurer les validateurs de numéro de téléphone
          <br>
          A coder pour chaque tenant ou pays
        </div>
      </div>


      <div class="card my-5" key="avenants">
        <div class="card-body">
          Configurer l'api whatsapp
          <br>
          La gestion des numéro de téléphone
          <br>
          L'autorisation aux partenaires ?
        </div>
      </div>

      <div class="card my-5" key="avenants">
        <div class="card-body">
          Configurer l'api whatsapp
          <br>
          La gestion des numéro de téléphone
          <br>
          L'autorisation aux partenaires ?
        </div>
      </div>

      <div class="card my-5" key="avenants">
        <div class="card-body">
          <b-tabs>
            <b-tab>
              <template #title>
                <div>Methodes de calcul</div>
              </template>
              <div class="card">
                <div class="card-body">
                  <div class="">
                    <div v-for="method in parameters.methods" class="m-5">
                      <div>
                        {{ method.label }}
                      </div>
                      <div>
                        <input type="text" v-model="method.value" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <div class="btn btn-primary">
                      Enregistrer
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <div>Etats</div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Contract from "@/entity/Contract";
import {api} from '@/services/Api';
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Avenant from "@/entity/Avenant";
import Item from "@/entity/Item";
import UserView from "@/components/UserView.vue";

import Property from "@/entity/Property";
import IncorporationDetail from "@/views/Contract/IncorporationDetail.vue";
import Printable from "@/entity/Printable";
import Swal from 'sweetalert2'
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import Engine from "@/entity/Engine";
import Insurer from "@/entity/Insurer";
import EngineMethod from "@/entity/EngineMethod";

@Component({
  components: {IncorporationDetail, FileUpload, Loading, Base, UserView}
})
export default class Parameters extends Vue {
  tabIndex = 0
  loading = false
  parameters = {
    methods: [
      {
        label: 'Affaire Nouvelle',
        code: 'new-order',
        value: 'AvenantAffaireNouvelle'
      },
      {
        label: 'Renouvellement',
        code: 'renewal',
        value: 'AvenantRenouvellement'
      },
      {
        label: 'Méthode de Génération de Police',
        code: 'policy-generator',
        value: 'PolicyGenerator'
      },
      {
        label: 'Commission',
        code: 'commission',
        value: 'CommissionWiASSUR'
      },
      {
        label: 'Commission Apporteur',
        code: 'commission-apporteur',
        value: 'CommissionApporteur'
      },
    ]
  }


}
</script>
<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .2s;
}

.slide-left-leave-from {
  transition: all .2s;
}

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.modal-xl .modal-content {
  height: 100%;
}</style>

import store from "@/store";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import CONSTANTS, {DATES} from "@/Utils/constant";


export const FILTER_API_DATE_FORMAT = 'YYYYMMDDHHmmss';
export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const TABLE_DISPLAY_DATE_FORMAT = 'MM/DD/YYYY h:mm:ssA';

class Helper {

    public uploadRoot = process.env.VUE_APP_MASTER_URL + 'uploads/'

    empty(data: any): boolean {
        if (data === null || data === undefined || data === 'null' || data === 'undefined') {
            return true
        }
        if (Array.isArray(data)) {
            if (data.length === 0) {
                return true
            }
        }
        if (typeof data === "number") {
            return false
        }
        if (typeof data === "string") {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = data
            data = tmp.innerHTML || tmp.innerText || "";
            return data.trim() === ''
        }

        return false
    }


    substractMonth(date: Date, nbMois: number) {
        const nouvelleDate = new Date(date);
        let mois = nouvelleDate.getMonth() - nbMois;
        const annee = nouvelleDate.getFullYear() + Math.floor(mois / 12);
        mois = (mois % 12 + 12) % 12;
        nouvelleDate.setFullYear(annee, mois);
        return nouvelleDate;
    }

    generateId() {
        return uuidv4()
        // const number = Math.random();
        // number.toString(36);
        // return number.toString(36).substr(2, 9);
    }

    currencyFormat(amount: any, abs = false, currency = true) {
        try {
            amount = parseFloat(amount);
            if (abs) {
                amount = Math.abs(amount)
            }
            amount = this.roundMoney(amount, 2)
            // console.log(amount)
            amount = '' + (amount * store.state.currency.factor);
            if (amount === undefined) {
                return amount;
            }
            // amount = this.roundMoney(amount, 2)
            // console.log(amount)
            // if (amount.includes('.')) {
            //     const vals = amount.split('.')
            //     const entier = vals[0]
            //     const decimal = vals[1]
            //     console.log('entier decimal',entier,decimal)
            // }
            amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            if (!currency) {
                return amount
            }
            if (store.state.currency) {
                if (store.state.currency.left) {
                    amount = store.state.currency.symbol + ' ' + amount;
                } else {
                    amount += ' ' + store.state.currency.symbol;
                }
            }
            return amount;
        } catch (e) {
            return amount
        }
    }

    roundMoney(amount: any, count: number) {
        amount = parseFloat(amount);
        // console.log(amount)
        const val = Math.pow(10, count);
        return Math.round(amount * val) / val
    }

    // minifyCurrency(amount: any, abs = false, currency = true) {
    //     amount = parseFloat(amount);
    //     if (abs) {
    //         amount = Math.abs(amount)
    //     }
    //     if(amount > 100)
    // }

    minifyCurrency(amount: any, abs = false, currency = true) {
        if (!amount) {
            return
        }
        amount = amount.toString().replace(/[^0-9.]/g, '');
        amount = parseFloat(amount);
        if (abs) {
            amount = Math.abs(amount)
        }

        if (amount < 1000) {
            return amount;
        }
        let si = [
            {v: 1E3, s: "K"},
            {v: 1E6, s: "M"},
            {v: 1E9, s: "B"},
            {v: 1E12, s: "T"},
            {v: 1E15, s: "P"},
            {v: 1E18, s: "E"}
        ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (amount >= si[index].v) {
                break;
            }
        }
        return (amount / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
    };

    numberFormat(n: number) {
        return new Intl.NumberFormat().format(n);
    }

    isValidDate(dateObject: any) {
        return new Date(dateObject).toString() !== 'Invalid Date';
    }

    phoneFormat(n: string) {
        //
        if (!n) {
            return n
        }
        let i = 0, pattern = null
        if (n.length === 14) {
            pattern = "#### ## ## ## ## ##"
        }
        if (n.length === 13) {
            pattern = "### ## ## ## ## ##"
        }
        if (n.length === 11) {
            pattern = "### ## ## ## ##"
        }
        if (n.length === 10) {
            pattern = "## ## ## ## ##"
        }
        if (n.length == 8) {
            pattern = "## ## ## ##"
        }

        if (pattern) {
            return pattern.replace(/#/g, () => n[i++]);
        }
        return n.replace(/\B(?=(\d{2})+(?!\d))/g, " ");
    }

    dateToHtml(date: Date) {
        try {
            const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()
            const month = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            return day + '-' + month + '-' + date.getFullYear()
        } catch (e) {
            return date
        }
    }

    reversedDateToHtml(date: Date) {

        const dataDate = new Date(date)


        try {
            const day = dataDate.getDate().toString().length === 1 ? ('0' + dataDate.getDate()) : dataDate.getDate()
            const month = (dataDate.getMonth() + 1).toString().length === 1 ? '0' + (dataDate.getMonth() + 1) : (dataDate.getMonth() + 1)
            return dataDate.getFullYear() + '-' + month + '-' + day
        } catch (e) {
            return dataDate.toString()
        }
    }

    readable(date: Date | string | undefined, format: string = 'dmY H:i:s'): string {

        // console.log(date);
        if (typeof date === 'string') {
            try {
                date = new Date(date);
            } catch (e) {
                if (typeof date === 'string') {
                    return date;
                }
                return '';
            }
        }
        if (date instanceof Date) {
            let res = '';
            if (format.includes('w')) {
                const day = date.getDay();
                const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
                res += days[day] + ' ';
            }
            if (format.includes('W')) {
                const day = date.getDay();
                const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
                res += days[day] + ' ';
            }

            if (format.includes('d')) {
                res += date.getDate() + ' ';
            }


            if (format.includes('m')) {
                let month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
                res += month[date.getMonth()] + ' ';
            }
            if (format.includes('M')) {
                let month = ['Janv', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
                res += month[date.getMonth()] + ' ';
            }
            if (format.includes('y')) {
                res += (date.getFullYear() + '').substr(-2);
            }
            if (format.includes('Y')) {
                res += (date.getFullYear() + '');
            }
            let h = date.getHours() < 10 ? '0' : '';
            let m = date.getMinutes() < 10 ? '0' : '';
            let s = date.getSeconds() < 10 ? '0' : '';
            if (format.includes('H')) {
                if (res.length > 0) {
                    res += ' à ';
                }
                res += h + date.getHours();
            }
            if (format.includes('i')) {
                res += ':' + m + date.getMinutes();
            }
            if (format.includes('s')) {
                res += ':' + s + date.getSeconds();
            }
            // console.log(res);
            return res;
        }
        return '';
    }


    removeAccent(string: string) {
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

    getSmartLapse(date?: Date | string | undefined, now?: Date | string) {
        if (typeof now === 'string') {
            try {
                now = new Date(now);
            } catch (e) {
                throw new Error('Impossible de convertir ' + now + ' en date valide');
            }
        }
        if (!now) {
            now = new Date();
        }
        if (typeof date === 'string') {
            try {
                date = new Date(date);
            } catch (e) {
                throw new Error('Impossible de convertir ' + date + ' en date valide');
            }
        }
        if (!date) {
            return '';
        }
        const diff = Math.abs(now.getTime() - date.getTime());
        let text = '';
        const timelapse = {
            y: Math.floor(diff / 1000 / 60 / 60 / 24 / 30 / 12),
            m: Math.floor(diff / 1000 / 60 / 60 / 24 / 30) % 12,
            d: Math.floor(diff / 1000 / 60 / 60 / 24) % 30,
            h: Math.floor(diff / 1000 / 60 / 60) % 24,
            i: Math.floor(diff / 1000 / 60) % 60,
            s: Math.floor(diff / 1000) % 60,
        };


        const dayLength = 86400
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const interval = (today.getTime() - date.getTime()) / 1000
        if (interval < 0) {
            // text = this.addLeadZero(timelapse.h, 2) + ':' + this.addLeadZero(timelapse.i, 2)
            text = this.readable(date, 'H:i')
        } else if (interval < dayLength) {
            text = 'Hier'
        } else if (interval < (dayLength * 7)) {
            // const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
            const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
            text = days[date.getDay()];
        } else {
            text = this.readable(date, 'dMy')
        }
        return text;

    }

    addDays(date: Date, count: number): Date {
        date.setDate(date.getDate() + count);
        return date;
    }


    ConvertTime(time?: number | undefined) {
        const MINUTES_IN_HOUR = 60;
        const HOURS_IN_DAY = 24;
        const DAYS_IN_WEEK = 7;
        const DAYS_IN_MONTH = 30; // Approximation
        const MONTHS_IN_YEAR = 12;

        let minutes: any = time;

        const years = Math.floor(
            minutes /
            (MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_MONTH * MONTHS_IN_YEAR)
        );
        minutes %= MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_MONTH * MONTHS_IN_YEAR;

        const months = Math.floor(
            minutes / (MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_MONTH)
        );
        minutes %= MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_MONTH;

        const weeks = Math.floor(
            minutes / (MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK)
        );
        minutes %= MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK;

        const days = Math.floor(minutes / (MINUTES_IN_HOUR * HOURS_IN_DAY));
        minutes %= MINUTES_IN_HOUR * HOURS_IN_DAY;

        const hours = Math.floor(minutes / MINUTES_IN_HOUR);
        minutes %= MINUTES_IN_HOUR;

        let result: any = {};

        if (years > 0) result.years = years;
        if (months > 0) result.months = months;
        if (weeks > 0) result.weeks = weeks;
        if (days > 0) result.days = days;
        if (hours > 0) result.hours = hours;
        if (minutes > 0) result.minutes = minutes;

        const resultString = Object.keys(result)
            .map(
                (key: any) =>
                    `${result[key]} ${
                        key == "hours"
                            ? "heures"
                            : key == "days"
                                ? "jours"
                                : key == "weeks"
                                    ? "semaines"
                                    : key == "months"
                                        ? "mois"
                                        : key == "years"
                                            ? "années"
                                            : key
                    }`
            )
            .join(" ");

        return resultString;
    }

    getTimeLapse(date?: Date | string | undefined, now?: Date | string, addPrefix = true, format?: string, smart = false): string {

        if (typeof now === 'string') {
            try {
                now = new Date(now);
            } catch (e) {
                throw new Error('Impossible de convertir ' + now + ' en date valide');
            }
        }
        if (!now) {
            now = new Date();
        }
        if (typeof date === 'string') {
            try {
                date = new Date(date);
            } catch (e) {
                throw new Error('Impossible de convertir ' + date + ' en date valide');
            }
        }
        if (!date) {
            return '';
        }
        const diff = Math.abs(now.getTime() - date.getTime());
        let text = '';
        const timelapse = {
            y: Math.floor(diff / 1000 / 60 / 60 / 24 / 30 / 12),
            m: Math.floor(diff / 1000 / 60 / 60 / 24 / 30) % 12,
            d: Math.floor(diff / 1000 / 60 / 60 / 24) % 30,
            h: Math.floor(diff / 1000 / 60 / 60) % 24,
            i: Math.floor(diff / 1000 / 60) % 60,
            s: Math.floor(diff / 1000) % 60,
        };

        if (text.trim() === '' || (format && format.includes('y'))) {
            text += timelapse.y > 0 ? timelapse.y + ' an' : '';
            text += timelapse.y > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('m'))) {
            text += timelapse.m > 0 ? ' ' + timelapse.m + ' ' + 'mois' : '';
        }
        if (text.trim() === '' || (format && format.includes('d'))) {
            text += timelapse.d > 0 ? ' ' + timelapse.d + ' jour' : '';
            text += timelapse.d > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('h'))) {
            text += timelapse.h > 0 ? ' ' + timelapse.h + ' heure' : '';
            text += timelapse.h > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('i'))) {
            text += timelapse.i > 0 ? ' ' + timelapse.i + ' minute' : '';
            text += timelapse.i > 1 ? 's' : '';
        }
        if (text.trim() === '' || (format && format.includes('s'))) {
            text += timelapse.s > 0 ? ' ' + timelapse.s + ' seconde' : '';
            text += timelapse.s > 1 ? 's' : '';
        }
        // console.log(text);


        if (addPrefix) {
            const prefix = now.getTime() - date.getTime() > 0 ? 'Il y a ' : 'Dans ';
            text = prefix + text;
        }
        // console.log(text);
        return text;
    }


    dateFromCode(code: number) {
        const match = DATES.find((data) => {
            return data.code_int === code
        })
        if (match && CONSTANTS.dateConstants.hasOwnProperty(match.code_text)) {
            return CONSTANTS.dateConstants[match.code_text]()
        }
        return null;
    }

    addLeadZero(text: string | number, length: number) {
        text = '' + text;
        while (text.length < length) {
            text = '0' + text;
        }
        return text;
    }

    slugify(text: any, separator = "-") {
        if (!text) {
            return '';
        }
        return text
            .toString()
            .normalize('NFD')                   // split an accented letter in the base letter and the acent
            .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
            // .toLowerCase()
            .trim()
            .replace(/[^A-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
            .replace(/\s+/g, separator);
    };


}

export const helper = new Helper()

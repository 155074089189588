<template>
    <Base>
      <template v-slot:toolbar>
        <div class="toolbar" id="kt_toolbar">
          <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
            <div class="d-flex align-items-center me-3">
              <slot name="leftToolbar">
                <span class="h-20px border-gray-200 border-start mx-4"></span>
                <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                  <li class="breadcrumb-item text-muted">
                    <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                      <i class="fad fa-home"></i>
                      {{ trans('Liste des facture') }}
                    </router-link>
                  </li>
                </ul>
              </slot>
            </div>
  
  
            <div class="d-flex align-items-center py-1">
              <router-link :to="{name:'invoice-form'}" class="btn-sm btn btn-primary ms-5">
                <i class="fad fa-plus"></i>
                {{ trans('Créer une facture') }}
              </router-link>
  
            </div>
  
          </div>
        </div>
      </template>
  
      <template #content>
        <div class="card">
          <div class="card-body">
  
            <table class="table table-striped gx-5">
              <thead>
              <tr>
                <th>
                  {{ trans('Facture') }}
                </th>
                <th>
                  {{ trans('Description') }}
                </th>
                <th>
                  {{ trans('Actions') }}
                </th>
              </tr>
              </thead>
              <tbody>
             <tr v-for="(invoice, index) in invoices" v-bind:key="index">
                <td>
                  <div class="fw-bolder">
                   WA{{ invoice.number }}
                  </div>
                
                </td>
                <td>
                    <div>
                        {{ invoice.description }}
                      </div>
                </td>
                <td>
                  <div 
                               class="btn btn-warning btn-icon btn-sm">
                    <i class="fad fa-edit"></i>
                  </div>
                  <div class="btn btn-sm btn-icon btn-danger ms-2" @click="remove(invoice)">
                    <i class="fad fa-trash-alt"></i>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </Base>
  </template>
  
  <script lang="ts">
  import Base from "@/layouts/Base.vue";
  import {Vue, Component} from 'vue-property-decorator';
  import {api} from "@/services/Api";
  import EmailTemplate from "@/entity/EmailTemplate";
import Invoice from '../../../entity/Invoice';
  
  @Component({
    components: {Base}
  })
  export default class InvoiceList extends Vue {
  
  
    invoices:Invoice[]  = []
  
    mounted() {
      this.load()
    }
  
    async remove(invoice: Invoice) {
      const res = await api.get(api.com, 'email/template/delete/' + invoice.uuid)
      if (res) {
        this.load()
      }
    }
  
    async load() {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'invoice/list')
      if (res && res.data) {
        this.invoices = []
        res.data.invoices.forEach((t: any) => {
          const occ = new Invoice(t)
          this.invoices.push(occ)
        })
      }
      this.$store.commit('stopLoading')
    }
  
  }
  </script>
  
  
  <style scoped>
  
  </style>
<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                  <i class="fad fa-home"></i>
                  {{ trans("Dashboard") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </template>
  <template v-slot:content>
    <b-modal title="Creer une fonctionnalite" id="modalFeature" @ok="createFeature">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label">{{
                  trans("Nom de la fonctionalite")
                }}</label>
            <input type="text" class="form-control" v-model="feature.label" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label class="form-label">{{ trans("Code") }}</label>
            <input type="text" class="form-control" v-model="feature.code" />
          </div>
        </div>
      </div>
    </b-modal>

    <LoadingLogo v-if="loading" />

    <div v-else>
      <div class="card" v-if="typeAvenant">
        <div class="card-body">
          <h3 class="card-title">Creation d'un type d'avenant</h3>
          <div class="my-5">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">{{ trans("Nom du type") }}</label>
                  <input type="text" class="form-control" v-model="typeAvenant.label" />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">{{
                  trans("Code du type")
                }}</label>
                  <input type="text" class="form-control" v-model="typeAvenant.code" />
                </div>
              </div>
            </div>

            <div>
              <label class="form-label">{{ trans("Description") }}</label>

              <b-form-textarea id="textarea" v-model="typeAvenant.description" placeholder="Description" rows="3"
                max-rows="6"></b-form-textarea>
            </div>

            <div class="row">
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'active'" class="form-label">{{
                  trans("Impact tout les risques")
                }}</label>
                <label class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" id="activated" name="active" type="checkbox"
                    v-model="typeAvenant.allRisks" value="" />
                  <span class="form-check-label"> </span>
                </label>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'active'" class="form-label">{{
                  trans("Impact la prime")
                }}</label>
                <label class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" id="activated" name="active" type="checkbox"
                    v-model="typeAvenant.primeImpact" value="" />
                  <span class="form-check-label"> </span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">{{
                  trans("Fonction de calcul du pre-avenant")
                }}</label>
                  <input type="text" class="form-control" v-model="typeAvenant.lastFunction" />
                </div>
              </div>
              <div class="col" v-if="typeAvenant.primeImpact">
                <div class="mb-3">
                  <label class="form-label">{{
                  trans("Fonction du calcul de la nouvelle prime")
                }}</label>
                  <input type="text" class="form-control" v-model="typeAvenant.calculPrimeFunction" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-5">

        <div class="card-body">

          <b-tabs v-model="tabIndex" card>
            <b-tab title="Selection des fonctionalités " :title-link-class="linkClass(0)">
    
             
                  <div class="d-flex align-items-center justify-content-between">
                    <h3 class="card-title">Fonctionalités</h3>
    
                    <button v-b-modal.modalFeature class="btn btn-sm btn-secondary">
                      <i class="fad fa-plus"></i>
                      {{ trans("Creer une fonctionnalite") }}
                    </button>
                  </div>
    
                  <div class="row my-5">
                    <div class="col" v-for="(feat, index) in features" :key="index">
                      <div class="col-auto m-2 flex-grow-1">
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input key="" class="form-check-input" id="activated" name="active" type="checkbox"
                            v-model="typeAvenant.feature" :value="feat" />
                          <span class="form-check-label">
                            {{ trans(feat.label) }}</span>
                        </label>
                      </div>
                    </div>
                 
    
              </div>
    
    
    
            </b-tab>
            <b-tab title="Selection des branches " :title-link-class="linkClass(1)">
             
                  <div class="d-flex align-items-center justify-content-between">
                    <h3 class="card-title">Choisir les branches </h3>
                  </div>
    
                  <div class="row my-5">
                    <div class="col" v-for="(category, index) in categories" :key="index">

                      <div>
                        <h4>{{category.label}}</h4>
                      </div>

                      <div class="row">
                        <div class="col" v-for="(branch, index) in category.branches" :key="index">
                          <div class="col-auto m-2 flex-grow-1">
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input class="form-check-input" 
                             
                                type="checkbox"
                                v-model="typeAvenant.branchs" :value="branch"
                                 />
                              <span class="form-check-label">
                                {{ trans(branch.label) }}  </span>
                            </label>
                          </div>
  
  
                        </div>
                      </div>

                     

                     
                    </div>
                  </div>
    
               
              
    
            </b-tab>
            <b-tab title="Selection des assureurs" :title-link-class="linkClass(2)">
             
                  <div class="d-flex align-items-center justify-content-between">
                    <h3 class="card-title">Choisir les assureurs </h3>
                  </div>
    
                  <div class="row my-5">
                    <div class="col" v-for="(insurer, index) in insurers" :key="index">
                      <div class="col-auto m-2 flex-grow-1">
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input class="form-check-input" :id="insurer" name="active" type="checkbox"
                            v-model="typeAvenant.insurers[index].id" :value="insurer.id" />
                          <span class="form-check-label">
                            {{ trans(insurer.label) }}  </span>
                        </label>
                      </div>
                    </div>
                  
              
              </div>
            </b-tab>
          </b-tabs>
    
          </div>



        <div class="card-footer d-flex justify-content-end">
          <button @click="createTypeAvenant" class="btn btn-sm btn-primary">
            {{ trans("Sauvegarder") }}
          </button>
        </div>
      </div>



    


    </div>
  </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/services/Api";
import Base from "@/layouts/Base.vue";
import Feature from "@/entity/Feature";
import TypeAvenant from "@/entity/TypeAvenant";
import Popup from "@/entity/Popup";
import LoadingLogo from "@/components/LoadingLogo.vue";
import Branch from "@/entity/Branch";
import Insurer from "@/entity/Insurer";
import BranchCategory from "@/entity/BranchCategory";

@Component({
  components: { Base, LoadingLogo },
})
export default class AvenantTypeForm extends Vue {
  features: Feature[] = [];
  categories: BranchCategory[] = []
  insurers:any[] = []

  feature: Feature = new Feature();

  tabIndex = 0

  typeAvenant = new TypeAvenant();

  mounted(): void {
    this.loadFeatures();
    this.loadBranchs();
    this.loadInsurers();

    if (this.$route.params.id) {
      this.loadTypeAvenant();
    }
  }

  async loadFeatures() {
    const pop = new Popup(
      "Recuperation des fonctionalité",
      "CHARGEMENT",
      "primary",
      "fad fa-sync",
      true
    );
    const res = await api.get(api.core, "feature/list");
    if (res && res.data) {
      // (this.$refs.dataTypeModal as any).hide()
      this.features = [];
      res.data.features.forEach((feature: Feature) => {
        this.features.push(new Feature(feature));
      });

      pop.hide();
    }
  }

  async createFeature() {
    const pop = new Popup("Creation d'une fonctionnalité", "CHARGEMENT");
    const res = await api.post(api.core, "feature/edit", {
      feature: this.feature,
    });

    if ((res.status = 200)) {
      this.loadFeatures();
      pop.hide();
    }

    // this.
  }

  linkClass(idx: number) {
    if (this.tabIndex === idx) {
      return ['bg-primary w-100', 'text-light']
    } else {
      return ['bg-light w-100']
    }
  }

  async createTypeAvenant() {
    const pop = new Popup("Creation d'un type d'avenant ", "CHARGEMENT");

    console.log(this.typeAvenant, "avenant");
    const res = await api.post(api.core, "type/avenant/edit", {
      typeAvenant: this.typeAvenant,
    });

    if ((res.status = 200)) {
      this.$router.back();
      pop.hide();
    }

    // this.
  }

  loading = false;

  async loadTypeAvenant() {
    this.loading = true;
    const res = await api.get(
      api.core,
      "type/avenant/show/" + this.$route.params.id
    );

    if ((res.status = 200)) {
      this.typeAvenant = new TypeAvenant(res.data.typeAvenant);
    }

    // this.
    this.loading = false;
  }

  async loadBranchs() {
    const res = await api.get(api.core, 'branch/category/list')
    if (res && res.data && res.data.categories) {
      this.categories = []
      res.data.categories.forEach((cat: BranchCategory) => {
        this.categories.push(new BranchCategory(cat))
      })
    }

    this.$forceUpdate()

  }


  async loadInsurers() {
    
    const res = await api.get(api.core, "insurer/list");
    if (res && res.data) {
      // (this.$refs.dataTypeModal as any).hide()
      this.insurers = [];
      res.data.insurers.forEach((insurer: Insurer) => {
        this.insurers.push({
          id:insurer.id,
          label:insurer.label
        });
      });

    }
  }


}
</script>
<style scoped></style>

<template>
  <div class="">
    <div class="d-flex justify-content-between   border-bottom py-4" style="border-bottom:1px gray dashed;">
      <div class="d-flex align-items-center" :class="{ 'text-gray-400': !isSelected }">
        <template v-for="space in level">
          &nbsp;&nbsp;&nbsp;&nbsp;
        </template>
        <template>
          <template v-if="role.sub.length > 0">
            <i class="fad fa-caret-down fs-2 me-2 cursor-pointer w-20px" v-if="expand"
               @click="expand = !expand"></i>
            <i class="fad fa-caret-right fs-2 me-2  cursor-pointer w-20px" v-else
               @click="expand = !expand"></i>
          </template>
          <template v-else>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </template>
        </template>
        <template v-if="!editMode">

          <!-- <div class="form-check form-check-sm form-check-custom form-check-solid me-5" v-if="parentSelected">
              <input type="checkbox" :checked="isSelected" class="form-check-input widget-9-check" disabled>
          </div> -->

          <div class="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input type="checkbox" :checked="isSelected" class="form-check-input widget-9-check"
                   @change="toggle({ role, tenant })">
          </div>


          <!-- <div class="form-check form-switch form-check-custom form-check-solid me-5">
              <label class="form-check-label">
                  <input class="form-check-input h-20px w-30px" type="checkbox" :checked="isSelected"
                      @change="toggle(role)">
              </label>
          </div> -->

          <!-- <label class="form-check form-check-custom form-check-solid me-2 cursor-pointer">
            <input class="form-check-input h-20px w-20px" type="checkbox" :checked="isSelected" @change="toggle(role)">
        </label> -->
        </template>

        <div>
          <div class="fw-bolder ">
            {{ role.label }}<span class="text-muted"> ▪️ {{ role.code }}</span>
          </div>
          <div>
            <!-- <p v-if="!editMode"> -->
            {{ role.description }}
            <!-- </p> -->
          </div>
        </div>

      </div>

      <div v-if="editMode">
        <div class="btn btn-xs btn-icon btn-primary" @click="edit(role)">
          <i class="fad fa-edit"></i>
        </div>
        <div class="btn btn-xs btn-icon btn-info ms-2" @click="addSub(role)">
          <i class="fad fa-folder-tree"></i>
        </div>
        <div class="btn btn-xs btn-icon btn-danger ms-2" @click="remove(role)">
          <i class="fad fa-trash-alt"></i>
        </div>
        <template v-for="space in level">
          &nbsp;&nbsp;&nbsp;&nbsp;
        </template>
      </div>
    </div>

    <div v-if="expand && role.sub.length > 0">
      <div v-for="r in role.sub" :key="'role-' + r.id">
        <RoleLine :role="r" :level="level + 1" @edit="edit" @addSub="addSub" @remove="remove" :editMode="editMode"
                  @toggle="toggle" :user="user" :parentSelected="isSelected" :tenant="tenant"></RoleLine>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Role from '@/entity/Role';
import Tenant from '@/entity/Tenant';
import User from '@/entity/User';
import {auth} from '@/services/Auth';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Swal from "sweetalert2";
import {api} from "@/services/Api";
import Contract from "@/entity/Contract";

@Component({
  components: {},
})
export default class RoleLine extends Vue {
  @Prop() role!: Role
  @Prop({default: 0}) level!: number
  @Prop({default: false}) editMode!: boolean
  @Prop({default: null}) user!: User
  @Prop({default: false}) parentSelected!: boolean
  @Prop({default: null}) tenant!: Tenant

  expand = true
  selected = false


  get isSelected() {
    // const res = this.user.hasRole(this.code) || this.parentSelected
    // // console.log(this.code + ' => ' + res)
    // return res
    return this.user ? this.user.roles.includes(this.code) : false
  }

  get code() {
    let code = this.role.code.startsWith('ROLE_') ? this.role.code : 'ROLE_' + this.role.code
    code += '_' + this.tenant.code.toUpperCase()
    return code
  }

  edit(role: Role) {
    this.$emit('edit', role)
  }

  addSub(role: Role) {
    this.$emit('addSub', role)
  }

  remove(role: Role) {
    this.$emit('remove', role)
  }

  toggle(data: any) {
    this.$emit('toggle', data)
  }

}
</script>
